import * as React from "react";
import { SQW_Log, DebugLevel } from "services/Logging";
//import Chip from "@mui/joy/Chip";
import "../../styles.css";
import { WebInventoryItem } from "interfaces/InvoiceInterfaces";
import { Box } from "@mui/material";
import theme from "theme/theme";
import { ThemeProvider } from "@mui/joy";
//import { Wrapper } from "./CartItem.styles";

type Props = {
    item: WebInventoryItem;
    handleAddToCart: (clickedItem: WebInventoryItem) => void;
    handleItemDisplay: (clickedItem: WebInventoryItem) => void;
};

const ProductCard: React.FC<Props> = ({ item, handleAddToCart, handleItemDisplay }) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [minWidth, setMinWidth] = React.useState(250);

    const truncateDescription = (text: string) => {
        if (text.length > 50) return text.substring(0, 50) + "...";

        return text;
    };

    React.useEffect(() => {
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "ProductCard: useEffect", item);

        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);

    React.useEffect(() => {
        if (width < 500) {
            setMinWidth(350);
        } else {
            setMinWidth(250);
        }
    }, [width]);

    return (
        <>
            {/*<Card className="item_listing_box">
        <CardContent>*/}
            <Box
                className='item_listing_box'
                onClick={() => handleItemDisplay(item)}
                borderRadius={theme.shape.borderRadius}
                boxShadow={1}
            >
                <Box className='item_listing_image_box'>
                    <Box
                        component='img'
                        className='item_listing_image'
                        src={item.oImage && item.oImage.cimgurl ? item.oImage.cimgurl : ""}
                        loading='lazy'
                        alt=''
                        maxWidth={330}
                    />
                </Box>
                {/* <Link
            href="#product-card"
            fontWeight="md"
            color="neutral"
            textColor="text.primary"
            onClick={() => handleItemDisplay(item)}
            overlay
          >
            {item.citemno}
          </Link>*/}
                <table width={"100%"}>
                    <tbody>
                        <tr>
                            <td height='120px'>
                                <Box className='item_listing_text'>
                                    Item#: {item.citemno}
                                    <br />
                                    <span className='.item_listing_text_details'>
                                        {truncateDescription(item.cdescshort)}
                                    </span>
                                </Box>
                            </td>
                        </tr>
                        <tr>
                            <td height='40px'>
                                <Box className='item_listing_price'>
                                    {"$" + item.nprice.toFixed(2)}
                                </Box>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Box>
            {/*</CardContent>
      </Card>*/}
        </>
    );
};

export default ProductCard;
