import CloseIcon from "@mui/icons-material/Close";
import { SQW_Log, DebugLevel } from "services/Logging";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import {
    AppBar,
    Avatar,
    Badge,
    Box,
    Button,
    Container,
    Drawer,
    FormControl,
    ListItemIcon,
    Menu,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Toolbar,
    Typography,
    Grid,
} from "@mui/material";
import React, { useContext } from "react";
import { NavLink as RouterNavLink, useLocation, useNavigate } from "react-router-dom";
import { GetUserName, GetSettingsRefresh } from "services/PersistantObjects";
import { SelectHubsByTenantID, SelectUser } from "services/GraphQlHelper_New";
import { globalUser } from "helpers/Globals";
import { CognitoAuthContext } from "helpers/CognitoAuthRoute";

import { useIsMobile, useIsLessThanFullWidth } from "../helpers/CustomHooks";
import {
    GetLogoURL,
    GetCompanyName,
    getUserGroups,
    API_GetUsersHubs,
    API_Authenticate,
    Settings,
    GetTenantName,
} from "../services/GetInvoiceData";
import { SmallIconButton } from "./Buttons";
import { useHubState } from "../context/HubContext";
import theme from "../theme/theme";

const settings = [] as unknown[];

/*
const MButton = ({ children, ...props }) => {
    const { onClick } = props;
    const buttonRef = useRef();
    const handleOnClick = ({ ...props }) => {
        buttonRef?.current?.ref?.current && buttonRef?.current?.ref?.current?.blur();
        if (onClick) {
            onClick(props);
        }
    };
    return (
        <Button
            {...props}
            ref={buttonRef}
            onClick={handleOnClick}
        >
            {children}
        </Button>
    );
};*/

const tempUser = {
    name: "Your Name",
    firstName: "Name",
    imageUrl: "https://prism-web-images.s3.amazonaws.com/user-profile/default_user_profile_pic.jpg",
};

type NavItem = {
    label: string;
    path: string;
    permissionname: string;
    visible: boolean;
    Icon: React.ElementType;
};

type NavBarProps = {
    navItems: NavItem[];
    signOut: () => Promise<void>;
    numberOfCartItems?: number;
};

interface NavLinkMenuItemProps {
    to?: string;
    children: React.ReactNode;
    icon: React.ReactNode;
    onClick?: () => void;
}

const NavLinkMenuItem: React.FC<NavLinkMenuItemProps> = ({ to, children, icon, onClick }) => {
    const location = useLocation();
    const isActive = to ? location.pathname === to : false;
    const ComponentProp = onClick ? "button" : RouterNavLink;

    return (
        <MenuItem
            component={ComponentProp}
            to={to}
            onClick={onClick}
            sx={{
                "backgroundColor": isActive ? theme.palette.primary.light : "transparent", // Using RGBA to include opacity. RGB(88, 181, 93) is the color #58B55D (green) with 25% opacity.
                "color": theme.palette.text.primary,
                "&:hover": {
                    backgroundColor:
                        isActive ? theme.palette.primary.light : theme.palette.action.hover,
                },
                "py": 2,
                "px": 3,
            }}
        >
            <ListItemIcon sx={{ minWidth: 40 }}>{icon}</ListItemIcon>
            <Typography
                variant='inherit'
                sx={{ ml: 1 }}
            >
                {children}
            </Typography>
        </MenuItem>
    );
};

type Hub = {
    description: string;
    chubno: string;
};

const NavBar: React.FC<NavBarProps> = ({ navItems, signOut, numberOfCartItems }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    //const [anchorElReports, setAnchorElReports] = React.useState<null | HTMLElement>(null);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    //const [openReports, setOpenReports] = React.useState(false);
    const [companyLogo, setCompanyLogo] = React.useState("");
    const open = Boolean(anchorEl);
    const [activePath, setActivePath] = React.useState("/");
    const [hubs, setHubs] = React.useState([] as Hub[]);
    const [hubSelected, setHubSelected] = React.useState("");
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [isSiteAdmin, setIsSiteAdmin] = React.useState(false);
    const { hubState, setHubState } = useHubState();
    const [settings, setSettings] = React.useState([] as Settings[]);
    const [loading, setLoading] = React.useState(false);
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");

    function getSettingByName(settingsArr: Settings[], name: string): string {
        let value = "0";

        if (!settingsArr) return value;

        try {
            value =
                settingsArr.find(
                    (x: any) => x.name.trim().toLowerCase() === name.trim().toLowerCase(),
                )?.value || "0";
        } catch (err) {
            //SQW_Log(DebugLevel.LOG, "Error getting setting by name " + name + ".", err);
        }
        return value.trim().toLowerCase();
    }

    function checkNavItemVisibility(settings: Settings[]) {
        navItems.forEach((item) => {
            item.visible = getSettingByName(settings, item.permissionname) === "1";
        });
    }

    const isMobile = useIsMobile();
    const isLessThanFullWidth = useIsLessThanFullWidth();
    const { signOut: CognitoSignOut } = useContext(CognitoAuthContext);

    React.useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const allSettings = await GetSettingsRefresh();
                if (allSettings) setSettings(allSettings);
                checkNavItemVisibility(allSettings);
                //SQW_Log(DebugLevel.LOG, "888: allSettings: ", allSettings);
                //SQW_Log(DebugLevel.LOG, "888: navItems: ", navItems);

                const allHubs = await SelectHubsByTenantID(GetTenantName());
                SQW_Log(DebugLevel.LOG, "432 - Allhubs: ", allHubs);

                const token = await API_Authenticate();
                const hubs = await API_GetUsersHubs(token, GetUserName());
                //SQW_Log(DebugLevel.LOG, "888: useEffect - hubs: ", hubs);
                setHubs([]);
                setHubSelected("");

                if (hubs && hubs.IsSuccessful) {
                    if (hubs.hubsOn && hubs.Hubs) {
                        const myHubs: Hub[] = [];
                        hubs.Hubs &&
                            hubs.Hubs.forEach((hub: string) => {
                                if (allHubs) {
                                    //SQW_Log(DebugLevel.LOG, "432 - pushing hub: ", hub);
                                    myHubs.push({
                                        description:
                                            allHubs.find(
                                                (b: any) =>
                                                    b.chubno.trim().toLowerCase() ===
                                                    hub.trim().toLowerCase(),
                                            )?.description || hub,
                                        chubno: hub,
                                    });
                                } else myHubs.push({ description: hub, chubno: hub });
                            });
                        //SQW_Log(DebugLevel.LOG, "888: setting hubs: ", hubs.Hubs);
                        setHubs(myHubs);
                        //SQW_Log(DebugLevel.LOG, "888: setting hubSelected: ", hubSelected);
                        if (hubs?.Hubs?.length > 0) {
                            let hub = localStorage.getItem("sqw_hub");
                            if (myHubs && myHubs.length > 0)
                                if (
                                    !myHubs.find(
                                        (x) =>
                                            x.chubno.trim().toLowerCase() ===
                                            hub?.trim().toLowerCase(),
                                    )
                                )
                                    hub = myHubs[0].chubno;

                            if (!hub) hub = hubs.Hubs[0];
                            if (hub) {
                                setHubSelected(hub);
                                setHubState && setHubState(hub);
                                //SQW_Log(DebugLevel.LOG, "888: setting hubSelected: ", hub);
                            }
                        } else setHubSelected("");
                    } //else SQW_Log(DebugLevel.LOG, "888: Hubs are turned off...");
                }
            } catch (err) {
                SQW_Log(DebugLevel.LOG, "Error getting hubs: ", err);
            } finally {
                setLoading(false);
            }
        }

        const fetchUserInfo = async () => {
            try {
                const userName = globalUser();
                if (!userName) {
                    SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "fetchUserInfo: no user found.");
                }
                const user = await SelectUser(globalUser());
                if (user) {
                    if (user.length > 0) {
                        //SQW_Log(DebugLevel.LOG, "532: fetchUserInfo", user.data.userByUsername.items[0]);
                        setFirstName(user[0]?.firstname || "");
                        setLastName(user[0]?.lastname || "");
                    }
                }
            } catch (err) {
                SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error getting user info: ", err);
            }
        };
        fetchUserInfo();
        fetchData();

        getUserGroups().then((groups) => {
            //SQW_Log(DebugLevel.LOG, "AAA-Groups being called");
            if (!groups) return;
            //SQW_Log(DebugLevel.LOG, "AAA-Groups", groups);
            if (groups.includes("Prism_admin")) setIsAdmin(true);
            //SQW_Log(DebugLevel.LOG, "AAA-Checking", "site-admin-" + GetTenantName());
            if (groups.includes("site-admin-" + GetTenantName())) {
                setIsSiteAdmin(true);
                //SQW_Log(DebugLevel.LOG, "AAA-Is site admin.");
            } //else SQW_Log(DebugLevel.LOG, "AAA-h");
        });
        fetchData();
        SQW_Log(DebugLevel.LOG, "Navbars - useEffect fetching data.");
    }, []);

    async function reloadLogo() {
        const logo = await GetLogoURL();
        setCompanyLogo(logo);
    }

    React.useEffect(() => {
        reloadLogo();
    }, []);

    React.useEffect(() => {
        //SQW_Log(DebugLevel.LOG, "888: setting local storage hubSelected: ", hubSelected);
        if (hubSelected === "") return;
        const storedHub = localStorage.getItem("sqw_hub");
        if (storedHub !== hubSelected) {
            localStorage.setItem("sqw_hub", hubSelected);
            setHubState && setHubState(hubSelected);
            reloadLogo();
            //navigate("/");
            location.reload();
        }
        localStorage.setItem("sqw_hub", hubSelected);
        setHubState && setHubState(hubSelected);
        reloadLogo();
    }, [hubSelected]);

    const navigate = useNavigate();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpenDrawer(false);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleSelectHub(event: SelectChangeEvent) {
        //SQW_Log(DebugLevel.LOG, "handleSelectHub: ", event.target.value);
        setHubSelected(event.target.value);
    }

    const renderNavItemsMobile = navItems.map(({ label, path, visible, Icon }, index) =>
        !visible || (!isSiteAdmin && !isAdmin && path === "/admin") ?
            null
        :   <Stack
                key={label}
                direction='column'
                alignItems='left'
            >
                <Button
                    key={label}
                    component={RouterNavLink}
                    //to={path !== "/reports" ? path : ""}
                    to={path}
                    onClick={(e) => {
                        navigate(path);
                        setActivePath(path);
                        setOpenDrawer(false);
                        //path === "/reports" && handleOpenReports(e);
                        //path !== "/reports" && setOpenDrawer(false);
                    }}
                    startIcon={<Icon sx={{ transform: "scale(1.25)" }} />}
                    sx={{
                        "color": "inherit",
                        "alignItems": "left",
                        "fontWeight": 500,
                        "justifyContent": "left",
                        "marginRight": "auto",
                        "fontSize": "14px",
                        "fontFamily": "Montserrat",
                        "gap": "8px",
                        "&.active": {
                            "color": path === activePath ? "#58B55D" : "inherit",
                            "& svg": {
                                color: path === activePath ? "#58B55D" : "inherit",
                            },
                        },
                        "textTransform": "none",
                        "whiteSpace": "nowrap",
                    }}
                >
                    {label}
                </Button>
            </Stack>,
    );

    /*
    const handleOpenReports = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorElReports(e.currentTarget);
        setOpenReports(true);
    };
    */

    const renderNavItems = navItems.map(({ label, path, visible, Icon }, index) =>
        !visible || (!isSiteAdmin && !isAdmin && path === "/admin") ?
            null
        :   <Box
                key={label}
                sx={{ display: "flex", alignItems: "center" }}
            >
                {index === navItems.length - 1 && (
                    <Box
                        sx={{
                            xs: { display: "none" },
                            sm: { display: "block" },
                            md: { display: "block" },
                            height: "24px",
                            width: "1px",
                            bgcolor: "grey.500",
                            marginRight: "24px",
                        }}
                    />
                )}
                <Button
                    key={label}
                    component={RouterNavLink}
                    //to={path !== "/reports" ? path : "."}
                    to={path}
                    onClick={(e: any) => {
                        setActivePath(path);
                        //path === "/reports" && handleOpenReports(e);
                    }}
                    startIcon={
                        <>
                            {path === "/cart" && (
                                <Badge
                                    badgeContent={numberOfCartItems && numberOfCartItems}
                                    color='success'
                                ></Badge>
                            )}
                            <Icon
                                sx={{
                                    transform: {
                                        xs: "scale(1.25)",
                                        sm: "scale(0.90)",
                                        md: "scale(1.25)",
                                    },
                                }}
                            />
                        </>
                    }
                    sx={{
                        "fontSize": isLessThanFullWidth ? "10px" : "14px",

                        "color": "inherit",
                        "alignItems": "center",
                        "fontWeight": 500,
                        "&.active": {
                            "color":
                                path === activePath ?
                                    theme.palette.text.secondary
                                :   theme.palette.text.primary,
                            "& svg": {
                                color:
                                    path === activePath ?
                                        theme.palette.text.secondary
                                    :   theme.palette.text.primary,
                            },
                        },
                    }}
                >
                    {label}
                </Button>
            </Box>,
    );

    interface DrawerButtonProps {
        label: string;
        path: string;
        Icon: React.ElementType;
        onClick?: () => void;
    }

    interface DrawerButtonNoPathProps {
        label: string;
        Icon: React.ElementType;
        onClick?: () => void;
    }

    const DrawerButton: React.FC<DrawerButtonProps> = ({ label, path, Icon, onClick }) => (
        <Button
            key={label}
            component={RouterNavLink}
            to={path}
            onClick={() => {
                setOpenDrawer(false);
                onClick && onClick();
            }}
            startIcon={<Icon sx={{ transform: "scale(1.25)" }} />}
            sx={{
                "color": "inherit",
                "alignItems": "left",
                "fontWeight": 500,
                "justifyContent": "left",
                "marginRight": "auto",
                "fontSize": "14px",
                "fontFamily": "Montserrat",
                "gap": "8px",
                "&.active": {
                    "color": "#58B55D",
                    "& svg": {
                        color: "#58B55D",
                    },
                },
                "textTransform": "none",
                "whiteSpace": "nowrap",
            }}
        >
            {label}
        </Button>
    );

    const DrawerButtonNoPath: React.FC<DrawerButtonNoPathProps> = ({ label, Icon, onClick }) => (
        <Button
            key={label}
            onClick={() => {
                setOpenDrawer(false);
                onClick && onClick();
            }}
            startIcon={<Icon sx={{ transform: "scale(1.25)" }} />}
            sx={{
                "color": "inherit",
                "alignItems": "left",
                "fontWeight": 500,
                "justifyContent": "left",
                "marginRight": "auto",
                "fontSize": "14px",
                "fontFamily": "Montserrat",
                "gap": "8px",
                "&.active": {
                    "color": "#58B55D",
                    "& svg": {
                        color: "#58B55D",
                    },
                },
                "textTransform": "none",
                "whiteSpace": "nowrap",
            }}
        >
            {label}
        </Button>
    );

    /*
    const reportMenu = (
        <Menu
            id='reports-menu-appbar'
            anchorEl={anchorElReports}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={openReports}
            onClose={() => {
                setOpenReports(false);
            }}
            sx={{
                ".MuiPaper-root": {
                    borderRadius: "6px",
                    padding: "8px",
                    marginTop: {
                        xs: "0px",
                        sm: "0px",
                        md: "0px",
                        lg: "24px",
                    },
                },
            }}
        >
            <NavLinkMenuItem
                to='/printinvoices'
                icon={<PersonOutlinedIcon />}
                onClick={() => {
                    setOpenReports(false);
                    setOpenDrawer(false);
                    navigate("/printinvoices");
                }}
            >
                Invoices
            </NavLinkMenuItem>
            <NavLinkMenuItem
                to='/printtickets'
                icon={<Inventory2OutlinedIcon />}
                onClick={() => {
                    setOpenReports(false);
                    setOpenDrawer(false);
                    navigate("/printtickets");
                }}
            >
                Tickets
            </NavLinkMenuItem>
        </Menu>
    );
    */

    const mobileLayout = (
        <AppBar
            position='sticky'
            color='default'
            elevation={0}
            sx={{
                borderBottom: 1,
                borderColor: "divider",
                backgroundColor: "#FFFFFF",
                height: "60px",
                alignItems: "left",
            }}
        >
            <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                <Box
                    className='float-left'
                    //sx={{ position: "absolute", top: "10", left: "0" }}
                >
                    <SmallIconButton onClick={() => setOpenDrawer(true)}>
                        <MenuIcon sx={{ color: "black" }} />
                    </SmallIconButton>
                    {/*<Button onClick={() => setOpenDrawer(true)}>
                        <Avatar
                            alt={tempUser.name}
                            src={tempUser.imageUrl}
                            sx={{ width: 25, height: 25 }}
                        />
                        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    fontFamily: "Montserrat SemiBold 14px",
                                    color: "#1E2832",
                                    textTransform: "none",
                                }}
                            >
                                {tempUser.firstName}
                            </Typography>
                        </Box>
                    </Button>*/}
                </Box>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    sx={{
                        maxWidth: 250,
                        height: "100%",
                        width: "100%",
                        alignItems: "center",
                        alignContent: "center",
                        marginTop: "5px",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >
                    {hubs && hubs.length > 1 && (
                        <>
                            <Typography>Hub:</Typography>
                            <FormControl
                                sx={{
                                    m: 1,
                                    width: "100%",
                                }}
                                size='small'
                            >
                                <Select
                                    className='hub_select'
                                    id='hubSelect'
                                    value={hubSelected}
                                    displayEmpty
                                    onChange={handleSelectHub}
                                >
                                    {hubs.map((hub) => (
                                        <MenuItem
                                            key={hub.chubno}
                                            value={hub.chubno}
                                        >
                                            {hub.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </>
                    )}
                </Stack>
                <Box
                    className='float-right'
                    //sx={{ position: "absolute", top: "10", right: "0" }}
                >
                    <Button
                        className='float-right'
                        component={RouterNavLink}
                        to={"/cart"}
                    >
                        <Badge
                            badgeContent={numberOfCartItems && numberOfCartItems}
                            color='success'
                        ></Badge>
                        <ShoppingCartOutlinedIcon sx={{ color: "black" }} />
                    </Button>
                </Box>
            </Box>
            <Drawer
                open={openDrawer}
                color='default'
                elevation={0}
                sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    backgroundColor: "#FFFFFF",
                    height: "80px",
                    width: "350px",
                    maxWidth: "350px",
                }}
            >
                <Stack
                    direction='column'
                    sx={{ height: "100%" }}
                    gap={1}
                    padding={1}
                    alignItems='left'
                >
                    <Box
                        sx={{
                            width: "100%",
                            alignItems: "left",
                        }}
                    >
                        <SmallIconButton
                            className='float-right'
                            onClick={() => setOpenDrawer(!openDrawer)}
                        >
                            <CloseIcon />
                        </SmallIconButton>
                        <Box
                            component='img'
                            src={companyLogo}
                            alt={GetCompanyName() + " Logo"}
                            sx={{
                                height: {
                                    xs: "40px",
                                    sm: "45px",
                                    md: "50px",
                                    lg: "60px",
                                },
                                width: "auto",
                                maxHeight: "100%",
                                margin: "auto 0",
                            }}
                        />
                    </Box>
                    {renderNavItemsMobile}
                    <Box
                        sx={{
                            height: "2px",
                            width: "270px",
                            bgcolor: "grey.500",
                            marginRight: "5px",
                            marginLeft: "5px",
                        }}
                    />
                    <DrawerButton
                        label='My Account'
                        path='/myaccount'
                        Icon={PersonOutlinedIcon}
                        onClick={() => {
                            navigate("/myaccount");
                            setActivePath("/myaccount");
                        }}
                    />
                    {/*<DrawerButton
                        label='My Orders'
                        path='/myorders'
                        Icon={Inventory2OutlinedIcon}
                        onClick={() => setActivePath("/myorders")}
                    />
                    <DrawerButton
                        label='Payment Methods'
                        path='/paymethods'
                        Icon={PaymentOutlinedIcon}
                        onClick={() => setActivePath("/paymethods")}
                    />
                    <DrawerButton
                        label='My Admin'
                        path='/myadmin'
                        Icon={AdminPanelSettingsOutlinedIcon}
                        onClick={() => setActivePath("/myadmin")}
                    />*/}
                    {(isAdmin || isSiteAdmin) && (
                        <DrawerButton
                            label='Settings'
                            path='/admin'
                            Icon={SettingsOutlinedIcon}
                            onClick={() => {
                                navigate("/admin");
                                setActivePath("/admin");
                            }}
                        />
                    )}
                    <br />
                    <DrawerButtonNoPath
                        label='Logout'
                        Icon={LogoutOutlinedIcon}
                        onClick={() => {
                            setActivePath("/");
                            handleClose();
                            CognitoSignOut();
                            navigate("/");
                        }}
                    />
                    {/*{reportMenu}*/}
                </Stack>
            </Drawer>
        </AppBar>
    );

    if (isMobile) return mobileLayout;

    return (
        <>
            <AppBar
                position='sticky'
                color='default'
                elevation={0}
                sx={{
                    right: "auto",
                    borderBottom: 1,
                    borderColor: "divider",
                    backgroundColor: "#FFFFFF",
                    justifyContent: "center",
                    sm: {
                        height: "160px",
                    },
                    md: {
                        height: "80px",
                    },
                }}
            >
                {/*<Container
                    sx={{
                        width: "100%",
                        maxWidth: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >*/}
                <Toolbar
                    disableGutters
                    sx={{
                        width: isLessThanFullWidth ? "100vw" : "1440px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "0",
                        marginBottom: "0",
                    }}
                >
                    <Stack
                        direction='row'
                        justifyContent='left'
                        alignItems='center'
                        sx={{
                            maxWidth: "1440px",
                            width: isLessThanFullWidth ? "100vw" : "1440px",
                        }}
                    >
                        <Box
                            component='img'
                            src={companyLogo}
                            alt={GetCompanyName() + " Logo"}
                            sx={{
                                width: "auto",
                                height: "80px",
                                marginTop: "auto",
                                marginBottom: "auto",
                                marginRight: "auto",
                                marginLeft: "0",
                            }}
                        />

                        {hubs && hubs.length > 1 && (
                            <Typography sx={{ marginLeft: "10px" }}>Hub:</Typography>
                        )}
                        {hubs && hubs.length > 1 && (
                            <FormControl
                                sx={{
                                    m: 1,
                                    width: "100%",
                                }}
                            >
                                <Select
                                    className='hub_select'
                                    id='hubSelect'
                                    value={hubSelected}
                                    displayEmpty
                                    sx={{ width: "100%" }}
                                    onChange={handleSelectHub}
                                >
                                    {hubs.map((hub) => (
                                        <MenuItem
                                            key={hub.chubno}
                                            value={hub.chubno}
                                        >
                                            {hub.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                            }}
                        >
                            {renderNavItems}
                            <Button onClick={handleMenu}>
                                <Avatar
                                    alt={firstName + " " + lastName}
                                    src={tempUser.imageUrl}
                                    sx={{ width: 72, height: 72 }}
                                />
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 0.5,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            fontFamily: "Montserrat SemiBold 14px",
                                            color: "#1E2832",
                                            textTransform: "none",
                                        }}
                                    >
                                        {firstName}
                                    </Typography>
                                    <KeyboardArrowDownRoundedIcon
                                        sx={{
                                            color: "#1E2832",
                                        }}
                                    />
                                </Box>
                            </Button>
                            {/*{reportMenu}*/}
                            <Menu
                                id='menu-appbar'
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={open}
                                onClose={handleClose}
                                sx={{
                                    ".MuiPaper-root": {
                                        borderRadius: "6px",
                                        padding: "8px",
                                    },
                                }}
                            >
                                <NavLinkMenuItem
                                    to='/myprofile'
                                    icon={<PersonOutlinedIcon />}
                                    onClick={() => {
                                        navigate("/myprofile");
                                        setActivePath("/myprofile");
                                    }}
                                >
                                    My Account
                                </NavLinkMenuItem>
                                {/*<NavLinkMenuItem
                                                to='/myorders'
                                                icon={<Inventory2OutlinedIcon />}
                                                onClick={() => {
                                                    navigate("/myorders");
                                                    setActivePath("/myorders");
                                                }}
                                            >
                                                My Orders
                                            </NavLinkMenuItem>*/}
                                {/*<NavLinkMenuItem
                                                to='/paymethods'
                                                icon={<PaymentOutlinedIcon />}
                                                onClick={() => {
                                                    navigate("/paymethods");
                                                    setActivePath("/paymethods");
                                                }}
                                            >
                                                Payment Methods
                                            </NavLinkMenuItem>
                                            <NavLinkMenuItem
                                                to='/myadmin'
                                                icon={<AdminPanelSettingsOutlinedIcon />}
                                                onClick={() => {
                                                    navigate("/myadmin");
                                                    setActivePath("/myadmin");
                                                }}
                                            >
                                                My Admin
                                            </NavLinkMenuItem>*/}
                                {(isAdmin || isSiteAdmin) && (
                                    <NavLinkMenuItem
                                        to='/admin'
                                        icon={<SettingsOutlinedIcon />}
                                        onClick={() => {
                                            navigate("/admin");
                                            setActivePath("/admin");
                                        }}
                                    >
                                        Settings
                                    </NavLinkMenuItem>
                                )}
                                <NavLinkMenuItem
                                    to='/signout'
                                    icon={<LogoutOutlinedIcon />}
                                    onClick={() => {
                                        setActivePath("/");
                                        handleClose();
                                        CognitoSignOut();
                                        navigate("/");
                                    }}
                                >
                                    Logout
                                </NavLinkMenuItem>
                            </Menu>
                        </Box>
                    </Stack>
                </Toolbar>
                {/*</Container>*/}
            </AppBar>
        </>
    );
};

export default NavBar;
