import { globalDataContext } from "App";
import { SQW_Log, DebugLevel } from "services/Logging";

import {
    AuthObject,
    Category,
    ScheduleAddress,
    WebInventoryItem,
} from "../interfaces/InvoiceInterfaces";
import {
    API_Authenticate,
    API_GetScheduleAddresses,
    API_GetSettings,
    API_GetSpecialImages,
    API_GetWebCategories,
    defaultSettings,
    getAPIAllProducts,
    Settings,
    SpecialImage,
    encryptString,
    decryptString,
    sleep,
    getTenantNameFromSubdomain,
} from "./GetInvoiceData";
//import { dataContext, GetCurrentTenantName(), GetCurrentTenantName()Info, globalUser } from "helpers/Globals";
import { SelectTenant, SelectUserTenants } from "./GraphQlHelper_New";
import { exit } from "process";

/*const SettingsExpirationMinutes = 60;
const TokenExpirationMinutes = 10;
const PersistantInfoExpirationMinutes = 60;*/

export enum PersistantObjectType {
    ID_TOKEN = "idToken",
    ACCESS_TOKEN = "accessToken",
    REFRESH_TOKEN = "refreshToken",
    USER_NAME = "userName",
    FIRST_NAME = "firstName",
    LAST_NAME = "lastName",
    USER_TENANTS = "userTenants",
    SETTINGS = "settings",
    SELECTED_CUSTOMER = "selectedCustomer",
    SELECTED_HUB = "selectedHub",
    SELECTED_TENANT = "selectedTenant",
    CATEGORIES = "categories",
    SPECIAL_IMAGES = "specialImages",
    CART_ITEMS = "cartItems",
    PRODUCTS = "products",
    CURRENT_ADDRESS = "currentAddress",
    NEXT_DELIVERY_DATE = "nextDeliveryDate",
    DEL_ADDRESSES = "deliveryAddresses",
    SVC_ADDRESSES = "serviceAddresses",
    TENANT_INFO = "tenantInfo",
}

interface Tenant {
    subdomain: string;
    hub: string;
    date: Date;
    data: any;
}

const tenantData: Tenant[] = [];

export type TenantInfo = {
    id: string;
    subdomain: string;
    usesHubs: boolean;
    name: string;
    url: string;
    uniqueid: string;
    createdby: string;
    logo?: string | null;
    loginsideimage?: string | null;
    companyname?: string | null;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    phone?: string | null;
    email?: string | null;
    hubs?: any | null;
    users?: any | null;
    linkedclient?: any | null;
    createdAt: string;
    updatedAt: string;
};

let persistantObj = {
    loadedDt: new Date("2000-01-01"),
    idToken: "",
    accessToken: "",
    refreshToken: "",
    userName: "",
    firstName: "",
    lastName: "",
    userTenants: [] as any[],
    settings: [] as Settings[],
    selectedCustomer: "",
    selectedHub: "",
    selectedTenant: "",
    categories: [] as Category[],
    specialImages: [] as SpecialImage[],
    cartItems: [] as WebInventoryItem[],
    products: [] as WebInventoryItem[],
    currentAddress: {} as ScheduleAddress,
    nextDeliveryDate: "",
    deliveryAddresses: [] as ScheduleAddress[],
    serviceAddresses: [] as ScheduleAddress[],
    tenantInfo: {} as any,
};

export function GetPersistantObject() {
    return persistantObj;
}

export function GetUserName() {
    const user = localStorage.getItem("sqw_u");
    return getItemStr(PersistantObjectType.USER_NAME) ?? user ?? "";
}

function GetCurrentTenantName() {
    return getTenantNameFromSubdomain() ?? "";
}

export async function GetTenant(): Promise<TenantInfo> {
    const gti = await fetchTenantInfo();
    SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "GetTenant: gti: ", gti);

    return (
        gti ?? {
            id: "",
            subdomain: "",
            usesHubs: false,
            name: "",
            url: "",
            uniqueid: "",
            createdby: "",
            logo: null,
            loginsideimage: null,
            companyname: null,
            address1: null,
            address2: null,
            city: null,
            state: null,
            zip: null,
            phone: null,
            email: null,
            hubs: null,
            users: null,
            linkedclient: null,
            createdAt: "",
            updatedAt: "",
        }
    );
}

export function storeItem(key: PersistantObjectType, value: any) {
    //loadStoredItems();

    if (
        key in
        [
            PersistantObjectType.PRODUCTS,
            PersistantObjectType.DEL_ADDRESSES,
            PersistantObjectType.SVC_ADDRESSES,
        ]
    ) {
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "Storing item separately: ", key, value);
        const itemstr = JSON.stringify(value);
        encryptString(itemstr).then((encryptedItemStr) => {
            localStorage.setItem("sqw_" + key, encryptedItemStr);
        });
    }
    SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "Storing item: ", key, value);
    persistantObj[key] = value;

    //saveStoredItems();
}

export function saveStoredItems() {
    //Make a clone of persistantObj so we can encrypt it.
    const po = Object.assign({}, persistantObj);
    if (po.products.length > 0)
        encryptString(JSON.stringify(po.products)).then((encryptedPoString) => {
            localStorage.setItem("sqw_products", encryptedPoString);
        });
    if (po.deliveryAddresses.length > 0)
        encryptString(JSON.stringify(po.deliveryAddresses)).then((encryptedPoString) => {
            localStorage.setItem("sqw_addr_del", encryptedPoString);
        });
    if (po.serviceAddresses.length > 0)
        encryptString(JSON.stringify(po.serviceAddresses)).then((encryptedPoString) => {
            localStorage.setItem("sqw_addr_svc", encryptedPoString);
        });
    po.products = [];
    po.deliveryAddresses = [];
    po.serviceAddresses = [];
    persistantObj.loadedDt = new Date();
    SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "loaded date:", persistantObj.loadedDt);
    const poString = JSON.stringify(po);

    encryptString(poString).then((encryptedPoString) => {
        localStorage.setItem("sqw_po", encryptedPoString);

        try {
            let loadDtStr = new Date().toISOString();
            if (persistantObj.loadedDt) {
                loadDtStr = new Date(persistantObj.loadedDt).toISOString();
            }

            localStorage.setItem("sqw_po_cd", loadDtStr);
        } catch (err) {
            SQW_Log(DebugLevel.ERROR, "Persistant Date loaded date: ", persistantObj.loadedDt);
            SQW_Log(DebugLevel.ERROR, "Error converting loaded date: ", err);
            localStorage.setItem("sqw_po_cd", new Date().toISOString());
        }
    });
}

export function getItem(key: PersistantObjectType) {
    if (!persistantObj) return null;
    SQW_Log(
        DebugLevel.INFO_WITH_INFO_TYPE,
        "Getting item: ",
        key,
        persistantObj[key],
        persistantObj,
    );
    return persistantObj[key];
}

export function getItemStr(key: PersistantObjectType) {
    SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "Getting item String: ", key, persistantObj);
    if (!persistantObj || !persistantObj[key]) return null;
    return persistantObj[key].toString();
}

function clearPersistantObject(clearAll: boolean = false) {
    persistantObj = {
        loadedDt: new Date("2000-01-01"),
        idToken: clearAll ? "" : persistantObj.idToken,
        accessToken: clearAll ? "" : persistantObj.accessToken,
        refreshToken: clearAll ? "" : persistantObj.refreshToken,
        userName: clearAll ? "" : persistantObj.userName,
        firstName: clearAll ? "" : persistantObj.firstName,
        lastName: clearAll ? "" : persistantObj.lastName,
        userTenants: [] as any[],
        settings: [] as Settings[],
        selectedCustomer: clearAll ? "" : persistantObj.selectedCustomer,
        selectedHub: clearAll ? "" : persistantObj.selectedHub,
        selectedTenant: clearAll ? "" : persistantObj.selectedTenant,
        categories: [] as Category[],
        specialImages: [] as SpecialImage[],
        cartItems: [] as WebInventoryItem[],
        products: [] as WebInventoryItem[],
        currentAddress: {} as ScheduleAddress,
        nextDeliveryDate: clearAll ? "" : persistantObj.nextDeliveryDate,
        deliveryAddresses: [] as ScheduleAddress[],
        serviceAddresses: [] as ScheduleAddress[],
        tenantInfo: {} as any,
    };
}

export async function clearLocalStorage() {
    SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "H22: Clearing local storage.");
    clearPersistantObject(true);
    const keys = Object.keys(localStorage);
    SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "Clearing local storage.", keys);
    for (const key of keys) {
        if (key.startsWith("sqw_")) {
            localStorage.removeItem(key);
        }
    }
}
/*
export const encryptString = async (data: string): Promise<string> => {
    try {
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "J116: Encrypt Data: 1", data);
        const encryptedData = await aesEncrypt(data);
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "J116: Encrypt Data: 2", encryptedData);
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "J116: Encrypt Data: 2 String", JSON.stringify(encryptedData));
        return JSON.stringify(encryptedData);
    } catch (err) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error encrypting: ", err);
    }
    return "";
};

export const decryptString = async (data: string): Promise<string> => {
    try {
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "J116: Decrypt Data: 1", data);
        const encryptedObject = JSON.parse(data);
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "J116: Decrypt Data: 2", encryptedObject);
        const decryptedData = await aesDecrypt(encryptedObject);
        return decryptedData;
    } catch (err) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error decrypting: ", err);
        throw new Error("Error decrypting: " + err);
    }
    return "";
};
*/

const initialKey = "sqwB3N33ss>NMlsdf#6@ss";

/*export const encryptString2 = (data: string): string => {
    try {
        const pw = process.env.REACT_APP_P1 ?? initialKey;
        const encryptedData = sjcl.encrypt(pw, data);
        if (typeof encryptedData === "string") return encryptedData;
        return JSON.stringify(encryptedData);
    } catch (err) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error encrypting: ", err);
    }
    return "";
};*/

/*export const decryptString2 = (data: string): string => {
    try {
        const pw = process.env.REACT_APP_P1 ?? initialKey;
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "J113: Decrypting Data Before Parse: ", data);
        //Weird SJCL bug where we need to parse the data and then stringify it again.
        const encryptedData: SjclCipherEncrypted = JSON.parse(data);
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "J113: Decrypting Data After Parse: ", encryptedData);
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "J113: Decrypting Data After Parse: ", encryptedData.ct);
        //const encryptedData2 = JSON.parse(data);
        //SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "J113: Decrypting Data After Parse2: ", encryptedData2);
        const decryptedData = sjcl.decrypt(pw, encryptedData);
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "J113: Decrypting Data After Decrypt: ", decryptedData);
        if (typeof decryptedData === "string") return decryptedData;
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "J113: Decrypted Data: ", decryptedData);
        return JSON.stringify(decryptedData);
    } catch (err) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "J113: Error decrypting: ", err);
    }
    return "";
};*/

export async function GetSettingsRefresh() {
    persistantObj[PersistantObjectType.SETTINGS] = [];
    return GetSettings();
}

export async function GetSetting(settingName: string, defaultValue: string) {
    const settings = await GetSettings();
    SQW_Log(DebugLevel.LOG, "GetSetting: Settings", settings);
    if (!settings) {
        //SQW_Log(DebugLevel.LOG, "GetSetting: Settings not found.  Looking for ", settingName);
        return defaultValue;
    }

    try {
        SQW_Log(DebugLevel.LOG, "GetSetting: find setting in", settings);
        const setting = settings.find(
            (x: Settings) => x.name.trim().toLowerCase() === settingName.trim().toLowerCase(),
        );
        if (!setting) {
            SQW_Log(DebugLevel.LOG, "GetSetting: Setting not found.", settingName);
            return defaultValue;
        }
        return setting.value;
    } catch (err) {
        SQW_Log(
            DebugLevel.LOG,
            "GetSetting: error finding setting.  Looking for " + settingName + ".  Error: ",
            err,
        );
    }
    return defaultValue;
}

let loadingStoredItems = false;
export async function loadStoredItems() {
    let exitFunction = false;
    while (loadingStoredItems === true) {
        //If another process is loading the persistant object, wait for it to finish and then return
        await sleep(300);
        exitFunction = true;
    }
    if (exitFunction) return;

    loadingStoredItems = true;
    try {
        /*const d = new Date();
        d.setDate(d.getDate() - 1);

        SQW_Log(
            DebugLevel.INFO_WITH_INFO_TYPE,
            "loadStoredItems: Persistant Object loaded date.",
            persistantObj.loadedDt,
            d,
        );
        if (new Date(persistantObj.loadedDt) > d) {
            SQW_Log(
                DebugLevel.INFO_WITH_INFO_TYPE,
                "loadStoredItems: Not reloading persistant object.",
            );
            return;
        } else {
            SQW_Log(
                DebugLevel.INFO_WITH_INFO_TYPE,
                "loadStoredItems: Reloading persistant object.",
            );
        }*/

        SQW_Log(
            DebugLevel.INFO_WITH_INFO_TYPE,
            "loadStoredItems: Reloading persistant object from storage.",
        );

        const poString = localStorage.getItem("sqw_po");
        if (!poString) {
            SQW_Log(
                DebugLevel.INFO_WITH_INFO_TYPE,
                "No persistant object found, creating new one.",
            );
            //await CreatePersistantObject({} as AuthObject);
            return;
        }
        /*decryptString(poString).then((decryptedPoString) => {
            persistantObj = JSON.parse(decryptedPoString);
            persistantObj.loadedDt = new Date();
            SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "Loaded persistant object.", persistantObj);
        });*/
        try {
            const decryptedPoString = await decryptString(poString);
            persistantObj = JSON.parse(decryptedPoString);
            const d = new Date();
            d.setDate(d.getDate() - 1);
            if (new Date(persistantObj.loadedDt) < d) {
                SQW_Log(
                    DebugLevel.INFO_WITH_INFO_TYPE,
                    "H22:  loadStoredItems: Persist Data has expired, reloading persistant object.",
                    persistantObj.loadedDt,
                    new Date(persistantObj.loadedDt),
                    d,
                );
                clearPersistantObject(false);
                localStorage.removeItem("sqw_po");
                localStorage.removeItem("sqw_po_cd");
                await CreatePersistantObject({} as AuthObject, true);
                return;
            }

            const products = localStorage.getItem("sqw_products");
            if (products) {
                const productsDecrypt = await decryptString(products);
                persistantObj[PersistantObjectType.PRODUCTS] = JSON.parse(productsDecrypt);
                if (persistantObj[PersistantObjectType.PRODUCTS].length > 0) {
                    SQW_Log(
                        DebugLevel.LOG,
                        "H22: Products from local storage...",
                        persistantObj[PersistantObjectType.PRODUCTS],
                    );
                    globalDataContext.setLoadingProducts &&
                        globalDataContext.setLoadingProducts(false);
                }
            }
            const addr_del = localStorage.getItem("sqw_addr_del");
            if (addr_del) {
                const addr_delDecrypt = await decryptString(addr_del);
                persistantObj[PersistantObjectType.DEL_ADDRESSES] = JSON.parse(addr_delDecrypt);
                if (persistantObj[PersistantObjectType.DEL_ADDRESSES].length > 0) {
                    SQW_Log(
                        DebugLevel.LOG,
                        "H22: Delivery Addresses from local storage...",
                        persistantObj[PersistantObjectType.DEL_ADDRESSES],
                    );
                    globalDataContext.setLoadingDeliveryAddresses &&
                        globalDataContext.setLoadingDeliveryAddresses(false);
                }
            }
            const addr_svc = localStorage.getItem("sqw_addr_svc");
            if (addr_svc) {
                const addr_svcDecrypt = await decryptString(addr_svc);
                persistantObj[PersistantObjectType.SVC_ADDRESSES] = JSON.parse(addr_svcDecrypt);
                if (persistantObj[PersistantObjectType.SVC_ADDRESSES].length > 0) {
                    globalDataContext.setLoadingServiceAddresses &&
                        globalDataContext.setLoadingServiceAddresses(false);
                }
            }

            SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "Loaded persistant object.", persistantObj);
        } catch (errdecry) {
            SQW_Log(DebugLevel.LOG, "Error decrypting persisted items: ", errdecry);
            clearPersistantObject(false);
            localStorage.removeItem("sqw_po");
            localStorage.removeItem("sqw_po_cd");
            await CreatePersistantObject({} as AuthObject, true);
        }
    } catch (err) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error loading stored items.", err);
    } finally {
        loadingStoredItems = false;
    }
}

export async function getMyCategories() {
    const token = await API_Authenticate();
    const mcategories = await API_GetWebCategories(token, GetUserName());
    if (mcategories && mcategories.IsSuccessful) {
        //SQW_Log(DebugLevel.LOG, "getMyCategories: ", mcategories.Categories);
        const categories = mcategories.Categories;

        const hasAll: boolean = categories.some((c: Category) => c.ccatno === "ALL");

        categories.map((c: Category) => {
            if (!hasAll && c.cparentno === "") c.cparentno = "ALL";
        });

        if (!hasAll) categories.push({ ccatno: "ALL", cdescript: "ALL", cparentno: "" });

        return categories;
    } else {
        SQW_Log(DebugLevel.LOG, "getMyCategories unsuccessful: ", mcategories);
    }

    return [];
}

const getScheduleAddressesFromAPI = async (type: string, username: string) => {
    const token = await API_Authenticate();
    const addrs = await API_GetScheduleAddresses(token, username, type);
    if (!addrs) {
        SQW_Log(DebugLevel.LOG, "Error getting schedule addresses. (null)");
        return [];
    }
    if (!addrs.IsSuccessful) {
        SQW_Log(
            DebugLevel.LOG,
            "Error getting schedule addresses. (" + addrs.ErrorCode + ": " + addrs.Error + ")",
        );
        return [];
    }
    SQW_Log(DebugLevel.LOG, "G111:  Schedule addresses 1. ", addrs.Addresses);
    const schdAddrs = addrs.Addresses.map((a: any) => {
        return {
            cid: a.cid,
            ccustno: a.ccustno,
            cscono: a.cscono,
            cinvno: a.cinvno,
            caddr: a.caddr,
            ccompany: a.ccompany,
            htmlDisplay: a.htmlDisplay,
        };
    });
    SQW_Log(DebugLevel.LOG, "G111:  Schedule addresses 2. ", schdAddrs);
    return schdAddrs;
};

export async function GetTenantsAndStore() {
    const username = GetUserName();
    SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "DG11: Getting User Tenants...");
    SQW_Log(
        DebugLevel.INFO_WITH_INFO_TYPE,
        "DG11: Getting User Tenants for user" + username !== "" ? username : "NO USER FOUND",
    );
    if (username !== "") {
        try {
            SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "DG11: Selecting tenants...");
            const tenants = await SelectUserTenants(username);
            SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "DG11: User Tenants: length: ", tenants.length);
            if (tenants.length > 0) {
                persistantObj[PersistantObjectType.USER_TENANTS] = tenants.map(
                    (t: any) => t.tenantsId,
                );
                SQW_Log(
                    DebugLevel.INFO_WITH_INFO_TYPE,
                    "DG11: Saving User Tenants: ",
                    persistantObj.userTenants?.length,
                );
                saveStoredItems();
            }
        } catch (err) {
            SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "DG11: Error getting user tenants: ", err);
        }
    }
}

export async function LoadPersistantObj() {
    //await loadStoredItems();
    await CreatePersistantObject({} as AuthObject);
    let po = GetPersistantObject();
    /*try {
        SQW_Log(DebugLevel.LOG, "GH333:  getPersistantObj: ", po);
        if (po.deliveryAddresses.length === 0 || po.serviceAddresses.length === 0) {
            SQW_Log(
                DebugLevel.INFO_WITH_INFO_TYPE,
                "getPersistantObj: reloading persistant object",
            );
            await CreatePersistantObject({} as AuthObject);
            po = GetPersistantObject();
        }
    } catch (err) {
        SQW_Log(DebugLevel.LOG, "getPersistantObj: Error checking persistant object: ", err);
    }*/
    return po;
}

let creatingPersistantObject = false;
export async function CreatePersistantObject(tokens: AuthObject, skipLoad: boolean = false) {
    let exitFunction = false;
    while (creatingPersistantObject) {
        //If another process is creating the persistant object, wait for it to finish and then return
        exitFunction = true;
        await sleep(100);
    }
    //if (exitFunction) return;

    //If the persistant object gets reset on render, reload from storage
    if (!skipLoad && persistantObj.loadedDt < new Date("01/01/2024")) {
        SQW_Log(
            DebugLevel.INFO_WITH_INFO_TYPE,
            "CreatePersistantObject: Reloading persistant object from storage.",
        );
        await loadStoredItems();
    }

    creatingPersistantObject = true;
    const funcCode = "CreatePersistantObject: ";
    let save = false;
    try {
        SQW_Log(DebugLevel.LOG, funcCode + "Creating Persistant Object: ", persistantObj);
        if (
            persistantObj[PersistantObjectType.ID_TOKEN].length === 0 &&
            tokens.idToken.length > 10
        ) {
            persistantObj[PersistantObjectType.ID_TOKEN] = tokens.idToken;
            persistantObj[PersistantObjectType.ACCESS_TOKEN] = tokens.at;
            persistantObj[PersistantObjectType.REFRESH_TOKEN] = tokens.rt;
            persistantObj[PersistantObjectType.USER_NAME] = tokens.user;
            save = true;
        }

        if (persistantObj[PersistantObjectType.USER_NAME] == "") {
            const user = GetUserName();
            if (user === "") {
                SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, funcCode + "No user found.");
                creatingPersistantObject = false;
                return;
            }
            persistantObj[PersistantObjectType.USER_NAME] = user;
            save = true;
        }

        if (persistantObj[PersistantObjectType.ID_TOKEN].length === 0) {
            //No tokens, so we can't get any data.
            SQW_Log(
                DebugLevel.LOG,
                funcCode + "CreatePersistantObject:  No tokens, so we can't get any data.",
            );
            creatingPersistantObject = false;
            return;
        }

        if (persistantObj[PersistantObjectType.USER_TENANTS].length === 0) {
            SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "DG11: Getting User Tenants...");
            const username = GetUserName();
            if (username !== "") {
                globalDataContext?.setLoadingTenants && globalDataContext.setLoadingTenants(true);
                SQW_Log(
                    DebugLevel.INFO_WITH_INFO_TYPE,
                    funcCode + "DG11: Getting User Tenants for " + username,
                );
                try {
                    const tenants = await SelectUserTenants(username);
                    SQW_Log(
                        DebugLevel.INFO_WITH_INFO_TYPE,
                        funcCode + "DG11: User Tenants: ",
                        tenants ?? "null",
                    );
                    if (tenants && tenants.length > 0) {
                        persistantObj[PersistantObjectType.USER_TENANTS] = tenants.map(
                            (t: any) => t.tenantsId,
                        );
                        save = true;
                    }
                } catch (err) {
                    SQW_Log(
                        DebugLevel.ERROR_WITH_ERROR_TYPE,
                        funcCode + "DG11: Error getting user tenants: ",
                        err,
                    );
                } finally {
                    globalDataContext?.setLoadingTenants &&
                        globalDataContext.setLoadingTenants(false);
                }
            }
        }

        if (persistantObj[PersistantObjectType.TENANT_INFO].length === 0) {
            if (
                GetCurrentTenantName() &&
                GetCurrentTenantName().length > 0 &&
                persistantObj[PersistantObjectType.ID_TOKEN].length > 0
            ) {
                const tenantInfo = await SelectTenant(GetCurrentTenantName());
                if (tenantInfo) {
                    persistantObj[PersistantObjectType.TENANT_INFO] = tenantInfo;
                    save = true;
                }
            }
        }

        if (persistantObj[PersistantObjectType.CATEGORIES].length === 0) {
            const categories = await getMyCategories();
            if (categories && categories.length > 0) {
                persistantObj[PersistantObjectType.CATEGORIES] = categories;
                save = true;
            }
        }

        if (persistantObj[PersistantObjectType.DEL_ADDRESSES].length === 0) {
            SQW_Log(
                DebugLevel.LOG,
                funcCode + "H22: Loading Delivery Addresses...",
                persistantObj[PersistantObjectType.DEL_ADDRESSES],
            );
            globalDataContext.setLoadingDeliveryAddresses &&
                globalDataContext.setLoadingDeliveryAddresses(true);
            try {
                const addresses = await getScheduleAddressesFromAPI("D", GetUserName());
                SQW_Log(DebugLevel.LOG, funcCode + "G111: AddressesFromApi: ", addresses);
                if (addresses && addresses.length) {
                    SQW_Log(DebugLevel.LOG, funcCode + "G111: UserName: ", GetUserName());
                    SQW_Log(DebugLevel.LOG, funcCode + "G111: Addresses: ", addresses);
                    if (addresses && addresses.length > 0) {
                        SQW_Log(DebugLevel.LOG, funcCode + "G111: Setting Addresses: ", addresses);
                        persistantObj[PersistantObjectType.CURRENT_ADDRESS] = addresses[0];
                        persistantObj.deliveryAddresses = addresses;
                        persistantObj[PersistantObjectType.SELECTED_CUSTOMER] =
                            addresses[0].ccustno;
                        persistantObj[PersistantObjectType.SELECTED_HUB] = addresses[0].chub;
                        save = true;
                        SQW_Log(DebugLevel.LOG, funcCode + "G111: Set Addresses: ", persistantObj);
                        SQW_Log(
                            DebugLevel.LOG,
                            funcCode + "G111: Delivery Addresses: ",
                            persistantObj.deliveryAddresses,
                        );

                        //const products = await getAPIAllProducts("CreatePersistant...", addresses[0]);
                    }
                }
            } catch (err) {
                SQW_Log(
                    DebugLevel.ERROR_WITH_ERROR_TYPE,
                    funcCode + "G111: Error loading delivery addresses: ",
                    err,
                );
            } finally {
                SQW_Log(DebugLevel.LOG, funcCode + "G111: Loading Delivery Addresses Done.");
                SQW_Log(DebugLevel.LOG, funcCode + "H22: Loading Delivery Addresses Done...");
                globalDataContext.setLoadingDeliveryAddresses &&
                    globalDataContext.setLoadingDeliveryAddresses(false);
            }
        }

        if (persistantObj[PersistantObjectType.SVC_ADDRESSES].length === 0) {
            globalDataContext.setLoadingServiceAddresses &&
                globalDataContext.setLoadingServiceAddresses(true);
            try {
                const svc_addresses = await getScheduleAddressesFromAPI("SVC", GetUserName());
                if (svc_addresses && svc_addresses.length > 0) {
                    persistantObj[PersistantObjectType.SVC_ADDRESSES] = svc_addresses;
                    save = true;
                    SQW_Log(
                        DebugLevel.LOG,
                        funcCode + "G111: Service Addresses: ",
                        persistantObj.serviceAddresses,
                    );
                }
            } catch (err) {
                SQW_Log(
                    DebugLevel.ERROR_WITH_ERROR_TYPE,
                    funcCode + "G111: Error loading service addresses: ",
                    err,
                );
            } finally {
                globalDataContext.setLoadingServiceAddresses &&
                    globalDataContext.setLoadingServiceAddresses(false);
            }
        }

        loadProducts();

        if (persistantObj[PersistantObjectType.SPECIAL_IMAGES].length === 0) {
            const images = await getSpecialImages();
            if (images && images?.Images) {
                persistantObj[PersistantObjectType.SPECIAL_IMAGES] = images.Images;
                save = true;
            }
        }
        if (persistantObj[PersistantObjectType.SETTINGS].length === 0) {
            const settings = await getSettingsFromAPI();
            if (settings && settings.length > 0) {
                persistantObj[PersistantObjectType.SETTINGS] = settings;
                save = true;
            }
        }

        if (save) {
            persistantObj.loadedDt = new Date();
            //storeSettings();
            SQW_Log(DebugLevel.LOG, funcCode + "Saving Persistant Object: ", persistantObj);
            saveStoredItems();
        }
    } catch (err) {
        SQW_Log(DebugLevel.LOG, "Error loading data: ", err);
    } finally {
        creatingPersistantObject = false;
    }
}

let fetchingProducts = false;
function loadProducts() {
    if (fetchingProducts) {
        return;
    }
    fetchingProducts = true;

    try {
        const funcCode = "loadProducts: ";

        if (persistantObj[PersistantObjectType.PRODUCTS].length === 0) {
            SQW_Log(
                DebugLevel.INFO_WITH_INFO_TYPE,
                funcCode + "H22: DG11: No stored products found...",
                persistantObj[PersistantObjectType.PRODUCTS],
            );
            globalDataContext.setLoadingProducts && globalDataContext.setLoadingProducts(true);
            getAPIAllProducts("DG11: CreatePersistant...")
                .then((products) => {
                    SQW_Log(
                        DebugLevel.INFO_WITH_INFO_TYPE,
                        funcCode + "DG11: H22: Initial Load: Products: ",
                        products,
                    );
                    if (products?.Items && products.Items?.length > 0) {
                        SQW_Log(
                            DebugLevel.INFO_WITH_INFO_TYPE,
                            funcCode + "DG11: H22: Initial Load: Storing Products: ",
                            products.Items,
                        );
                        persistantObj[PersistantObjectType.PRODUCTS] = products.Items;
                        if (persistantObj.products.length > 0)
                            encryptString(JSON.stringify(persistantObj.products)).then(
                                (encryptedPoString) => {
                                    localStorage.setItem("sqw_products", encryptedPoString);
                                },
                            );
                    }
                })
                .finally(() => {
                    SQW_Log(
                        DebugLevel.INFO_WITH_INFO_TYPE,
                        funcCode + "DG11: H22: Initial Load: Loading Products Done.",
                    );
                    globalDataContext.setLoadingProducts &&
                        globalDataContext.setLoadingProducts(false);
                });
        }
    } catch (err) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error loading products: ", err);
    } finally {
        fetchingProducts = false;
    }
}
/*export async function CreatePersistantObject() {
    persistantObj[PersistantObjectType.ID_TOKEN] = await globalIdToken();
    persistantObj[PersistantObjectType.ACCESS_TOKEN] = await globalAccessToken();
    persistantObj[PersistantObjectType.REFRESH_TOKEN] = await globalRefreshToken();
    persistantObj[PersistantObjectType.USER_NAME] = GetUserName();
    persistantObj[PersistantObjectType.USER_TENANTS] = globalUserTenants();
    persistantObj.loadedDt = new Date();
    saveStoredItems();
    storeSettings();
}*/

export async function GetSettings() {
    if ((persistantObj[PersistantObjectType.SETTINGS] ?? []).length === 0) await storeSettings();
    return persistantObj[PersistantObjectType.SETTINGS];
}

function FormatSettings(settings: any) {
    const newSettings: Settings[] = [];

    if (!settings) {
        return defaultSettings;
    }

    for (let i = 0; i < settings.length; i++) {
        var setting = settings[i];
        const newSetting = defaultSettings.find(
            (x) => x.name.trim().toLowerCase() === setting.name.trim().toLowerCase(),
        );
        if (newSetting) {
            newSetting.value = setting.value;
            newSettings.push(newSetting);
        }
    }

    return newSettings;
}

export async function getSpecialImages() {
    const token = await API_Authenticate();
    const images = await API_GetSpecialImages(token);
    if (images && images.IsSuccessful) {
        return images;
    }
    return null;
}

export async function getSettingsFromAPI() {
    const token = await API_Authenticate();
    const settings = await API_GetSettings(token);
    if (settings && settings.IsSuccessful) {
        return settings.Settings;
    }
    return defaultSettings;
}

async function storeSettings() {
    const settings = await getSettingsFromAPI();
    const newJSON = FormatSettings(settings);
    storeItem(PersistantObjectType.SETTINGS, newJSON);
    saveStoredItems();
}

let fetchingTenants = false;
export async function fetchTenantInfo() {
    let runIter = 0;
    while (fetchingTenants && runIter < 30) {
        SQW_Log(
            DebugLevel.INFO_WITH_INFO_TYPE,
            "A3334: Waiting for previous tenant fetch to complete...",
        );
        await sleep(1000);
        runIter++;
    }
    try {
        fetchingTenants = true;
        if (GetCurrentTenantName() && GetCurrentTenantName() !== "") {
            const ti = getItem(PersistantObjectType.TENANT_INFO) || "";
            if (ti !== "") {
                SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "A3334: Found existing tenant info:", ti);
                let tenantInfo: TenantInfo | undefined = undefined;
                try {
                    if (typeof ti === "string") {
                        SQW_Log(
                            DebugLevel.LOG,
                            "A3334: A1: Found existing tenant info as string:",
                            ti,
                        );
                        tenantInfo = JSON.parse(ti) as TenantInfo;
                        SQW_Log(
                            DebugLevel.LOG,
                            "A3334: A1: Found existing tenant info as string:",
                            ti,
                        );
                    } else {
                        SQW_Log(
                            DebugLevel.LOG,
                            "A3334: A1: Found existing tenant info as object:",
                            ti,
                        );
                        tenantInfo = ti as TenantInfo;
                        SQW_Log(
                            DebugLevel.LOG,
                            "A3334: A1: Found existing tenant info as object:",
                            ti,
                            tenantInfo,
                        );
                    }
                } catch (error) {
                    SQW_Log(
                        DebugLevel.ERROR_WITH_ERROR_TYPE,
                        "A3334:  Error fetching tenant info:",
                        error,
                    );
                }

                if (tenantInfo && tenantInfo.subdomain === GetCurrentTenantName()) {
                    SQW_Log(
                        DebugLevel.INFO_WITH_INFO_TYPE,
                        "A3334: A2: Found existing tenant info:",
                        tenantInfo,
                    );
                    return tenantInfo;
                } else {
                    SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "A3334: A2: Why no match?");
                }
            } else {
                SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "A3334: A2: No tenant info found.");
            }

            const tInfo = await SelectTenant(GetCurrentTenantName());
            SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "A3334: A2: new tenantinfo:", tInfo);
            if (tInfo && tInfo.length > 0) {
                storeItem(PersistantObjectType.TENANT_INFO, JSON.stringify(tInfo[0]));
                saveStoredItems();
                return tInfo[0];
            }
        }
    } catch (error) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error fetching tenant info:", error);
    } finally {
        fetchingTenants = false;
    }

    return {} as TenantInfo;
}
