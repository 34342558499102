import { ScheduleAddress, WebInventoryItem } from "interfaces/InvoiceInterfaces";
import { SQW_Log, DebugLevel } from "services/Logging";
import { bool } from "prop-types";
import React, { createContext, useState, useContext, useEffect } from "react";

export interface DataContextType {
    deliveryAddresses: ScheduleAddress[];
    serviceAddresses: ScheduleAddress[];
    products: WebInventoryItem[];
    loadingDeliveryAddresses: boolean;
    loadingServiceAddresses: boolean;
    loadingProducts: boolean;
    setLoadingDeliveryAddresses?: React.Dispatch<React.SetStateAction<boolean>>;
    setLoadingServiceAddresses?: React.Dispatch<React.SetStateAction<boolean>>;
    setLoadingProducts?: React.Dispatch<React.SetStateAction<boolean>>;
    // setLoadingDeliveryAddresses?: (loading: boolean) => void;
    // setLoadingServiceAddresses?: (loading: boolean) => void;
    // setLoadingProducts?: (loading: boolean) => void;
    setSignedInUser?: React.Dispatch<React.SetStateAction<string>>;
    signedInUser: string;
    loadingTenants: boolean;
    setLoadingTenants?: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultContextValue: DataContextType = {
    deliveryAddresses: [] as ScheduleAddress[],
    serviceAddresses: [] as ScheduleAddress[],
    products: [] as WebInventoryItem[],
    loadingDeliveryAddresses: true,
    loadingServiceAddresses: true,
    loadingProducts: true,
    setLoadingDeliveryAddresses: undefined,
    setLoadingServiceAddresses: undefined,
    setLoadingProducts: undefined,
    setSignedInUser: undefined,
    signedInUser: "",
    loadingTenants: true,
    setLoadingTenants: undefined,
};

const DataContext = createContext<DataContextType>(defaultContextValue);

interface DataProviderProps {
    children: React.ReactNode;
}

export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
    const [deliveryAddresses, setDeliveryAddresses] = useState<ScheduleAddress[]>([]);
    const [serviceAddresses, setServiceAddresses] = useState<ScheduleAddress[]>([]);
    const [products, setProducts] = useState<WebInventoryItem[]>([]);
    const [loadingDeliveryAddresses, setLoadingDeliveryAddresses] = useState(true);
    const [loadingServiceAddresses, setLoadingServiceAddresses] = useState(true);
    const [loadingProducts, setLoadingProducts] = useState(true);
    const [loadingTenants, setLoadingTenants] = useState(true);
    const [signedInUser, setSignedInUser] = useState<string>("");

    useEffect(() => {
        console.info("H22: DataProvider: useEffect: Initial Load.");
        setLoadingDeliveryAddresses(true);
        setLoadingServiceAddresses(true);
        setLoadingProducts(true);
    }, []);

    // const loadingDeliveryAddressesRef = React.useRef(true);
    // const loadingServiceAddressesRef = React.useRef(true);
    // const loadingProductsRef = React.useRef(true);

    // const setLoadingDeliveryAddresses = (loading: boolean) => {
    //     loadingDeliveryAddressesRef.current = loading;
    // };
    // const setLoadingProducts = (loading: boolean) => {
    //     loadingProductsRef.current = loading;
    // };

    // const setLoadingServiceAddresses = (loading: boolean) => {
    //     loadingServiceAddressesRef.current = loading;
    // };

    // SQW_Log(
    //     DebugLevel.INFO_WITH_INFO_TYPE,
    //     "initial:  H22: DataProvider: deliveryAddresses: ",
    //     deliveryAddresses,
    // );
    // SQW_Log(
    //     DebugLevel.INFO_WITH_INFO_TYPE,
    //     "initial:  H22: DataProvider: serviceAddresses: ",
    //     serviceAddresses,
    // );
    // SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "initial:  H22: DataProvider: products: ", products);
    SQW_Log(
        DebugLevel.INFO_WITH_INFO_TYPE,
        "initial:  H22: DataProvider: loadingDeliveryAddresses: ",
        loadingDeliveryAddresses,
    );
    // SQW_Log(
    //     DebugLevel.INFO_WITH_INFO_TYPE,
    //     "initial:  H22: DataProvider: loadingServiceAddresses: ",
    //     loadingServiceAddresses,
    // );
    SQW_Log(
        DebugLevel.INFO_WITH_INFO_TYPE,
        "initial:  H22: DataProvider: loadingProducts: ",
        loadingProducts,
    );

    useEffect(() => {
        SQW_Log(
            DebugLevel.INFO_WITH_INFO_TYPE,
            "H22: DataProvider: useEffect: loadingDeliveryAddress changed.",
            loadingDeliveryAddresses,
        );
    }, [loadingDeliveryAddresses]);

    useEffect(() => {
        SQW_Log(
            DebugLevel.INFO_WITH_INFO_TYPE,
            "H22: DataProvider: useEffect: loadingProducts changed.",
            loadingProducts,
        );
    }, [loadingProducts]);

    /*useEffect(() => {
        SQW_Log(
            DebugLevel.INFO_WITH_INFO_TYPE,
            "DataProvider: useEffect: loadingDeliveryAddresses changed.",
            loadingDeliveryAddresses,
        );
        setLoadingDeliveryAddresses(false);
    }, [deliveryAddresses]);

    useEffect(() => {
        SQW_Log(
            DebugLevel.INFO_WITH_INFO_TYPE,
            "DataProvider: useEffect: loadingServiceAddresses changed.",
            loadingServiceAddresses,
        );
        setLoadingServiceAddresses(false);
    }, [serviceAddresses]);

    useEffect(() => {
        SQW_Log(
            DebugLevel.INFO_WITH_INFO_TYPE,
            "DataProvider: useEffect: loadingProducts changed.",
            loadingProducts,
        );
        setLoadingProducts(false);
    }, [products]);*/

    return (
        <DataContext.Provider
            value={{
                deliveryAddresses: deliveryAddresses,
                serviceAddresses: serviceAddresses,
                products: products,
                loadingDeliveryAddresses: loadingDeliveryAddresses,
                loadingServiceAddresses: loadingServiceAddresses,
                loadingProducts: loadingProducts,
                setLoadingDeliveryAddresses: setLoadingDeliveryAddresses,
                setLoadingServiceAddresses: setLoadingServiceAddresses,
                setLoadingProducts: setLoadingProducts,
                setSignedInUser,
                signedInUser,
                loadingTenants: loadingTenants,
                setLoadingTenants: setLoadingTenants,
            }}
        >
            {children}
        </DataContext.Provider>
    );
};

export const useDataState = (): DataContextType => useContext(DataContext);
