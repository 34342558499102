import { useNavigate, NavigateFunction, useLocation } from "react-router-dom";
import React, { useContext } from "react";
import { getTenantNameFromSubdomain } from "services/GetInvoiceData";
import {
    getItemStr,
    PersistantObjectType,
    TenantInfo,
    fetchTenantInfo,
} from "services/PersistantObjects";
import { DataContextType, useDataState } from "context/DataContext";
import { CognitoAuthContextType, CognitoAuthContext } from "helpers/CognitoAuthRoute";

//import { AuthContext } from "../context/AuthContext";

export type AuthContextType = {
    token: any;
    isAuthenticated: any;
    setIsUserAuthenticated: any;
    cognitoAuthenticateUser: any;
    accessToken: any;
    refreshToken: any;
    cognitoSignOut: any;
    currentUser: any;
    currentUserTenants: any;
    isLoading: boolean;
    onChooseTenant: any;
    checkSessionGetTokens: any;
    hasTokenExpired: any;
    isValidSession: any;
    getTenantInfo: any;
    authenticationComplete: any;
};

let globalNavigate: NavigateFunction;
let globalDataContext: DataContextType;
let globalCognitoAuthContext: CognitoAuthContextType;
let globalTenant: string;
let globalTenantInfo: () => Promise<TenantInfo | null>;
let globalUser: () => string;
let globalSetInitialTokens: (idToken: string, accessToken: string, refreshToken: string) => void;
let globalIdToken: () => Promise<string>;
let globalAccessToken: () => Promise<string>;
let globalRefreshToken: () => Promise<string>;
let globalSignOut: () => true;
let globalLogin: () => void;
let initialToken: string = "";
let initialAccessToken: string = "";
let initialRefreshToken: string = "";
let globalAlert: (title: string, message: string) => void;
let globalAlertMsg: (message: string) => void;

const Globals = () => {
    const location = useLocation();

    globalTenant = getTenantNameFromSubdomain() ?? "";

    React.useEffect(() => {
        //SQW_Log(DebugLevel.LOG, "Globals: PathName changed.", location);
        globalTenant = getTenantNameFromSubdomain() ?? "";
    }, [location.pathname]);

    globalNavigate = useNavigate();
    //globalAuthContext = useContext(AuthContext);
    globalDataContext = useDataState();
    globalCognitoAuthContext = useContext(CognitoAuthContext);
    /*globalSetUser = (username: string) => {
        if (globalAuthContext) {
            globalAuthContext.setCurrentUser(username);
            localStorage.setItem("sqw_user", username);
        }
    };*/
    globalUser = () => {
        return getItemStr(PersistantObjectType.USER_NAME) ?? "";
    };

    globalAlert = (title: string, message: string) => {
        return globalCognitoAuthContext?.DisplayAlert(title, message);
    };
    globalAlertMsg = (message: string) => {
        return globalCognitoAuthContext?.DisplayAlert("", message);
    };

    globalSetInitialTokens = (idToken: string, accessToken: string, refreshToken: string) => {
        initialToken = idToken;
        initialAccessToken = accessToken;
        initialRefreshToken = refreshToken;
    };

    globalIdToken = async (): Promise<string> => {
        return getItemStr(PersistantObjectType.ID_TOKEN) ?? "";
    };
    globalAccessToken = async (): Promise<string> => {
        return getItemStr(PersistantObjectType.ACCESS_TOKEN) ?? "";
    };
    globalRefreshToken = async (): Promise<string> => {
        return getItemStr(PersistantObjectType.REFRESH_TOKEN) ?? "";
    };
    globalSignOut = () => {
        const resp = globalCognitoAuthContext?.signOut();
        return resp;
    };

    globalTenantInfo = async () => {
        return await fetchTenantInfo();
    };

    return null;
};

export {
    Globals,
    globalNavigate,
    globalIdToken,
    globalCognitoAuthContext,
    globalTenant,
    globalUser,
    globalSignOut,
    globalAccessToken,
    globalRefreshToken,
    globalLogin,
    globalSetInitialTokens,
    globalTenantInfo,
    globalDataContext,
    globalAlert,
    globalAlertMsg,
};
