import { Button } from "@aws-amplify/ui-react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { experimentalStyled as styled } from "@mui/material/styles";
import * as React from "react";
import { GreenButton } from "components/Buttons";

import { SelectAllTenants } from "../services/GraphQlHelper";
import { getOverrideProps } from "../ui-components/utils";
//import TenantsUpdateForm from "./TenantsUpdateForm";
import TenantsUpdateForm from "../ui-components/TenantsUpdateForm";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const pages = [
    {
        name: "Create New Tenant",
        link: "CreateTenants",
    },
    {
        name: "Update Tenants",
        link: "UpdateTenants",
    },
    {
        name: "Users",
        link: "Users",
    },
];

interface UpdateTenantsProps {
    onSuccess: () => void;
}

export default function UpdateTenants(props: UpdateTenantsProps) {
    const [selectedPage, setSelectedPage] = React.useState("default");
    const [tenants, setTenants] = React.useState([] as any[]);

    function TenantsTable() {
        //SQW_Log(DebugLevel.LOG, "Calling TenantsTable");
        return (
            <>
                <TableContainer component={Paper}>
                    <Table aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell align='left'>
                                    <h5>Tenant ID/Subdomain</h5>
                                </TableCell>
                                <TableCell align='left'>
                                    <h5>Description</h5>
                                </TableCell>
                                <TableCell align='left'>
                                    <h5>API URL</h5>
                                </TableCell>
                                <TableCell align='left'></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tenants
                                .sort((a, b) => (a > b ? 1 : -1))
                                .map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            "&:last-child td, &:last-child th": { border: 0 },
                                        }}
                                    >
                                        <TableCell
                                            component='th'
                                            scope='row'
                                        >
                                            {row.id}
                                        </TableCell>
                                        <TableCell
                                            component='th'
                                            scope='row'
                                        >
                                            {row.name}
                                        </TableCell>
                                        <TableCell
                                            component='th'
                                            scope='row'
                                        >
                                            {row.url}
                                        </TableCell>
                                        <TableCell align='right'>
                                            <GreenButton
                                                style={{ padding: "30px" }}
                                                onClick={() => {
                                                    setSelectedPage(row.id);
                                                }}
                                            >
                                                Update
                                            </GreenButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }

    React.useEffect(() => {
        const tnames = [] as string[];
        SelectAllTenants().then((tenants) => {
            if (tenants?.data?.listTenants?.items)
                /*for (let i = 0; i < tenants.data.listTenants.items.length; i++) {
                    tnames.push(tenants.data.listTenants.items[i].id);
                }
                setTenants(tnames);*/
                setTenants(tenants.data.listTenants.items);
        });
    }, []);
    function showPage() {
        switch (selectedPage) {
            case "default":
                return (
                    <>
                        <TenantsTable />

                        <br />
                        <br />
                        <Button
                            children='Go Back'
                            type='reset'
                            onClick={(event) => {
                                event.preventDefault();
                                props.onSuccess();
                            }}
                            {...getOverrideProps(null, "ClearButton")}
                        ></Button>
                    </>
                );
            default:
                return (
                    <div>
                        <h3>{selectedPage}</h3>
                        <TenantsUpdateForm
                            id={selectedPage}
                            onSuccess={() => {
                                setSelectedPage("default");
                            }}
                            onCancel={() => {
                                setSelectedPage("default");
                            }}
                        />
                    </div>
                );
        }
    }

    return <Box sx={{ flexGrow: 1 }}>{showPage()}</Box>;
}
