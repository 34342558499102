export enum DebugLevel {
    INSANE = 100000000,
    DEBUG = 99,
    LOG = 50,
    INFO = 40,
    INFO_WITH_INFO_TYPE = 39,
    WARN = 30,
    WARN_WITH_WARN_TYPE = 29,
    ERROR = 20,
    ERROR_WITH_ERROR_TYPE = 19,
    CRITICAL = 10,
    NONE = 0,
}

export const alwaysLogLevel = DebugLevel.ERROR;
export let debuggingMode = false;
export let debugLevel = DebugLevel.ERROR;

export function shouldLog(level: DebugLevel) {
    //return true;
    return alwaysLogLevel > level || (debuggingMode && debugLevel >= level);
}

export function SQW_Log(level: DebugLevel, message: any, ...optionalParams: any[]) {
    if (shouldLog(level)) {
        if (level === DebugLevel.INFO_WITH_INFO_TYPE) console.info(message, ...optionalParams);
        else if (level === DebugLevel.WARN_WITH_WARN_TYPE) console.warn(message, ...optionalParams);
        else if (level === DebugLevel.ERROR_WITH_ERROR_TYPE)
            console.error(message, ...optionalParams);
        else console.log(message, ...optionalParams);
    }
}

export function setDebuggingMode(debLevel: string) {
    debuggingMode = true;
    debugLevel = Number(debLevel);

    if (isNaN(debugLevel)) debugLevel = DebugLevel.ERROR;

    SQW_Log(
        DebugLevel.WARN_WITH_WARN_TYPE,
        "**********************Debugging Mode Enabled.***************************",
    );
}
