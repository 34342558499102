import React, { useEffect, useReducer, useContext } from "react";
import { SQW_Log, DebugLevel } from "services/Logging";
import { Text, Link, Grid, Card } from "@aws-amplify/ui-react";
import { AppBar, Box, Toolbar } from "@mui/material";
import { globalTenantInfo } from "helpers/Globals";
import { cb } from "@react-email/components";
import { footerSize, getMainAppSize, setFooterSize } from "services/GetInvoiceData";

import packageJson from "../../package.json";
import { useIsMobile } from "../helpers/CustomHooks";
import { useHubState } from "../context/HubContext";

const Footer: React.FC<{ cbHeight: number }> = ({ cbHeight }) => {
    const { hubState } = useHubState();
    const isMobile = useIsMobile();
    const [stickyOrStatic, setStickyOrStatic] = React.useState<
        "fixed" | "relative" | "absolute" | "static" | "sticky"
    >("static");
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const [state, setState] = React.useState({
        companyName: "Prism Visual Software",
        companyLink: "https://www.prismvs.com/",
        address1: "123 Main Street",
        address2: "",
        city: "Anytown",
        stateAbrv: "CA",
        zip: "12345",
        phone: "(123) 456-7890",
        email: "prism@prismvs.com",
        tenant: {} as any,
    });

    const { companyName, address1, address2, city, stateAbrv, zip, phone, email } = state;

    useEffect(() => {
        SQW_Log(DebugLevel.LOG, "Footer: mainAppSize: ", getMainAppSize());
        SQW_Log(DebugLevel.LOG, "Footer: cbHeight", cbHeight);
    }, [cbHeight]);

    useEffect(() => {
        SQW_Log(DebugLevel.LOG, "Footer force update: stickyOrStatic", stickyOrStatic);
        forceUpdate();
    }, [stickyOrStatic]);

    useEffect(() => {
        setFooterSize(address2 && address2?.trim() !== "" ? 110 : 80);
    }, [address2]);

    const MemoFooter = React.memo<{
        position: "fixed" | "relative" | "absolute" | "static" | "sticky";
    }>(
        ({ position }) => {
            return (
                <AppBar
                    position={cbHeight < getMainAppSize() ? "fixed" : "static"}
                    sx={{ top: "auto", bottom: 0, marginTop: "20px" }}
                >
                    <Toolbar
                        disableGutters
                        sx={{
                            width: "100vw",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: "0",
                            marginBottom: "0",
                        }}
                    >
                        <Box sx={{ width: "100vw", height: "auto" }}>
                            <Grid
                                width='100vw'
                                height={address2 && address2?.trim() !== "" ? "110px" : "80px"}
                                backgroundColor='hsla(210, 25%, 16%, 1)'
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                                padding='0 20px'
                                color='#ffffff'
                            >
                                <Card backgroundColor='transparent'>
                                    <Text color='#ffffff'>{companyName}</Text>
                                    <Text color='#ffffff'>
                                        {address1 && address1?.trim() !== "" && <>{address1}</>}
                                    </Text>
                                    <Text color='#ffffff'>
                                        {address2 && address2?.trim() !== "" && <>{address2}</>}
                                    </Text>
                                    <Text color='#ffffff'>
                                        {city} {stateAbrv} {zip}
                                    </Text>
                                </Card>
                                {!isMobile && (
                                    <Card backgroundColor='transparent'>
                                        <Link
                                            href='https://www.prismvs.com/'
                                            target='_blank'
                                            rel='noopener noreferrrer'
                                            color='#ffffff'
                                        >
                                            Prism Visual Software, Inc {getYear()}. All Rights
                                            Reserved. Powered by ServQuest © v{packageJson.version}
                                        </Link>
                                    </Card>
                                )}
                                <Card
                                    backgroundColor='transparent'
                                    color='#ffffff'
                                >
                                    <Text color='#ffffff'>{phone !== "" && <>{phone}</>}</Text>
                                    <Text color='#ffffff'>
                                        {email !== "" && (
                                            <Link
                                                href={"mailto:" + email}
                                                target='_blank'
                                                rel='noopener noreferrrer'
                                                color='#ffffff'
                                            >
                                                {email}
                                            </Link>
                                        )}
                                    </Text>
                                </Card>
                            </Grid>
                            {isMobile && (
                                <Grid
                                    width='100%'
                                    backgroundColor='hsla(210, 25%, 16%, 1)'
                                    display='flex'
                                    alignItems='center'
                                    textAlign='center'
                                    justifyContent='center'
                                    color='#ffffff'
                                >
                                    <Card backgroundColor='transparent'>
                                        <Link
                                            href='https://www.prismvs.com/'
                                            target='_blank'
                                            rel='noopener noreferrrer'
                                            color='#ffffff'
                                        >
                                            Prism Visual Software, Inc {getYear()}. All Rights
                                            Reserved. Powered by ServQuest © v{packageJson.version}
                                        </Link>
                                    </Card>
                                </Grid>
                            )}
                        </Box>
                    </Toolbar>
                </AppBar>
            );
        },
        (prevProps, nextProps) => {
            // Return true if props are equal, false if they are different
            SQW_Log(DebugLevel.LOG, "Footer: prevProps", prevProps);
            SQW_Log(DebugLevel.LOG, "Footer: nextProps", nextProps);
            return prevProps.position === nextProps.position;
        },
    );

    async function updateTenantInfo() {
        let tenant: any = {
            companyname: "Advantage Route Inc",
            companylink: "https://www.advantageroute.com/",
            address1: "123 Main Street",
            address2: "",
            city: "Anytown",
            state: "CA",
            zip: "12345",
            phone: "(123) 456-7890",
            email: "info@advantageroute.com",
            tenant: {} as any,
        };

        try {
            const currentTenant = await globalTenantInfo();
            if (currentTenant) {
                tenant = currentTenant;
            }
        } catch (err) {
            SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, err);
        }
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "Current Tenant", tenant);

        setState((prevState) => ({ ...prevState, companyName: tenant.companyname }));
        setState((prevState) => ({ ...prevState, address1: tenant.address1 }));
        setState((prevState) => ({ ...prevState, address2: tenant.address2 }));
        setState((prevState) => ({ ...prevState, city: tenant.city }));
        setState((prevState) => ({ ...prevState, stateAbrv: tenant.state }));
        setState((prevState) => ({ ...prevState, zip: tenant.zip }));
        setState((prevState) => ({ ...prevState, phone: tenant.phone }));
        setState((prevState) => ({ ...prevState, email: tenant.email }));

        /*GetTenantInfo().then((tenant: any) => {
            setState((prevState) => ({ ...prevState, companyName: tenant.companyname }));
            setState((prevState) => ({ ...prevState, address1: tenant.address1 }));
            setState((prevState) => ({ ...prevState, address2: tenant.address2 }));
            setState((prevState) => ({ ...prevState, city: tenant.city }));
            setState((prevState) => ({ ...prevState, stateAbrv: tenant.state }));
            setState((prevState) => ({ ...prevState, zip: tenant.zip }));
            setState((prevState) => ({ ...prevState, phone: tenant.phone }));
            setState((prevState) => ({ ...prevState, phone: tenant.phone }));
            setState((prevState) => ({ ...prevState, email: tenant.email }));
        });*/
    }

    React.useEffect(() => {
        updateTenantInfo();
    }, []);

    React.useEffect(() => {
        updateTenantInfo();
        //SQW_Log(DebugLevel.LOG, "Footer: hubState", hubState);
        //SQW_Log(DebugLevel.LOG, { state }, "state");
    }, [hubState]);

    /*React.useEffect(() => {
        updateTenantInfo();
        //SQW_Log(DebugLevel.LOG, "Footer: hubState", hubState);
        //SQW_Log(DebugLevel.LOG, { state }, "state");
    }, [authContext.getTenantInfo()]);*/

    const getYear = () => {
        return new Date().getFullYear();
    };

    return (
        <AppBar
            position={cbHeight < getMainAppSize() ? "fixed" : "static"}
            sx={{ top: "auto", bottom: 0, marginTop: "20px" }}
        >
            <Toolbar
                disableGutters
                sx={{
                    width: "100vw",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "0",
                    marginBottom: "0",
                }}
            >
                <Box sx={{ width: "100vw", height: "auto" }}>
                    <Grid
                        width='100vw'
                        height={`${footerSize}px`}
                        backgroundColor='hsla(210, 25%, 16%, 1)'
                        display='flex'
                        alignItems='center'
                        justifyContent='space-between'
                        padding='0 20px'
                        color='#ffffff'
                    >
                        <Card backgroundColor='transparent'>
                            <Text color='#ffffff'>{companyName}</Text>
                            <Text color='#ffffff'>
                                {address1 && address1?.trim() !== "" && <>{address1}</>}
                            </Text>
                            <Text color='#ffffff'>
                                {address2 && address2?.trim() !== "" && <>{address2}</>}
                            </Text>
                            <Text color='#ffffff'>
                                {city} {stateAbrv} {zip}
                            </Text>
                        </Card>
                        {!isMobile && (
                            <Card backgroundColor='transparent'>
                                <Link
                                    href='https://www.prismvs.com/'
                                    target='_blank'
                                    rel='noopener noreferrrer'
                                    color='#ffffff'
                                >
                                    Prism Visual Software, Inc {getYear()}. All Rights Reserved.
                                    Powered by ServQuest © v{packageJson.version}
                                </Link>
                            </Card>
                        )}
                        <Card
                            backgroundColor='transparent'
                            color='#ffffff'
                        >
                            <Text color='#ffffff'>{phone !== "" && <>{phone}</>}</Text>
                            <Text color='#ffffff'>
                                {email !== "" && (
                                    <Link
                                        href={"mailto:" + email}
                                        target='_blank'
                                        rel='noopener noreferrrer'
                                        color='#ffffff'
                                    >
                                        {email}
                                    </Link>
                                )}
                            </Text>
                        </Card>
                    </Grid>
                    {isMobile && (
                        <Grid
                            width='100%'
                            backgroundColor='hsla(210, 25%, 16%, 1)'
                            display='flex'
                            alignItems='center'
                            textAlign='center'
                            justifyContent='center'
                            color='#ffffff'
                        >
                            <Card backgroundColor='transparent'>
                                <Link
                                    href='https://www.prismvs.com/'
                                    target='_blank'
                                    rel='noopener noreferrrer'
                                    color='#ffffff'
                                >
                                    Prism Visual Software, Inc {getYear()}. All Rights Reserved.
                                    Powered by ServQuest © v{packageJson.version}
                                </Link>
                            </Card>
                        </Grid>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

const areEqual = (prevProps: { cbHeight: any }, nextProps: { cbHeight: any }) => {
    // Custom comparison logic
    return prevProps.cbHeight === nextProps.cbHeight;
};

export default Footer;
//export default React.memo(Footer, areEqual);
