// React
import React, { ReactElement, ReactNode } from "react";
import { SQW_Log, DebugLevel } from "services/Logging";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

// Components
import {
    AuthObject,
    Category,
    ScheduleAddress,
    WebInventoryItem,
} from "interfaces/InvoiceInterfaces";
import TenantsCreateForm from "ui-components/TenantsCreateForm";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { Globals } from "helpers/Globals";
import {
    GetSettings,
    loadStoredItems,
    PersistantObjectType,
    saveStoredItems,
    storeItem,
    getItem,
    CreatePersistantObject,
} from "services/PersistantObjects";
import CognitoAuthProvider from "helpers/CognitoAuthRoute";
import { DataContextType, useDataState } from "context/DataContext";

import AdminDashboard from "./admin/AdminDashboard";
import Checkout from "./components/Checkout";
import ModifyCreditCards from "./components/ModifyCreditCards";
// Helpers
import NavigationProvider from "./helpers/NavigationProvider";
// Pages
import Cart from "./pages/Cart";
import DashboardPage from "./pages/DashboardPage";
import FAQs from "./pages/FAQs";
import MainStore from "./pages/MainStore";
import MyAdmin from "./pages/MyAdmin";
import PayInvoices from "./pages/PayInvoices";
import Payments from "./pages/Payments";
import Reports from "./pages/Reports";
import ServiceRequests from "./pages/ServiceRequests";
//import Test from "./pages/Test";
import ViewOpenInvoices from "./pages/ViewOpenInvoices";
import MyProfile from "./pages/MyProfile";
// Services
import { GetTenant, Settings } from "./services/GetInvoiceData";

// UI Components

// Layouts
import MainLayout from "./layouts/MainLayout";
import AccountMenu from "./layouts/AccountLayout";
import { HubProvider } from "./context/HubContext";

interface Props {
    signout?: any;
    user?: any;
}

type pages = {
    key: string;
    value: ReactElement;
    default: boolean;
};

export let globalDataContext: DataContextType;

const App = () => {
    const [selectedCategory, setSelectedCategory] = React.useState(["ALL"]);
    const [selectedFilter, setSelectedFilter] = React.useState("0");
    const [categories, setCategories] = React.useState([] as Category[]);
    const [searchCategory, setSearchCategory] = React.useState([""]);
    const [category, setCategory] = React.useState("ALL");
    const [filter, setFilter] = React.useState("0");
    const [showCartButton, setShowCartButton] = React.useState(false);
    const [numberOfCartItems, setNumberOfCartItems] = React.useState(0);
    const [openCart, setOpenCart] = React.useState(false);
    const [tenant, setTenant] = React.useState({} as any);
    const [cartItems, setCartItems] = React.useState([] as WebInventoryItem[]);
    const [currentAddress, setCurrentAddress] = React.useState({} as ScheduleAddress);
    const [refreshCartItems, setRefreshCartItems] = React.useState(false);
    const [currentView, setCurrentView] = React.useState("makePayments");
    const [dashboardPage, setDashboardPage] = React.useState({} as ReactNode);
    const [mainStorePage, setMainStorePage] = React.useState({} as ReactNode);
    const [payInvoicesPage, setPayInvoicesPage] = React.useState({} as ReactNode);
    const [permissionPages, setPermissionPages] = React.useState([] as pages[]);
    const [defaultPage, setDefaultPage] = React.useState("mainstore");
    const [settings, setSettings] = React.useState([] as Settings[]);
    const [loadPastCartItems, setLoadPastCartItems] = React.useState(true);
    const [props, setProps] = React.useState({} as propInterface);

    globalDataContext = useDataState();
    const { signedInUser } = useDataState();

    interface propInterface {
        signout: () => void;
        user: string;
    }

    const addItemToCart = (clickedItem: WebInventoryItem, updateQty: boolean = false) => {
        setCartItems(addItemToCartHelper(clickedItem, updateQty));
    };

    const addItemToCartHelper = (clickedItem: WebInventoryItem, updateQty: boolean = false) => {
        const prev = cartItems;
        const isItemInCart = prev.find((item) => item.citemno === clickedItem.citemno);

        if (clickedItem.nqty === 0) clickedItem.nqty = 1;

        if (isItemInCart) {
            return prev.map((item) =>
                item.citemno === clickedItem.citemno ?
                    {
                        ...item,
                        nqty:
                            updateQty ? clickedItem.nqty
                            : item.nqty + clickedItem.nqty > 999 ? 999
                            : item.nqty + clickedItem.nqty,
                    }
                :   item,
            );
        }
        return [...prev, { ...clickedItem }];
    };

    const removeFromCart = (citemno: string, nqty?: number, bAll?: boolean) => {
        setCartItems(removeFromCartHelper(citemno, nqty, bAll));
    };

    React.useEffect(() => {
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "App: Loading stored items.");
        //loadStoredItems();
        CreatePersistantObject({} as AuthObject);
    }, []);

    React.useEffect(() => {
        /*const key = "sqw_" + signedInUser + "_" + GetTenantName() + "_Cart";
        SQW_Log(DebugLevel.LOG, "App: Restoring Cart.", key);
        const pastCart = localStorage.getItem(key);*/
        const pastCart = getItem(PersistantObjectType.CART_ITEMS);
        if (pastCart) {
            SQW_Log(DebugLevel.LOG, "App: Restoring Past Cart.  ", pastCart);
            setCartItems(pastCart);
            setLoadPastCartItems(false);
        }
    }, [refreshCartItems]);

    const getTotalItems = () => {
        SQW_Log(DebugLevel.LOG, "5533:  getTotalItems: ", cartItems);
        if (!cartItems || cartItems.length === 0) return 0;
        SQW_Log(DebugLevel.LOG, "5533:  getTotalItems: ", cartItems);
        return cartItems.reduce((acc, item) => acc + item.nqty, 0);
    };

    React.useEffect(() => {
        setNumberOfCartItems(getTotalItems());
        //const key = "sqw_" + signedInUser + "_" + GetTenantName() + "_Cart";
        //SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "App: Saving Cart.", key);
        if (!loadPastCartItems) {
            storeItem(PersistantObjectType.CART_ITEMS, cartItems); //localStorage.setItem(key, JSON.stringify(cartItems));
            saveStoredItems();
        }
    }, [cartItems]);

    const removeFromCartHelper = (
        citemno: string,
        nqty?: number,
        bAll?: boolean,
    ): WebInventoryItem[] => {
        //SQW_Log(DebugLevel.LOG, "removeFromCart: ", citemno);
        let returnCart = cartItems;
        //let returnCart = prev;

        if (nqty === undefined) nqty = 1;
        if (bAll === undefined) bAll = false;

        returnCart.reduce((acc, item) => {
            if (item.citemno === citemno) {
                if (bAll || item.nqty === nqty) {
                    returnCart = acc;
                    return returnCart;
                }
                //SQW_Log(DebugLevel.LOG, "removeFromCart item.amount: ", item.nqty);
                returnCart = [...acc, { ...item, nqty: item.nqty - (nqty || 1) }];
                return returnCart;
            } else {
                returnCart = [...acc, item];
                return returnCart;
            }
        }, [] as WebInventoryItem[]);

        return returnCart;
    };

    React.useEffect(() => {
        async function fetchData() {
            const allSettings = await GetSettings();
            SQW_Log(DebugLevel.LOG, "353: allSettings: ", allSettings);
            if (allSettings) {
                setSettings(allSettings);
            }

            const tenantObj = await GetTenant();

            if (tenantObj) {
                setTenant(tenantObj);
            }
            datadogRum.setUserProperty("name", signedInUser);
        }

        //if (!isLoading) {
        SQW_Log(DebugLevel.LOG, "Getting configuration data....");
        //getSecrets2();

        fetchData();
        const props: propInterface = {
            signout: () => {
                SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Is anything still using this?");
            },
            user: signedInUser,
        };
        setProps(props);
        //}
    }, []);

    React.useEffect(() => {
        async function getPages() {
            if (settings && settings.length > 0) {
                const defPage = await getSettingByName("defaultpage");
                if (!defPage) return;
                SQW_Log(DebugLevel.LOG, "353: Default Page: ", defPage);
                SQW_Log(DebugLevel.LOG, "353: Settings: ", settings);
                setDefaultPage(defPage);
            } else {
                //SQW_Log(DebugLevel.LOG, "353: No settings found");
            }
        }
        getPages();
    }, [settings]);

    /* async function getDefaultPage(page: string) {
        const defp = await getSettingByName("defaultpage");

        if (defp.trim().toLowerCase() === page.trim().toLowerCase()) {
            return <div>Could not find page {page}.</div>;
        }

        return getPageMainLayout(defp);
    }*/
    async function getSettingByName(name: string) {
        let value = "";

        if (!settings || settings.length === 0) return value;

        try {
            value =
                settings.find((x: any) => x.name.trim().toLowerCase() === name.trim().toLowerCase())
                    ?.value || "";
        } catch (err) {
            //SQW_Log(DebugLevel.LOG, "Error getting setting by name " + name + ".", err); /* Error Logging before Datadog. */

            datadogLogs.logger.error("Error getting setting by name " + name + ".", {
                error: err as any /* Typescript issue. Any is needed. Errors are captured as 'undefined' but TS requires you to specify the datatype. */,
            }); /* Error Logging after Datadog. */
        }
        return value.trim().toLowerCase();
    }

    /*    const pagesToCheckPermissions = ["viewopeninvoices", "dashboardpage", "mainstore"];

    async function getPermissionPages() {
        if (!settingsState || settingsState.length === 0) return;

        const defp = await getSettingByName("defaultpage");

        var p = [] as pages[];

        try {
            for (let i = 0; i < pagesToCheckPermissions.length; i++) {
                const x = pagesToCheckPermissions[i];
                const value = await getPageMainLayout(x);
                p.push({ key: x, value: value, default: defp === x });
            }
            setPermissionPages(p);
        } catch (err) {
            SQW_Log(DebugLevel.LOG, "Error getting permission pages.", err);
        }
    }

    function getPage(page: string) {
        const retPage = permissionPages.find((x) => x.key === page);
        if (!retPage) {
            return <div>Could not find page {page}.</div>;
        }
    }

    async function getPageMainLayout(page: string) {
        const defp = page ? page.trim().toLowerCase() : "";
        var retPage = await getDefaultPage(page);

        var setting = await getSettingByName("show" + defp);
        //SQW_Log(DebugLevel.LOG, "5settings", setting);
        if (setting !== "1") {
            return (
                <MainLayout>
                    <ErrorDisplay
                        message='Page does not exist or you do not have permission to view this page.'
                        onGoBack={() => {
                            window.location.href = "/";
                        }}
                    ></ErrorDisplay>
                </MainLayout>
            );
        } else {
            if (defp === "viewopeninvoices") retPage = <ViewOpenInvoices />;
            else if (defp === "dashboardpage") retPage = <DashboardPage />;
            else if (defp === "mainstore")
                retPage = (
                    <MainStore
                        selectedCategory={selectedCategory}
                        selectedFilter={selectedFilter}
                        categories={categories}
                        setSelectedCategory={setSelectedCategory}
                        setSelectedFilter={setSelectedFilter}
                        setCategories={setCategories}
                        searchCategory={searchCategory}
                        setSearchCategory={setSearchCategory}
                        setShowCartButton={setShowCartButton}
                        setNumberOfCartItems={setNumberOfCartItems}
                        setOpenCart={setOpenCart}
                        openCart={openCart}
                        addItemToCart={addItemToCart}
                        cartItems={cartItems || []}
                        setCartItems={setCartItems}
                        removeFromCart={removeFromCart}
                        currentAddress={currentAddress}
                        setCurrentAddress={setCurrentAddress}
                        refreshCartItems={refreshCartItems}
                    />
                );
        }
        return (
            <MainLayout
                signOut={props.signout}
                showStoreBar={1 === 1}
                selectedCategory={selectedCategory}
                selectedFilter={selectedFilter}
                categories={categories}
                setSelectedCategory={setSelectedCategory}
                setSelectedFilter={setSelectedFilter}
                setCategories={setCategories}
                searchCategory={searchCategory}
                setSearchCategory={setSearchCategory}
                showCartButton={showCartButton}
                numberOfCartItems={numberOfCartItems}
                setOpenCart={setOpenCart}
                setNumberOfCartItems={setNumberOfCartItems}
                setShowCartButton={setShowCartButton}
                openCart={openCart}
            >
                {retPage}
            </MainLayout>
        );
    }*/

    return (
        <>
            <CognitoAuthProvider>
                <HubProvider>
                    {/*<CustomBox>*/}
                    <BrowserRouter>
                        <NavigationProvider>
                            <Globals />
                            <Routes>
                                {/*<Route
                                path='/login'
                                element={<LoginForm />}
                            />*/}
                                <Route
                                    path='/dashboardpage'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            {(
                                                settings &&
                                                (settings.find(
                                                    (x) =>
                                                        x.name.trim().toLowerCase() ===
                                                        "showdashboardpage".trim().toLowerCase(),
                                                )?.value || "0") === "1"
                                            ) ?
                                                <DashboardPage />
                                            :   <div></div>}
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/viewinvoices'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            {(
                                                settings &&
                                                (settings.find(
                                                    (x) =>
                                                        x.name.trim().toLowerCase() ===
                                                        "showviewopeninvoices".trim().toLowerCase(),
                                                )?.value || "0") === "1"
                                            ) ?
                                                <ViewOpenInvoices />
                                            :   <div></div>}
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/payinvoices'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            <PayInvoices />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/reports'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            <Reports />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/servicerequests'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            {(
                                                settings &&
                                                (settings.find(
                                                    (x) =>
                                                        x.name.trim().toLowerCase() ===
                                                        "showservicerequests",
                                                )?.value || "0") === "1"
                                            ) ?
                                                <ServiceRequests
                                                    address={currentAddress}
                                                    setAddress={setCurrentAddress}
                                                />
                                            :   <div></div>}
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/products'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            showStoreBar={1 === 1}
                                            selectedCategory={selectedCategory}
                                            selectedFilter={selectedFilter}
                                            categories={categories}
                                            setSelectedCategory={setSelectedCategory}
                                            setSelectedFilter={setSelectedFilter}
                                            setCategories={setCategories}
                                            searchCategory={searchCategory}
                                            setSearchCategory={setSearchCategory}
                                            showCartButton={showCartButton}
                                            numberOfCartItems={numberOfCartItems}
                                            setOpenCart={setOpenCart}
                                            setNumberOfCartItems={setNumberOfCartItems}
                                            setShowCartButton={setShowCartButton}
                                            openCart={openCart}
                                        >
                                            {(
                                                settings &&
                                                (settings.find(
                                                    (x) =>
                                                        x.name.trim().toLowerCase() ===
                                                        "showmainstore".trim().toLowerCase(),
                                                )?.value || "0") === "1"
                                            ) ?
                                                <MainStore
                                                    selectedCategory={selectedCategory}
                                                    selectedFilter={selectedFilter}
                                                    categories={categories}
                                                    setSelectedCategory={setSelectedCategory}
                                                    setSelectedFilter={setSelectedFilter}
                                                    setCategories={setCategories}
                                                    searchCategory={searchCategory}
                                                    setSearchCategory={setSearchCategory}
                                                    setShowCartButton={setShowCartButton}
                                                    setNumberOfCartItems={setNumberOfCartItems}
                                                    setOpenCart={setOpenCart}
                                                    openCart={openCart}
                                                    addItemToCart={addItemToCart}
                                                    cartItems={cartItems || []}
                                                    setCartItems={setCartItems}
                                                    removeFromCart={removeFromCart}
                                                    currentAddress={currentAddress}
                                                    setCurrentAddress={setCurrentAddress}
                                                    refreshCartItems={refreshCartItems}
                                                />
                                            :   <div></div>}
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/cart/checkout'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            showStoreBar={1 === 1}
                                            selectedCategory={selectedCategory}
                                            selectedFilter={selectedFilter}
                                            categories={categories}
                                            setSelectedCategory={setSelectedCategory}
                                            setSelectedFilter={setSelectedFilter}
                                            setCategories={setCategories}
                                            searchCategory={searchCategory}
                                            setSearchCategory={setSearchCategory}
                                            showCartButton={showCartButton}
                                            numberOfCartItems={numberOfCartItems}
                                            setOpenCart={setOpenCart}
                                            setNumberOfCartItems={setNumberOfCartItems}
                                            setShowCartButton={setShowCartButton}
                                            openCart={openCart}
                                        >
                                            <Checkout
                                                address={currentAddress}
                                                cartItems={cartItems || []}
                                                setCartItems={setCartItems}
                                            />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/cart'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            <Cart
                                                setOpenCart={setOpenCart}
                                                address={currentAddress}
                                                addToCart={addItemToCart}
                                                cartItems={cartItems || []}
                                                removeFromCart={removeFromCart}
                                                checkout={() => {}}
                                                setAddress={setCurrentAddress}
                                                refreshCartItems={refreshCartItems}
                                                setRefreshCartItems={setRefreshCartItems}
                                            />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/admin'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            <AdminDashboard />
                                        </MainLayout>
                                    }
                                />{" "}
                                <Route
                                    path='/addtenant'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            <TenantsCreateForm />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/faqs'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            <FAQs />
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/myaccount'
                                    element={
                                        <AccountMenu
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                            activeButton='/myprofile'
                                        >
                                            <MyProfile />
                                        </AccountMenu>
                                    }
                                />
                                <Route
                                    path='/paymethods'
                                    element={
                                        <AccountMenu
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                            activeButton='/paymethods'
                                        >
                                            <ModifyCreditCards ccustno='NEEDTOSELECTCUSTOMER' />
                                        </AccountMenu>
                                    }
                                />
                                <Route
                                    path='/payments'
                                    element={
                                        <MainLayout
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            {(
                                                settings &&
                                                (settings.find(
                                                    (x) =>
                                                        x.name.trim().toLowerCase() ===
                                                        "showviewopeninvoices".trim().toLowerCase(),
                                                )?.value || "0") === "1"
                                            ) ?
                                                <Payments />
                                            :   <div></div>}
                                        </MainLayout>
                                    }
                                />
                                <Route
                                    path='/myadmin'
                                    element={
                                        <AccountMenu
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            <MyAdmin />
                                        </AccountMenu>
                                    }
                                />
                                <Route
                                    path='/myprofile'
                                    element={
                                        <AccountMenu
                                            signOut={props.signout}
                                            numberOfCartItems={numberOfCartItems}
                                        >
                                            <MyProfile />
                                        </AccountMenu>
                                    }
                                />
                                <Route
                                    path='/'
                                    element={
                                        defaultPage === "mainstore" ? <Navigate to='/products' />
                                        : defaultPage === "dashboardpage" ?
                                            <Navigate to='/dashboardpage' />
                                        : defaultPage === "viewopeninvoices" ?
                                            <Navigate to='/payments' />
                                        :   null
                                        /*<MainLayout
                                            signOut={props.signout}
                                            showStoreBar={1 === 1}
                                            selectedCategory={selectedCategory}
                                            selectedFilter={selectedFilter}
                                            categories={categories}
                                            setSelectedCategory={setSelectedCategory}
                                            setSelectedFilter={setSelectedFilter}
                                            setCategories={setCategories}
                                            searchCategory={searchCategory}
                                            setSearchCategory={setSearchCategory}
                                            showCartButton={showCartButton}
                                            numberOfCartItems={numberOfCartItems}
                                            setOpenCart={setOpenCart}
                                            setNumberOfCartItems={setNumberOfCartItems}
                                            setShowCartButton={setShowCartButton}
                                            openCart={openCart}
                                        >
                                            {defaultPage === "mainstore" ?
                                                <MainStore
                                                    selectedCategory={selectedCategory}
                                                    selectedFilter={selectedFilter}
                                                    categories={categories}
                                                    setSelectedCategory={setSelectedCategory}
                                                    setSelectedFilter={setSelectedFilter}
                                                    setCategories={setCategories}
                                                    searchCategory={searchCategory}
                                                    setSearchCategory={setSearchCategory}
                                                    setShowCartButton={setShowCartButton}
                                                    setNumberOfCartItems={setNumberOfCartItems}
                                                    setOpenCart={setOpenCart}
                                                    openCart={openCart}
                                                    addItemToCart={addItemToCart}
                                                    cartItems={cartItems || []}
                                                    setCartItems={setCartItems}
                                                    removeFromCart={removeFromCart}
                                                    currentAddress={currentAddress}
                                                    setCurrentAddress={setCurrentAddress}
                                                    refreshCartItems={refreshCartItems}
                                                />
                                            : defaultPage === "dashboardpage" ?
                                                <DashboardPage />
                                            : defaultPage === "viewopeninvoices" ?
                                                <Payments />
                                            :   null}
                                        </MainLayout>*/
                                    }
                                />
                                {/*<Route
                                    path='/test'
                                    element={
                                            <MainLayout
                                                signOut={props.signout}
                                                numberOfCartItems={numberOfCartItems}
                                            >
                                                <Test />
                                            </MainLayout>
                                    }
                                />*/}
                            </Routes>
                        </NavigationProvider>
                    </BrowserRouter>
                    {/*</CustomBox>*/}
                </HubProvider>
            </CognitoAuthProvider>
        </>
    );
};

export default App;
