import { FieldGroupIconButton, Flex, Label, SelectField, TextField } from "@aws-amplify/ui-react";
import { SQW_Log, DebugLevel } from "services/Logging";
import { Button, Dialog, Pagination, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Hidden from "@mui/material/Hidden";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled as muistyled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { MdClose, MdSearch } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";

import {
    API_AddItemsToSchedule,
    API_Authenticate,
    API_GetNextDeliveryDate,
    OrderItems,
    API_GetWebItem,
    GetTenantName,
} from "../services/GetInvoiceData";

import {
    storeItem,
    saveStoredItems,
    CreatePersistantObject,
    GetPersistantObject,
    loadStoredItems,
    PersistantObjectType,
} from "services/PersistantObjects";

import "../styles.css";
import { useIsMobile, useIsLessThanFullWidth } from "helpers/CustomHooks";
import theme from "theme/theme";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
    AuthObject,
    CartItemType,
    Category,
    CheckoutCartItems,
    Customer,
    ScheduleAddress,
    WebInventoryItem,
} from "interfaces/InvoiceInterfaces";
import { useDataState } from "context/DataContext";

import LoadingSpinner from "./LoadingSpinner";
import ProductCard2 from "./Item/ProductCard";
import Item from "./Item/Item";
import { CatchingPokemonSharp } from "@mui/icons-material";

export const SearchWrapper = styled.div`
    width: 100%;
    align-items: center;
    max-width: 800px;
`;

export const Wrapper = muistyled("div")(() => ({
    padding: "0",
    maxWidth: "100vw",
    xl: {
        maxWidth: "1190px",
    },
}));

function Arrow(props: any) {
    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " arrow";
    const char = props.type === "next" ? ">" : "<";
    return (
        <span
            className={className}
            onClick={props.onClick}
        >
            {char}
        </span>
    );
}

// const getScheduleAddress = async (username: string) => {
//     const token = await API_Authenticate();
//     const addrs = await API_GetScheduleAddresses(token, username, "D");
//     if (!addrs) {
//         SQW_Log(DebugLevel.LOG, "Error getting schedule addresses. (null)");
//         return [];
//     }
//     if (!addrs.IsSuccessful) {
//         return [];
//     }
//     return addrs.Addresses;
// };

type Props = {
    selectedCategory: string[];
    selectedFilter: string;
    categories: Category[];
    setCategories: React.Dispatch<React.SetStateAction<Category[]>>;
    setNavBarSearchCategory: (searchCategory: string[]) => void;
    cartOpen: boolean;
    setCartOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setTotalItems: React.Dispatch<React.SetStateAction<number>>;
    setSelectedFilter: React.Dispatch<React.SetStateAction<string>>;
    setSelectedCategory: React.Dispatch<React.SetStateAction<string[]>>;
    onFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onCategoryChange: (event: React.SyntheticEvent, nodeId: string[]) => void;
    setSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    applyFilters: boolean;
    setCartItems: React.Dispatch<React.SetStateAction<WebInventoryItem[]>>;
    cartItems: WebInventoryItem[];
    addItemToCart: (clickedItem: WebInventoryItem, updateQty: boolean) => void;
    removeFromCart: (citemno: string, nqty?: number, bAll?: boolean) => void;
    runCheckout?: boolean;
    currentAddress?: ScheduleAddress;
    setCurrentAddress?: React.Dispatch<React.SetStateAction<ScheduleAddress>>;
    setShowItem: React.Dispatch<React.SetStateAction<boolean>>;
    showItem: boolean;
    refreshCartItems: boolean;
};

type State = {
    products: CartItemType[];
    cartOpen: boolean;
    cartItems: CartItemType[];
    numberOfItems: number;
    currentPage: number;
    ItemsPerPage: number;
    pageNumbers: number[];
    numberOfPages: number;
};

const DefaultCustomer: Customer = {
    ccustno: "",
    cscono: "",
    cschedid: "",
};

const testCustomer: Customer = {
    ccustno: "",
    cscono: "",
    cschedid: "",
};

//const ProductCard: React.FC<Props> = ({ item, handleAddToCart }) => (

const StoreItems: React.FC<Props> = ({
    selectedCategory,
    selectedFilter,
    categories,
    setCategories,
    setNavBarSearchCategory,
    cartOpen,
    setCartOpen,
    setTotalItems,
    setSelectedFilter,
    setSelectedCategory,
    onFilterChange,
    onCategoryChange,
    setSideBarOpen,
    applyFilters,
    setCartItems,
    cartItems,
    addItemToCart,
    removeFromCart,
    runCheckout,
    currentAddress,
    setCurrentAddress,
    setShowItem,
    showItem,
    refreshCartItems,
}) => {
    //const [cartOpen, setCartOpen] = useState(false);
    // const [products, setProducts] = useState([] as WebInventoryItem[]);
    const [currentPage, setCurrentPage] = useState(1);
    const [ItemsPerPage, setItemsPerPage] = useState(9);
    //const [pageNumbers, setPageNumbers] = useState([] as number[]);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [searchInput, setSearchInput] = useState("");
    const [searchString, setSearchString] = useState("");
    const [inSearch, setInSearch] = useState(false);
    //const [searchCategory, setSearchCategory] = useState(["ALL"]);
    const [checkout, setCheckout] = useState(false);
    //const [searchFilter, setSearchFilter] = useState("0");
    const [apiProducts, setApiProducts] = useState([] as WebInventoryItem[]);
    const [customer, setCustomer] = useState(DefaultCustomer);
    const [itemToDisplay, setItemToDisplay] = useState({} as WebInventoryItem);
    const [addresses, setAddresses] = React.useState([] as ScheduleAddress[]);
    const [selectedAddress, setSelectedAddress] = React.useState("");
    const [addressLabel, setAddressLabel] = React.useState(
        "Please select Schedule/Shipping address...",
    );
    const [startCheckout, setStartCheckout] = React.useState(runCheckout ? runCheckout : false);
    const [checkoutItems, setCheckoutItems] = React.useState([] as CheckoutCartItems[]);
    //const [checkoutComplete, setCheckoutComplete] = React.useState(false);
    //const [createdTicket, setCreatedTicket] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    //const [hasCustomersLoaded, setHasCustomersLoaded] = React.useState(false);
    const [hasLoaded, setHasLoaded] = React.useState(false);
    const [nextDeliveryDate, setNextDeliveryDate] = React.useState("");
    const [showNextDeliveryDate, setShowNextDeliveryDate] = React.useState(false);
    const [sort, setSort] = React.useState("A-Z");
    //const [showFilters, setShowFilters] = React.useState(false);
    const [specialImages, setSpecialImages] = React.useState([] as any);

    const isMobile = useIsMobile();
    const isLessThanFullWidth = useIsLessThanFullWidth();

    const { loadingDeliveryAddresses, loadingProducts, signedInUser } = useDataState();

    const getAddressFromCID = (cid: string) => {
        //SQW_Log(DebugLevel.LOG, "getAddressFromCID: ", cid);
        const address = addresses.find((address) => address.cid === cid);
        if (address) {
            //SQW_Log(DebugLevel.LOG, "getAddressFromCID: Found: ", address);
            return address;
        }
        //SQW_Log(DebugLevel.LOG, "getAddressFromCID: Address Not Found");
        return {} as ScheduleAddress;
    };

    const handleScheduleAddressChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let cust: Customer = DefaultCustomer;
        const addr = event.target.value;

        //SQW_Log(DebugLevel.LOG, "handleScheduleAddressChange: ", event.target.value);

        if (addr && addr !== "") cust = getCustomerFromAddress(event.target.value);

        if (cust && cust.ccustno && cust.ccustno !== "") {
            setSelectedAddress(addr);
            setCustomer(cust);
        } else {
            SQW_Log(DebugLevel.LOG, "Customer Not Found: ", addr);
            setSelectedAddress("");
            setCustomer(DefaultCustomer);
        }
        //SQW_Log(DebugLevel.LOG, "MainStore handleScheduleAddressChange", addr);
    };

    const getCustomerFromAddress = (addr: string) => {
        const cust = addr.split("|");

        return { ccustno: cust[0], cscono: cust[1], cschedid: cust[2] };
    };

    const getNumberOfPages = async (itemsPerPage: number) => {
        const numOfPages = Math.ceil(
            (await getAPIProducts("getNumberOfPages", getAddressFromCID(selectedAddress))).length /
                itemsPerPage,
        );
        await setNumberOfPages(numOfPages);

        //SQW_Log(DebugLevel.LOG, "getNumberOfPages numberOfPages: ", numOfPages);
    };

    const handleItemsPerPageChange = (event: SelectChangeEvent) => {
        //SQW_Log(DebugLevel.LOG, "Items Per Page Changed: ", event.target.value);
        setCurrentPage(1);
        setItemsPerPage(Number(event.target.value));
        getNumberOfPages(Number(event.target.value));
    };

    // const handleClickPagination = (event: React.MouseEvent<HTMLLIElement>) => {
    //     //SQW_Log(DebugLevel.LOG, "Page click: ", event.currentTarget.id);
    //     setCurrentPage(Number(event.currentTarget.id));
    //     //setDisplayPageNumbersFromPageNumbers(Number(event.currentTarget.id));
    //     document.body.scrollTop = document.documentElement.scrollTop = 0;
    // };

    const getTotalItems = (items: WebInventoryItem[]) =>
        items.reduce((acc, item) => acc + item.nqty, 0);

    const runCheckoutProcess = async () => {
        try {
            //SQW_Log(DebugLevel.LOG, "runCheckoutProcess: num of cart items ", cartItems.length);
            //SQW_Log(DebugLevel.LOG, "runCheckoutProcess: customer: ", JSON.stringify(customer));

            const ordItems = cartItems.map(
                (item) => ({ citemno: item.citemno, nqty: item.nqty }) as OrderItems,
            );

            //SQW_Log(DebugLevel.LOG, "runCheckoutProcess:  ordItems: ", ordItems);

            const token = await API_Authenticate();

            const ci = await API_AddItemsToSchedule(
                token,
                customer.ccustno,
                customer.cscono,
                customer.cschedid,
                ordItems,
            );
            if (ci) {
                //SQW_Log(DebugLevel.LOG, "checkoutItems: ", JSON.stringify(checkoutItems));
                //SQW_Log(DebugLevel.LOG, "checkoutItems: ci: ", ci);
                //SQW_Log(DebugLevel.LOG, "checkoutItems: items: ", ci.items);
                cartItems.forEach((item) => {
                    const checkoutItem = ci.items.find(
                        (it: CheckoutCartItems) => it.citemno === item.citemno,
                    );
                    if (checkoutItem) {
                        checkoutItem.image =
                            item.oImage && item.oImage.cimgurl ? item.oImage.cimgurl : "";
                    }
                });
                //SQW_Log(DebugLevel.LOG, "checkoutItems: items after adding image: ", ci.items);
                setCheckoutItems(ci.items);
            } else {
                //SQW_Log(DebugLevel.LOG, "checkoutItems null");
            }
            /*const paymentMethodData = await API_GetPaymentMethods("customerNumber", token);
      if (paymentMethodData) {
        setPaymentMethods(paymentMethodData);
      }*/
            setCartOpen(false);
            setCheckout(true);
        } catch (error) {
            SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Failed checkout process: ", error);
        } finally {
            //SQW_Log(DebugLevel.LOG, "Finished runCheckoutProcess");
            setStartCheckout(false);
        }
    };

    // const onCheckoutComplete = (csono: string) => {
    //     setStartCheckout(false);
    //     setCheckout(false);
    //     setCartOpen(false);
    //     setCreatedTicket(csono);
    //     setCheckoutComplete(true);
    //     setCartItems([] as WebInventoryItem[]);
    // };

    // const goBackToStore = () => {
    //     setStartCheckout(false);
    //     setCheckout(false);
    //     setCartOpen(false);
    //     setCreatedTicket("");
    //     setCheckoutComplete(false);
    //     setCartItems([] as WebInventoryItem[]);
    // };

    // const handleCheckout = () => {
    //     if (!selectedAddress || selectedAddress == "") {
    //         alert("You need to pick a Schedule/Address before you can checkout.");
    //         return;
    //     }
    //     setCustomer(getCustomerFromAddress(selectedAddress));

    //     setStartCheckout(true);
    // };

    // const handleCancelCheckout = () => {
    //     setCartOpen(false);
    //     setCheckout(false);
    // };

    function ScrollToTop() {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

        return null;
    }

    const handleItemDisplay = (clickedItem: WebInventoryItem) => {
        SQW_Log(DebugLevel.LOG, "handleItemDisplay: ", clickedItem);
        setItemToDisplay(clickedItem);
        setShowItem(true);
        ScrollToTop();
    };

    async function handleSpecialItemDisplay(item: any) {
        //SQW_Log(DebugLevel.LOG, "handleSpecialItemDisplay: ", item);
        if (item.ctitle.startsWith("http://") || item.ctitle.startsWith("https://")) {
            //SQW_Log(DebugLevel.LOG, "handleSpecialItemDisplay: link: ", item.ctitle);
            window.open(item.ctitle);
            return;
        }
        if (item.ctitle.startsWith("Category:")) {
            const categories = item.ctitle.replace("Category:", "").trim().split("||");
            //SQW_Log(DebugLevel.LOG, "handleSpecialItemDisplay: Categories: ", categories);
            setSelectedCategory(categories);
            return;
        }

        const token = await API_Authenticate();
        const items = await API_GetWebItem(token, signedInUser, item.ctitle);
        //SQW_Log(DebugLevel.LOG, "handleSpecialItemDisplay: items:", items);
        if (items?.Items && items.Items.length > 0) {
            //SQW_Log(DebugLevel.LOG, "handleSpecialItemDisplay: Showing item:", items.Items[0]);
            handleItemDisplay(items.Items[0]);
        }
    }

    const handleItemDisplayClose = () => {
        setShowItem(false);
    };

    const handleAddToCart = (clickedItem: WebInventoryItem) => {
        addItemToCart(clickedItem, false);
        //const newCartItems = addItemToCart(clickedItem, false);
        //SQW_Log(DebugLevel.LOG, "Updated Cart: ", JSON.stringify(newCartItems));
        //setCartItems(newCartItems);
    };

    const getUpdatedPrice = (citemno: string, origPrice: number) => {
        if (!apiProducts) {
            return origPrice;
        }
        const prod = apiProducts.find((x) => x.citemno === citemno);
        if (prod) {
            return prod.nprice;
        }

        return origPrice;
    };

    const getUpdatedPriceCartItems = (): WebInventoryItem[] => {
        let updatedCart = cartItems;

        updatedCart.reduce((acc, item) => {
            updatedCart = [...acc, { ...item, nprice: getUpdatedPrice(item.citemno, item.nprice) }];
            return updatedCart;
        }, [] as WebInventoryItem[]);

        return updatedCart;
    };

    // const handleStoreSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     e.preventDefault();
    //     setSearchInput(e.target.value);
    // };

    // const handleStoreCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    //     e.preventDefault();
    //     //setSearchCategory(e.target.value);
    // };
    const getChildCategories = (category: string[]) => {
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "getChildCategories: category", category);
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "getChildCategories: categories", categories);

        let selectCategories = [] as string[];

        if (!categories) {
            return [];
        }
        category.forEach((cat) => {
            if (cat === "ALL") {
                return [];
            }
            selectCategories = [...selectCategories, ...cat.split("|||")];
        });
        SQW_Log(
            DebugLevel.INFO_WITH_INFO_TYPE,
            "getChildCategories: selectCategories",
            selectCategories,
        );
        return (
            categories &&
            categories
                .filter(
                    (cat) =>
                        selectCategories.some((x) => x === cat.cparentno) ||
                        selectCategories.some((x) => x === cat.ccatno),
                )
                .map((cat) => cat.ccatno)
        );
    };

    const doesItemIncludeText = (item: WebInventoryItem, searchString: string) => {
        const search = searchString.toLowerCase();
        if (
            item.citemno.toLowerCase().includes(search) ||
            item.cdescshort.toLowerCase().includes(search) ||
            item.cdescslong.toLowerCase().includes(search) ||
            item.cdescdetails.toLowerCase().includes(search)
        ) {
            return true;
        }

        return false;
    };

    /*const getFilteredProducts = () => {
        const searchCategories: string[] = getChildCategories(searchCategory);

        if (inSearch) {
            return apiProducts.filter(
                (product) =>
                    (searchCategories.some((x) => x === "ALL") ||
                        searchCategories.includes(product.ccatno)) &&
                    (product.citemno.toLowerCase().includes(searchString.toLowerCase()) ||
                        product.cdescshort.toLowerCase().includes(searchString.toLowerCase())),
            );
        }
        if (searchCategories.some((x) => x === "ALL")) {
            return apiProducts.filter((product) => searchCategories.includes(product.ccatno));
        }

        return apiProducts;
    };*/

    const startSearch = async () => {
        if (searchInput === "") await setInSearch(false);
        else await setInSearch(true);

        await setSearchString(searchInput);
        //setNavBarSearchCategory(searchCategory);
        //getNumberOfPages(ItemsPerPage);
        setCurrentPage(1);
    };

    const cancelSearch = async () => {
        // setSearchInput("");
        await setInSearch(false);
        await setSearchInput("");
        await setSearchString("");
        //await setSearchCategory(["ALL"]);
    };

    useEffect(() => {
        SQW_Log(DebugLevel.LOG, "H22: StoreItems: useEffect: isLoading: ", isLoading);
    }, [isLoading]);

    useEffect(() => {
        if (startCheckout) runCheckoutProcess();
        //SQW_Log(DebugLevel.LOG, "selectedCategory: ", selectedCategory);
    }, [startCheckout]);

    const getAPIProducts = async (callingProc: string, customer: ScheduleAddress) => {
        SQW_Log(DebugLevel.LOG, "getAPIProducts: ", customer);
        await CreatePersistantObject({} as AuthObject);
        const po = GetPersistantObject();
        const localItems = po.products;
        return setFrontEndProducts(localItems);
    };

    function getInt(value: any) {
        if (value === null || value === undefined) return 0;
        if (typeof value === "number") return value;
        if (typeof value === "string") return parseInt(value);
        return 0;
    }

    function paginate(
        filteredItems: WebInventoryItem[],
        ItemsPerPage: number,
        currentPage: number,
    ) {
        const startIndex = (currentPage - 1) * ItemsPerPage;
        const endIndex = startIndex + ItemsPerPage;
        return filteredItems.slice(startIndex, endIndex);
    }

    const setFrontEndProducts = (items: WebInventoryItem[]) => {
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "setFrontEndProducts: items", items);
        const filteredItems = getFilteredItems(items);
        SQW_Log(
            DebugLevel.INFO_WITH_INFO_TYPE,
            "setFrontEndProducts: filteredItems",
            filteredItems,
        );
        if (filteredItems) {
            const totalItems = filteredItems.length;
            //setTotalItems(totalItems);
            const numOfPages = Math.ceil(totalItems / ItemsPerPage);
            setNumberOfPages(numOfPages);
            if (currentPage > numOfPages) setCurrentPage(1);
            const paginatedItems = paginate(
                filteredItems,
                ItemsPerPage,
                currentPage > numOfPages ? 1 : currentPage,
            );
            setApiProducts(paginatedItems);
            return paginatedItems;
        }
        return filteredItems;
    };

    /* async function getNextDeliveryDate(customer: Customer) {
        try {
            const token = await API_Authenticate();
            const ddate = await API_GetNextDeliveryDate(token, customer.ccustno, customer.cschedid);
            if (ddate?.DeliveryDate) {
                //SQW_Log(DebugLevel.LOG, "getNextDeliveryDate: ", ddate.DeliveryDate);
                var deliveryDate = new Date(ddate.DeliveryDate);
                if (deliveryDate) setNextDeliveryDate(getFormattedDate(deliveryDate));
            }
        } catch (err) {
            //SQW_Log(DebugLevel.LOG, "Error getting next delivery date: ", err);
        }
    }*/
    // const maxItemsToLoadPerApiCall = 500000;

    // const getAPIAllProducts = async (callingProc: string, customer: ScheduleAddress) => {
    //     let loadedItems = 0;
    //     try {
    //         if (!customer || !customer.ccustno || customer.ccustno === "") {
    //             SQW_Log(DebugLevel.LOG, "746: getAPIProducts customer is null or empty");
    //             return;
    //         }
    //         setIsLoading(true);

    //         const token = await API_Authenticate();
    //         const apiItems = await API_GetWebItems(
    //             token,
    //             signedInUser(),
    //             customer.ccustno,
    //             customer.cscono,
    //             customer.cinvno,
    //             ["ALL"], //selectedCategory || ["ALL"],
    //             selectedFilter,
    //             1,
    //             maxItemsToLoadPerApiCall,
    //             "",
    //             [], //cartItems.map((item: any) => item.citemno),
    //             sort,
    //         );
    //         SQW_Log(DebugLevel.LOG, "getAPIAllProducts: ", apiItems);
    //         return apiItems;
    //     } catch (error) {
    //         SQW_Log(DebugLevel.LOG, "Error loading items: ", error);
    //     } finally {
    //         setIsLoading(false);
    //     }
    //     //setCategories(getCategories());
    //     return [];
    // };

    // const getRemainingAPIProducts = async (
    //     callingProc: string,
    //     customer: ScheduleAddress,
    //     loadedItems: any,
    //     totalPages: number,
    //     key: string,
    // ) => {
    //     let pageNumber = 2;
    //     try {
    //         if (!customer || !customer.ccustno || customer.ccustno === "") {
    //             SQW_Log(DebugLevel.LOG, "746: getRemainingAPIProducts customer is null or empty");
    //             return;
    //         }

    //         //setIsLoading(true);
    //         while (pageNumber <= totalPages) {
    //             const token = await API_Authenticate();
    //             const apiItems = await API_GetWebItems(
    //                 token,
    //                 signedInUser(),
    //                 customer.ccustno,
    //                 customer.cscono,
    //                 customer.cinvno,
    //                 ["ALL"], //selectedCategory || ["ALL"],
    //                 selectedFilter,
    //                 pageNumber,
    //                 maxItemsToLoadPerApiCall,
    //                 "",
    //                 [], //cartItems.map((item: any) => item.citemno),
    //                 sort,
    //             );
    //             SQW_Log(DebugLevel.LOG, "getRemainingAPIProducts: ", apiItems);
    //             if (apiItems) {
    //                 loadedItems = [...loadedItems, ...apiItems.Items];
    //                 localStorage.setItem(key, JSON.stringify(loadedItems));
    //                 localStorage.setItem(key + "_LoadedItems", String(loadedItems.length));
    //             }
    //             pageNumber++;
    //         }
    //         const totalItems = localStorage.getItem(key + "_TotalItems");
    //         if (totalItems) {
    //             if (getInt(totalItems) > loadedItems.length) {
    //                 SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE,
    //                     "getRemainingAPIProducts: Total Items does not match loaded items.",
    //                 );
    //                 SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE,
    //                     "getRemainingAPIProducts: Total Items: ",
    //                     String(getInt(totalItems)),
    //                 );
    //                 SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE,
    //                     "getRemainingAPIProducts: Loaded Items: ",
    //                     String(loadedItems.length),
    //                 );
    //                 localStorage.setItem(key + "_LoadedItems", totalItems);
    //             }
    //         }

    //         return loadedItems;
    //     } catch (error) {
    //         SQW_Log(DebugLevel.LOG, "Error loading items: ", error);
    //     } finally {
    //         //setIsLoading(false);
    //     }
    //     return [];
    // };

    const getFilteredItems = (products: WebInventoryItem[]) => {
        const searchCategories: string[] = getChildCategories(selectedCategory);
        SQW_Log(DebugLevel.LOG, "getFilteredItems: searchCategories: ", searchCategories);
        if (!products || products.length === 0) return [];

        const addr = getAddressFromCID(selectedAddress);
        let addrID = "";
        if (addr?.ccustno && addr?.cinvno) {
            addrID = addr.ccustno.trim() + "|" + addr.cinvno.trim();
        }

        SQW_Log(DebugLevel.LOG, "getFilteredItems: addrID: ", addrID);
        SQW_Log(DebugLevel.LOG, "getFilteredItems: selectedFilter: ", selectedFilter);
        SQW_Log(DebugLevel.LOG, "getFilteredItems: searchCategories: ", searchCategories);
        SQW_Log(DebugLevel.LOG, "getFilteredItems: sort: ", sort);

        products = products.filter(
            (product) =>
                product.schedules &&
                (product.schedules === "ALLSCHD" ||
                    product.schedules.split(",").includes(addrID)) &&
                (searchCategories.length === 0 ||
                    selectedCategory.includes("ALL") ||
                    (product.ccatno &&
                        searchCategories.some((x) => product.ccatno.split("|||").includes(x)))) &&
                (selectedFilter === "0" ||
                    (selectedFilter === "1" && product.history1) ||
                    (selectedFilter === "2" && product.history2) ||
                    (selectedFilter === "3" &&
                        cartItems.some((x) => x.citemno === product.citemno && x.nqty !== 0))) &&
                (!inSearch || searchString === "" || doesItemIncludeText(product, searchString)),
        );

        switch (sort) {
            case "Z-A":
                products = products.sort((a, b) => b.cdescshort.localeCompare(a.cdescshort));
                break;
            case "PLH":
                products = products.sort((a, b) => a.nprice - b.nprice);
                break;
            case "PHL":
                products = products.sort((a, b) => b.nprice - a.nprice);
                break;
            default:
                products = products.sort((a, b) => a.cdescshort.localeCompare(b.cdescshort));
                break;
        }

        return products;
    };

    useEffect(() => {
        if (hasLoaded)
            getAPIProducts(
                "currentPage, searchString, ItemsPerPage, applyFilters",
                getAddressFromCID(selectedAddress),
            );
    }, [currentPage, searchString, ItemsPerPage, applyFilters]);

    useEffect(() => {
        if (applyFilters && hasLoaded)
            getAPIProducts(
                "searchCategory, selectedFilter, selectedCategory, sort, applyFilters",
                getAddressFromCID(selectedAddress),
            );
    }, [selectedFilter, selectedCategory, sort, applyFilters]);

    function getFormattedDate(date: Date) {
        if (date < new Date()) return "";

        const year = date.getFullYear();
        const month = (1 + date.getMonth()).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");

        return month + "/" + day + "/" + year;
    }

    // async function getSpecialImages() {
    //     const token = await API_Authenticate();
    //     const specialImages = await API_GetSpecialImages(token);
    //     //SQW_Log(DebugLevel.LOG, "specialImages: ", specialImages);
    //     return specialImages;
    // }

    useEffect(() => {
        if (!hasLoaded) {
            initialLoad();
        }
        /*setIsLoading(true);
        try {
            getMyCategories();
            getScheduleAddress(signedInUser()).then((address) => {
                setAddresses(address);
                if (address && address.length > 0 && (!currentAddress || !currentAddress?.cid))
                    setCurrentAddress && setCurrentAddress(address[0]);
            });
            getSpecialImages().then((images) => {
                if (images?.Images) setSpecialImages(images.Images);
                //SQW_Log(DebugLevel.LOG, "specialImages: ", images?.Images);
            });
            const key = signedInUser + "_" + GetTenantName() + "_Cart";
            //SQW_Log(DebugLevel.LOG, "Restoring Cart.", key);
            const pastCart = localStorage.getItem(key);
            if (pastCart) {
                //SQW_Log(DebugLevel.LOG, "Restoring Past Cart.  ", pastCart);
                setCartItems(JSON.parse(pastCart));
            }
        } catch (err) {
            //SQW_Log(DebugLevel.LOG, "Error loading data: ", err);
        }
        setIsLoading(false);
        setHasLoaded(true);*/
    }, []);

    const previousLoadingDeliveryRef = useRef<boolean>(loadingDeliveryAddresses);

    useEffect(() => {
        if (previousLoadingDeliveryRef.current === loadingDeliveryAddresses) return;

        previousLoadingDeliveryRef.current = loadingDeliveryAddresses;
        SQW_Log(
            DebugLevel.LOG,
            "H22: useEffect: loadingDeliveryAddresses: ",
            loadingDeliveryAddresses,
        );
        const updateAddresses = async () => {
            const po = await GetPersistantObject();
            if (!loadingDeliveryAddresses) {
                if (
                    po.deliveryAddresses &&
                    po.deliveryAddresses.length > 0
                    //&& (!currentAddress || !currentAddress?.cid
                ) {
                    SQW_Log(
                        DebugLevel.INFO_WITH_INFO_TYPE,
                        "initialLoad: loading addresses",
                        po.deliveryAddresses,
                    );
                    setAddresses(po.deliveryAddresses);
                    setCurrentAddress && setCurrentAddress(po.deliveryAddresses[0]);
                } else {
                    SQW_Log(
                        DebugLevel.INFO_WITH_INFO_TYPE,
                        "useEffect: no addresses found",
                        po.deliveryAddresses,
                    );
                }
            }
        };
        SQW_Log(DebugLevel.LOG, "useEffect: loadingDeliveryAddresses: ", loadingDeliveryAddresses);
        updateAddresses();
    }, [loadingDeliveryAddresses]);

    const previousLoadingProductsRef = useRef<boolean>(loadingProducts);

    useEffect(() => {
        if (previousLoadingProductsRef.current === loadingProducts) return;

        previousLoadingProductsRef.current = loadingProducts;

        SQW_Log(DebugLevel.LOG, "H22: useEffect: loadingProducts: ", loadingProducts);
        const updateProducts = async () => {
            if (!loadingProducts) {
                const po = await GetPersistantObject();
                if (po.products && po.products.length > 0) {
                    SQW_Log(
                        DebugLevel.INFO_WITH_INFO_TYPE,
                        "useEffect: updating products",
                        po.products,
                    );
                    setFrontEndProducts(po.products);
                } else {
                    SQW_Log(
                        DebugLevel.INFO_WITH_INFO_TYPE,
                        "useEffect: no products found",
                        po.products,
                    );
                }
            }
        };
        SQW_Log(DebugLevel.LOG, "useEffect: loadingProducts: ", loadingProducts);
        updateProducts();
    }, [loadingProducts]);

    async function getPersistantObj() {
        //await loadStoredItems();
        await CreatePersistantObject({} as AuthObject);
        let po = GetPersistantObject();
        /*try {
            SQW_Log(DebugLevel.LOG, "GH333:  getPersistantObj: ", po);
            if (
                po.categories.length === 0 ||
                po.deliveryAddresses.length === 0 ||
                po.products.length === 0
            ) {
                SQW_Log(
                    DebugLevel.INFO_WITH_INFO_TYPE,
                    "getPersistantObj: reloading persistant object",
                );
                await CreatePersistantObject({} as AuthObject);
                po = GetPersistantObject();
            }
        } catch (err) {
            SQW_Log(DebugLevel.LOG, "getPersistantObj: Error checking persistant object: ", err);
        }*/
        return po;
    }

    async function initialLoad() {
        //setIsLoading(true);

        try {
            SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "initialLoad: ");
            const po = await getPersistantObj();

            SQW_Log(
                DebugLevel.INFO_WITH_INFO_TYPE,
                "initialLoad: loading categories",
                po.categories,
            );
            if (!po.categories || po.categories.length === 0) {
                SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "initialLoad: loading categories from API");
            }
            setCategories(po.categories);

            if (po.deliveryAddresses.length > 0) {
                SQW_Log(
                    DebugLevel.INFO_WITH_INFO_TYPE,
                    "initialLoad: loading addresses",
                    po.deliveryAddresses,
                );
                setAddresses(po.deliveryAddresses);
                if (po.currentAddress && po.currentAddress.cid) {
                    setSelectedAddress(po.currentAddress.cid);
                    setCurrentAddress &&
                        setCurrentAddress(getAddressFromCID(po.currentAddress.cid));
                } else {
                    setSelectedAddress(po.deliveryAddresses[0].cid);
                    setCurrentAddress && setCurrentAddress(po.deliveryAddresses[0]);
                }
            } else {
                SQW_Log(
                    DebugLevel.INFO_WITH_INFO_TYPE,
                    "initialLoad: no addresses found",
                    po.deliveryAddresses,
                );
            }

            const images = po.specialImages;
            SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "initialLoad: loading special images", images);
            if (images) {
                setSpecialImages(images);
            }
            if (po.cartItems.length > 0) {
                SQW_Log(
                    DebugLevel.INFO_WITH_INFO_TYPE,
                    "initialLoad: loading cart items",
                    po.cartItems,
                );
                setCartItems(po.cartItems);
            }

            if (po.products.length > 0) {
                SQW_Log(
                    DebugLevel.INFO_WITH_INFO_TYPE,
                    "initialLoad: loading products",
                    po.products,
                );
                setFrontEndProducts(po.products);
            }
        } catch (err) {
            SQW_Log(DebugLevel.LOG, "Error loading data: ", err);
        } finally {
            setIsLoading(false);
        }
        setHasLoaded(true);
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "initialLoad: done");
    }

    async function getNextDeliveryDate(customer: Customer) {
        try {
            const token = await API_Authenticate();
            const ddate = await API_GetNextDeliveryDate(token, customer.ccustno, customer.cschedid);
            if (ddate?.DeliveryDate) {
                //SQW_Log(DebugLevel.LOG, "getNextDeliveryDate: ", ddate.DeliveryDate);
                const deliveryDate = new Date(ddate.DeliveryDate);
                if (deliveryDate) setNextDeliveryDate(getFormattedDate(deliveryDate));
            }
        } catch (err) {
            //SQW_Log(DebugLevel.LOG, "Error getting next delivery date: ", err);
        }
    }

    const indexOfLastItem = currentPage * ItemsPerPage;
    const indexOfFirstItem = indexOfLastItem - ItemsPerPage;
    /*const currentItems = getFilteredProducts().slice(
    indexOfFirstItem,
    indexOfLastItem,
  );*/

    const currentItems = apiProducts;
    useEffect(() => {
        if (addresses.length > 0) {
            //setSelectedAddress(addresses[0].cid);
            if (hasLoaded) {
                getAPIProducts("useEffect(addresses)", addresses[0]);
            }
        }
    }, [addresses]);

    useEffect(() => {
        if (selectedAddress !== "") {
            setAddressLabel("Schedule/Address");
            const scustomer = getCustomerFromAddress(selectedAddress);
            //SQW_Log(DebugLevel.LOG, "746: StoreItems: Selected Address useEffect customer: ", customer);

            if (scustomer) setCustomer(scustomer);
            const address = getAddressFromCID(selectedAddress);
            if (address) setCurrentAddress && setCurrentAddress(address);
            storeItem(PersistantObjectType.CURRENT_ADDRESS, address);

            if (customer) getNextDeliveryDate(customer);
        } else {
            setAddressLabel("Please select Schedule/Shipping address...");
            //SQW_Log(DebugLevel.LOG, "746: StoreItems: Selected Address is blank useEffect selectedAddress: ", selectedAddress);
        }
        getAPIProducts("useEffect(selectedAddress)", getAddressFromCID(selectedAddress));
    }, [selectedAddress]);

    useEffect(() => {
        setTotalItems(getTotalItems(cartItems));

        if (cartItems.length > 0) {
            //const key = "sqw_" + signedInUser + "_" + GetTenantName() + "_Cart";
            SQW_Log(DebugLevel.LOG, "Saving Cart.");
            storeItem(PersistantObjectType.CART_ITEMS, cartItems);
            saveStoredItems();
            /*const jsonCart = JSON.stringify(cartItems);
            //SQW_Log(DebugLevel.LOG, "Cart Items", jsonCart);
            localStorage.setItem(key, jsonCart);*/
        }
    }, [cartItems]);

    useEffect(() => {
        if (cartOpen) {
            setCheckout(false);
            //setShowItem(false);
        }
    }, [cartOpen]);

    function handleSortChange(value: string): void {
        setSort(value);
    }

    const sliderSettings = {
        dots: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 9000,
    };

    //SQW_Log(DebugLevel.LOG, "li-" + indexOfLastItem);
    //SQW_Log(DebugLevel.LOG, "fi-" + indexOfFirstItem);
    //SQW_Log(DebugLevel.LOG, "cp-" + currentPage);
    /*
    if (isLoading) return <LinearProgress />;
    if (error) return <div>An error has occurred: {error.message}</div>;
*/
    return (
        <>
            {(isLoading || loadingDeliveryAddresses || loadingProducts) && !startCheckout ?
                <LoadingSpinner
                    message={
                        "Loading..." /*
                        loadingDeliveryAddresses ? "Loading Addresses..."
                        : loadingProducts ?
                            "Loading Products..."
                        :   "Loading..." */
                    }
                />
            :   <>
                    {/*<Drawer anchor="right" open={cartOpen} onClose={() => setCartOpen(false)}>
        <div style={{ marginTop: 25 }}>
          <Cart
            cartItems={getUpdatedPriceCartItems()}
            addToCart={handleAddToCart}
            removeFromCart={handleRemoveFromCart}
            checkout={handleCheckout}
          />
        </div>
      </Drawer>*/}
                    <>
                        <Dialog
                            fullScreen={isMobile}
                            fullWidth
                            maxWidth='md'
                            open={showItem}
                        >
                            <Box className='float-right'>
                                <IconButton
                                    className='float-right'
                                    onClick={() => setShowItem(false)}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Box>
                                <Item
                                    item={itemToDisplay}
                                    customer={customer}
                                    handleAddToCart={handleAddToCart}
                                    handleItemDisplayClose={handleItemDisplayClose}
                                    handleItemDisplay={handleItemDisplay}
                                />
                            </Box>
                        </Dialog>
                        <Box
                            sx={{
                                maxWidth:
                                    isLessThanFullWidth ?
                                        isMobile ? "100vw"
                                        :   "Calc(100vw - 300px)"
                                    :   "1120px",
                                padding: "0px",
                            }}
                        >
                            <Stack
                                alignItems='left'
                                spacing={2}
                            >
                                <Grid
                                    className='store-top'
                                    container
                                    spacing={2}
                                >
                                    {specialImages && specialImages.length > 0 && (
                                        <Grid
                                            className='image-carousel'
                                            item
                                            padding='30px'
                                            maxHeight={specialImages.length > 1 ? "420px" : "360px"}
                                            sx={{ overflow: "hidden" }}
                                            xs={12}
                                            md={12}
                                        >
                                            {specialImages.length === 1 ?
                                                <img
                                                    src={specialImages[0].cfullfilename}
                                                    alt={specialImages[0].calt}
                                                    onClick={() =>
                                                        handleSpecialItemDisplay(specialImages[0])
                                                    }
                                                />
                                            :   <Slider {...sliderSettings}>
                                                    {specialImages.map(
                                                        (item: any, index: number) => (
                                                            <div
                                                                key={index}
                                                                onClick={() =>
                                                                    handleSpecialItemDisplay(item)
                                                                }
                                                            >
                                                                <img
                                                                    src={item.cfullfilename}
                                                                    alt={item.calt}
                                                                />
                                                            </div>
                                                            /*<Box
                                                        component='img'
                                                        src={item.cfullfilename}
                                                        alt={item.calt}
                                                        key={index}
                                                        onClick={() =>
                                                            handleSpecialItemDisplay(item)
                                                        }
                                                    />*/
                                                        ),
                                                    )}

                                                    {/*{content.map((item, index) => (
                <div
                  key={index}
                  style={{ background: `url('${item.image}') no-repeat center center` }}
                >
                  <div className="center">
                    <h1>{item.title}</h1>
                    <p>{item.description}</p>
                    <button>{item.button}</button>
                  </div>
                </div>
              ))}*/}
                                                </Slider>
                                            }
                                        </Grid>
                                    )}
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                    >
                                        <Flex
                                            direction='row'
                                            justifyContent={isMobile ? "center" : "space-between"}
                                        >
                                            {showNextDeliveryDate ?
                                                <Typography className='next_delivery_on'>
                                                    Next Delivery On: {nextDeliveryDate}
                                                </Typography>
                                            :   <div> </div>}

                                            <Stack
                                                direction='column'
                                                className='items-per-page'
                                            >
                                                <FormControl
                                                    variant='standard'
                                                    size='small'
                                                >
                                                    <Select
                                                        id='items-per-page'
                                                        value={ItemsPerPage.toString()}
                                                        onChange={handleItemsPerPageChange}
                                                        label='Items Per Page'
                                                    >
                                                        <MenuItem value={9}>
                                                            Show 9 Per Page{" "}
                                                        </MenuItem>
                                                        <MenuItem value={24}>
                                                            Show 24 Per Page{" "}
                                                        </MenuItem>
                                                        <MenuItem value={48}>
                                                            Show 48 Per Page{" "}
                                                        </MenuItem>
                                                        <MenuItem value={96}>
                                                            Show 96 Per Page{" "}
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Stack>
                                        </Flex>
                                    </Grid>
                                </Grid>

                                <Stack
                                    alignItems={isMobile ? "center" : "left"}
                                    direction={isMobile ? "column" : "row"}
                                    sx={{ flexWrap: "wrap", gap: 1 }}
                                >
                                    {/*<Box
                                            className='parent'
                                            m={2}
                                            display='flex'
                                            justifyContent='left'
                                            sx={{ flexWrap: "wrap", alignItems: "center" }}
                                                >*/}
                                    {/*<Grid  item xs={12} md={4}>*/}
                                    <Box className='search_grid_items'>
                                        <Label className='search_textfields_label'>Search</Label>
                                        <TextField
                                            label='Search'
                                            labelHidden
                                            inputStyles={{
                                                backgroundColor: "white",
                                            }}
                                            className='search_textfields'
                                            onChange={(e) => setSearchInput(e.target.value)}
                                            value={searchInput}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    startSearch();
                                                }
                                            }}
                                            outerEndComponent={
                                                <FieldGroupIconButton
                                                    backgroundColor={"#58b55d"}
                                                    color={"white"}
                                                    ariaLabel='Search'
                                                    variation='link'
                                                    onClick={() =>
                                                        searchInput === "" ? cancelSearch() : (
                                                            startSearch()
                                                        )
                                                    }
                                                >
                                                    <MdSearch />
                                                </FieldGroupIconButton>
                                            }
                                            innerEndComponent={
                                                <FieldGroupIconButton
                                                    ariaLabel='Cancel Search'
                                                    variation='link'
                                                    onClick={() => cancelSearch()}
                                                >
                                                    <MdClose />
                                                </FieldGroupIconButton>
                                            }
                                        />
                                    </Box>
                                    <Box className='search_grid_items'>
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            <Grid
                                                item
                                                xs={8}
                                                lg={12}
                                            >
                                                <Label className='search_textfields_label'>
                                                    Sort By
                                                </Label>
                                                <SelectField
                                                    className='search_textfields'
                                                    labelHidden
                                                    id='sel-sort'
                                                    onChange={(event) =>
                                                        handleSortChange(event.target.value)
                                                    }
                                                    value={sort}
                                                    label='Sort By'
                                                >
                                                    <option
                                                        className='search_textfields'
                                                        key='A-Z'
                                                        value='A-Z'
                                                    >
                                                        Alphabetically, A-Z
                                                    </option>
                                                    <option
                                                        className='search_textfields'
                                                        key='Z-A'
                                                        value='Z-A'
                                                    >
                                                        Alphabetically, Z-A
                                                    </option>
                                                    <option
                                                        className='search_textfields'
                                                        key='PLH'
                                                        value='PLH'
                                                    >
                                                        Price, Low-High
                                                    </option>
                                                    <option
                                                        className='search_textfields'
                                                        key='PHL'
                                                        value='PHL'
                                                    >
                                                        Price, High-Low
                                                    </option>
                                                </SelectField>
                                            </Grid>
                                            <Hidden lgUp>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    lg={12}
                                                >
                                                    <div className='filters_button'>
                                                        <Button
                                                            style={{
                                                                backgroundColor: "#58B55D",
                                                                marginTop: "0px",
                                                                marginBottom: "5px",
                                                                color: "white",
                                                                width: "100%",
                                                            }}
                                                            variant='contained'
                                                            onClick={() => setSideBarOpen(true)}
                                                        >
                                                            Filters
                                                        </Button>
                                                    </div>
                                                </Grid>
                                            </Hidden>
                                        </Grid>
                                    </Box>
                                    <Box className='search_grid_items'>
                                        {/*<Grid  item xs={12} md={4}>*/}
                                        <Label className='search_textfields_label'>
                                            Schedule/Shipping Address
                                        </Label>
                                        <SelectField
                                            className='search_textfields'
                                            labelHidden
                                            id='sel-address'
                                            onChange={handleScheduleAddressChange}
                                            value={currentAddress?.cid}
                                            label={addressLabel}
                                            placeholder='Please select an Address...'
                                        >
                                            {addresses &&
                                                addresses?.map((addr) => (
                                                    <option
                                                        className='search_textfields'
                                                        key={addr.cid}
                                                        value={addr.cid}
                                                    >
                                                        {addr.caddr}
                                                        {/*<div dangerouslySetInnerHTML={{ __html: addr.caddr }} />*/}
                                                    </option>
                                                ))}
                                        </SelectField>
                                    </Box>
                                    {/*</Grid>*/}
                                    {/*</Box>*/}
                                </Stack>
                                {/*</Stack>*/}
                            </Stack>
                            <br />

                            {/* Drawer was here
            
            <div className="ShoppingCartButtonDiv">
              <IconButton
                className="ShoppingCartButton"
                onClick={() => setCartOpen(true)}
              >
                <Badge
                  badgeContent={getTotalItems(cartItems)}
                  color="secondary"
                >
                  <AddShoppingCartIcon />
                </Badge>
              </IconButton>
                    </div>*/}
                            {currentItems?.length === 0 ?
                                <>
                                    <Typography
                                        align='center'
                                        variant='h6'
                                    >
                                        No items found
                                    </Typography>
                                    <Box sx={{ minHeight: "Calc(100vh - 280px)" }} />
                                </>
                            :   <>
                                    {/*<Grid container spacing={1}>*/}
                                    <Stack
                                        alignItems={isMobile ? "center" : "left"}
                                        direction={isMobile ? "column" : "row"}
                                        sx={{ flexWrap: "wrap", gap: 1 }}
                                    >
                                        {/*<Box
                                                className='parent'
                                                display='flex'
                                                justifyContent='left'
                                                sx={{ flexWrap: "wrap", alignItems: "left" }}
                                >*/}
                                        {currentItems?.map((item) => (
                                            <div
                                                key={item.citemno}
                                                className='product_grid_items'
                                            >
                                                <ProductCard2
                                                    key={item.citemno}
                                                    item={item}
                                                    handleAddToCart={handleAddToCart}
                                                    handleItemDisplay={handleItemDisplay}
                                                />
                                            </div>
                                        ))}
                                        {/*</Box>*/}
                                    </Stack>
                                    {/*</Grid>*/}
                                    <br />
                                    <br />
                                    <Stack alignItems='center'>
                                        <div className='parent'>
                                            <div
                                                id='d1'
                                                className='inline-block-child'
                                            >
                                                <Pagination
                                                    count={numberOfPages}
                                                    page={currentPage}
                                                    onChange={(e, value) =>
                                                        setCurrentPage(Number(value))
                                                    }
                                                    defaultPage={1}
                                                    sx={{
                                                        "float": "right",
                                                        "mt": 2,
                                                        "& .MuiPaginationItem-root": {
                                                            "borderRadius": "4px",
                                                            "backgroundColor":
                                                                theme.palette.common.white,
                                                            "color": theme.palette.text.primary,
                                                            "border": `1px solid ${theme.palette.text.primary}`,
                                                            "&:hover": {
                                                                color: theme.palette.primary.main,
                                                                border: `2px solid ${theme.palette.primary.main}`,
                                                                backgroundColor:
                                                                    theme.palette.common.white,
                                                                fontWeight: 600,
                                                            },
                                                            "&.Mui-selected": {
                                                                backgroundColor:
                                                                    theme.palette.primary.main,
                                                                color: theme.palette.common.white,
                                                            },
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Stack>
                                </>
                            }
                            <Box sx={{ height: isMobile ? "150px" : "150px" }} />
                        </Box>
                    </>
                </>
            }
        </>
    );
};

export default StoreItems;
