import { AttributeValue, DynamoDBClient, ExecuteStatementCommand } from "@aws-sdk/client-dynamodb";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { SQW_Log, DebugLevel } from "services/Logging";

import { getAWSConfig, hasTokenExpired, UpdateTokens } from "../services/GetInvoiceData";
import { getItemStr, PersistantObjectType } from "../services/PersistantObjects";

interface loginKeys {
    [key: string]: string;
}

const ddbClient = async () => {
    try {
        const config = getAWSConfig();
        const logins: loginKeys = {};
        let token = await getItemStr(PersistantObjectType.ID_TOKEN);

        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "GLBIDTOKEN:", token);
        if (!token || token.length < 10) {
            SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "ddbClient - No token.");
            token = await UpdateTokens();
            if (!token || token.length < 10) return null;
        }

        if (hasTokenExpired(token)) {
            SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "ddbClient - Token expired.");
            token = await UpdateTokens();
            if (!token || token.length < 10) return null;
        }

        logins[
            `cognito-idp.${config.aws_cognito_region}.amazonaws.com/${config.aws_user_pools_id}`
        ] = token;

        /*SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "GLBIDTOKEN logins:", logins);
    SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "GLBIDTOKEN config:", config);*/

        return new DynamoDBClient({
            region: config.aws_cognito_region,

            credentials: fromCognitoIdentityPool({
                clientConfig: { region: config.aws_cognito_region },
                identityPoolId: config.aws_cognito_identity_pool_id,
                logins: logins,
            }),
        });
    } catch (error) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error creating ddbClient:", error);
        throw error;
    }
};

const DataTypeBinary = "Binary";
const DataTypeBinarySet = "BinarySet";
const DataTypeBoolean = "Boolean";
const DataTypeList = "List";
const DataTypeMap = "Map";
const DataTypeNull = "Null";
const DataTypeNumber = "Number";
const DataTypeNumberSet = "NumberSet";
const DataTypeString = "String";
const DataTypeStringSet = "StringSet";

//This assumes the db is flat...
function dynamoDbRecordsToArray(records: Record<string, AttributeValue>[] | undefined): any[] {
    const returnArray: any[] = [];
    try {
        if (!records || records.length === 0) return returnArray;

        records.forEach((record) => {
            const obj: { [key: string]: any } = {};
            //SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "dynamoDbRecordsToArray: record:", record);
            Object.entries(record).forEach(([key, value]) => {
                SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "dynamoDbRecordsToArray: key: " + key);
                obj[key] = getAttributeValue(value);
                //SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "dynamoDbRecordsToArray: value:", obj[key]);
            });
            if (obj) returnArray.push(obj);
        });

        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "dynamoDbRecordsToArray: ", returnArray);
    } catch (error) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error creating dynamoDbRecordsToArray:", error);
    }
    return returnArray;
}

function getAttributeValue(attributeValue: AttributeValue): any {
    const [key, value] = Object.entries(attributeValue)[0];

    //SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "getAttributeValue: key:", key);
    //SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "getAttributeValue: value:", value);

    return value;

    /*switch (key) {
        case "S":
            return value.S;
        case "N":
            return Number(value.N);
        case "BOOL":
            return value.BOOL;
        case "SS":
            return value.SS;
        case "NS":
            if (!value.NS) return "";
            return value.NS.map(Number);
        case "BS":
            return value.BS;
        case "L":
            if (!value.L) return "";
            return value.L.map((item: AttributeValue) => getAttributeValue(item));
        case "M":
            return Object.fromEntries(
                value.M ?
                    Object.entries(value.M).map(([k, v]) => [
                        k,
                        getAttributeValue(v as AttributeValue),
                    ])
                :   [],
            );
        case "NULL":
            return null;
        default:
            return value;
        //throw new Error(`Unknown attribute value type: ${key}`);
    }*/
}

const executeQuery = async (partiQLQuery: string, parameters: []) => {
    try {
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "Executing SQL query:", partiQLQuery, parameters);
        const client = await ddbClient();

        const params: { Statement: string; Parameters?: any[] } = {
            Statement: partiQLQuery,
        };
        if (parameters.length > 0) {
            params["Parameters"] = parameters;
        }
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "executeQuery Params:", params);
        const command = new ExecuteStatementCommand(params);
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "executeQuery Command:", command);
        const response = client ? await client.send(command) : { Items: [] };
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "executeQuery Response:", response);
        return dynamoDbRecordsToArray(response.Items);
    } catch (error) {
        SQW_Log(
            DebugLevel.ERROR_WITH_ERROR_TYPE,
            "Error executing SQL query:",
            error,
            partiQLQuery,
            parameters,
        );
        return [];
    }
};

export { ddbClient, executeQuery };
