import { Grid, Hidden } from "@mui/material";
import React from "react";
import { CheckoutCartItems } from "interfaces/InvoiceInterfaces";

import { Wrapper } from "./CartItem/CartItem.styles";

type Props = {
    item: CheckoutCartItems;
};

const CheckoutItem: React.FC<Props> = ({ item }) => {
    return (
        <Wrapper>
            <Grid
                container
                spacing={2}
            >
                <Hidden smDown>
                    <Grid
                        item
                        xs={6}
                        sm={2}
                    >
                        <div className='image'>
                            <img
                                src={item.image && item.image ? item.image : ""}
                                alt={item.citemno}
                            />
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={4}
                    >
                        {item.citemno + " - " + item.cdescript}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={3}
                    >
                        {item.nqty}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={3}
                    >
                        ${item.nprice.toFixed(2)}
                    </Grid>
                </Hidden>
                <Hidden smUp>
                    <Grid
                        item
                        xs={4}
                    >
                        <div className='image'>
                            <img
                                src={item.image && item.image ? item.image : ""}
                                alt={item.citemno}
                            />
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={8}
                    >
                        {item.citemno + " - " + item.cdescript}
                        <br />
                        Quantity: {item.nqty}
                        <br />
                        <b>${item.nprice.toFixed(2)}</b>
                    </Grid>
                </Hidden>
            </Grid>
        </Wrapper>
    );
};

export default CheckoutItem;
