import React, { useState, useEffect, createContext, useRef } from "react";
import { SQW_Log, DebugLevel, setDebuggingMode } from "services/Logging";
import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
    CognitoRefreshToken,
    CognitoUserSession,
} from "amazon-cognito-identity-js";
import { getTenantNameFromSubdomain, GotoTenantURL } from "services/GetInvoiceData";
import LoginForm from "pages/LoginForm";
import TenantChooser from "identity/pages/TenantChooser";
import {
    clearLocalStorage,
    GetTenantsAndStore,
    getItem,
    loadStoredItems,
    PersistantObjectType,
    saveStoredItems,
    storeItem,
    CreatePersistantObject,
    GetUserName,
} from "services/PersistantObjects";
import { useDataState } from "context/DataContext";
import { setDebugMode } from "@datadog/browser-core";
import config from "../amplifyconfiguration.json";
import { AlertDialog } from "components/AlertDialog";
import { Create } from "@mui/icons-material";
import { AuthObject } from "interfaces/InvoiceInterfaces";

const userPool = new CognitoUserPool({
    UserPoolId: config.aws_user_pools_id,
    ClientId: config.aws_user_pools_web_client_id,
});

type CognitoAuthProviderProps = {
    children: React.ReactNode;
};

type Session = {
    accessToken: string;
    idToken: string;
    refreshToken: string;
    error: string;
};

export type CognitoAuthContextType = {
    cognitoAuthenticateUser: any;
    signOut: any;
    isLoading: boolean;
    isAuthenticated: boolean;
    DisplayAlert: (title: string, message: string) => void;
};

export const CognitoAuthContext = createContext({
    isAuthenticated: false,
    isLoading: true,
    cognitoAuthenticateUser: (username: string, password: string): Session => {
        return {
            accessToken: "",
            idToken: "",
            refreshToken: "",
            error: "",
        };
    },
    signOut: () => {},
    DisplayAlert: (title: string, message: string) => {},
});

export const CognitoAuthProvider: React.FC<CognitoAuthProviderProps> = ({ children }) => {
    const [username, setUsername] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [tenantsLoaded, setTenantsLoaded] = useState(false);
    const [isValidTenant, setIsValidTenant] = useState(false);
    const [accessToken, setAccessToken] = useState("");
    const [idToken, setIdToken] = useState("");
    const [refreshToken, setRefreshToken] = useState("");
    const [currentTenant, setCurrentTenant] = useState("");
    const [noAccessToTenant, setNoAccessToTenant] = useState(false);
    const refreshTokenInterval = useRef<NodeJS.Timer | null>(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertMessage, setAlertMessage] = useState("");

    const { signedInUser, setSignedInUser, loadingTenants } = useDataState();

    useEffect(() => {
        async function LoadPersistantData() {
            //await loadStoredItems();
            //CreatePersistantObject({} as AuthObject);
        }
        checkUser();
        LoadPersistantData();
        // if (isAuthenticated) {
        //     SQW_Log(DebugLevel.LOG, "AGG12:  Setting refresh token interval.");
        //     refreshTokenInterval.current = setInterval(RefreshTokens, 300000); // Refresh every 5 minutes
        // }
    }, []);

    /*useEffect(() => {
        if (getTenantName() === currentTenant) {
            setIsValidTenant(true);
        } else {
            setIsValidTenant(false);
        }
    }, [currentTenant]);*/
    let createdPersistantObject = false;

    useEffect(() => {
        if (isAuthenticated) {
            SQW_Log(
                DebugLevel.INFO_WITH_INFO_TYPE,
                "DG 11: 445 isAuthenticated: ",
                isAuthenticated,
            );
            if (createdPersistantObject === false) {
                CreatePersistantObject({} as AuthObject);
                createdPersistantObject = true;
            }
            LoadUserTenants();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated && GetUserName() !== "" && !isValidTenant) {
            checkUser();
            LoadUserTenants();
        }
    }, [signedInUser]);

    useEffect(() => {
        if (!loadingTenants && isAuthenticated && username !== "" && !isValidTenant) {
            LoadUserTenants();
        }
    }, [loadingTenants]);

    useEffect(() => {
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "DG 11: 445 isValidTenant: ", isValidTenant);
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "DG 11: 445 isAuthenticated: ", isAuthenticated);

        if (isAuthenticated && isValidTenant) {
            setSignedInUser && setSignedInUser(username);
            setTenantsLoaded(true);
        }
    }, [isValidTenant]);

    function getTenantName() {
        return getTenantNameFromSubdomain() ?? "";
    }

    async function LoadUserTenants() {
        if (username === "") {
            return;
        }

        SQW_Log(DebugLevel.LOG, "DG11: 44  LoadUserTenants: ");
        let tenants = getItem(PersistantObjectType.USER_TENANTS) as string[];
        if (!tenants || tenants.length === 0) {
            SQW_Log(DebugLevel.LOG, "DG11: 44  GetTenantsAndStore");
            await GetTenantsAndStore();
            tenants = getItem(PersistantObjectType.USER_TENANTS) as string[];
        }

        SQW_Log(DebugLevel.LOG, "DG11: 44  LoadUserTenants here: ", tenants);
        if (tenants && tenants.length > 0) {
            SQW_Log(
                DebugLevel.LOG,
                "DG11: 44  LoadUserTenants Got tenants...: ",
                tenants,
                getTenantName(),
            );
            SQW_Log(
                DebugLevel.LOG,
                "DG11:  LoadUserTenants Tenant includes...: ",
                tenants.includes(getTenantName()),
            );
            if (tenants.includes(getTenantName())) setIsValidTenant(true);
            setTenantsLoaded(true);
        } else {
            setNoAccessToTenant(true);
            setTenantsLoaded(true);
        }
    }

    const checkUser = () => {
        try {
            const cognitoUser = userPool.getCurrentUser();
            SQW_Log(DebugLevel.LOG, "AGG12:  Checking user: ", cognitoUser);
            if (cognitoUser) {
                cognitoUser.getSession((err: any, session: CognitoUserSession) => {
                    if (err) {
                        setIsAuthenticated(false);
                        SQW_Log(
                            DebugLevel.LOG,
                            "AGG12:  Failed to get session for checkUser: ",
                            err,
                        );
                    } else {
                        SQW_Log(
                            DebugLevel.LOG,
                            "AGG12:  Got session for checkUser: ",
                            cognitoUser.getUsername(),
                        );
                        setIsAuthenticated(true);
                        setAccessToken(session.getAccessToken().getJwtToken());
                        setIdToken(session.getIdToken().getJwtToken());
                        setRefreshToken(session.getRefreshToken().getToken());
                        storeItem(
                            PersistantObjectType.ACCESS_TOKEN,
                            session.getAccessToken().getJwtToken(),
                        );
                        storeItem(
                            PersistantObjectType.ID_TOKEN,
                            session.getIdToken().getJwtToken(),
                        );
                        storeItem(
                            PersistantObjectType.REFRESH_TOKEN,
                            session.getRefreshToken().getToken(),
                        );
                        if (username !== "") {
                            storeItem(PersistantObjectType.USER_NAME, username);
                            setSignedInUser && setSignedInUser(username);
                            setUsername(username);
                        }

                        saveStoredItems();

                        {
                            cognitoUser.getUserAttributes((err, result) => {
                                if (err) {
                                    SQW_Log(
                                        DebugLevel.ERROR_WITH_ERROR_TYPE,
                                        "Failed to get user attributes.",
                                        err,
                                    );
                                    return;
                                }
                                if (result) {
                                    const username = result.find((attr) => attr.Name === "email");
                                    if (
                                        signedInUser === "" &&
                                        username &&
                                        username.getValue() !== ""
                                    ) {
                                        storeItem(
                                            PersistantObjectType.USER_NAME,
                                            username.getValue(),
                                        );
                                        setSignedInUser && setSignedInUser(username.getValue());
                                        saveStoredItems();
                                        setUsername(username.getValue());
                                    }

                                    let debLevel = "0";
                                    if (process.env.REACT_APP_DEBUG_LEVEL_FIELD) {
                                        const debugLevel = result.find(
                                            (attr) =>
                                                attr.Name ===
                                                process.env.REACT_APP_DEBUG_LEVEL_FIELD,
                                        );
                                        SQW_Log(
                                            DebugLevel.ERROR,
                                            "debug level",
                                            debugLevel?.getValue(),
                                        );
                                        if (debugLevel) {
                                            debLevel = debugLevel.getValue();
                                        }
                                    }

                                    if (
                                        process.env.REACT_APP_DEBUG_FIELD &&
                                        process.env.REACT_APP_DEBUG_KEY
                                    ) {
                                        const debug = result.find(
                                            (attr) =>
                                                attr.Name === process.env.REACT_APP_DEBUG_FIELD,
                                        );
                                        if (debug?.getValue() === process.env.REACT_APP_DEBUG_KEY) {
                                            SQW_Log(
                                                DebugLevel.LOG,
                                                "debug value found - setting debug mode",
                                            );
                                            setDebuggingMode(debLevel);
                                        }
                                    }
                                }
                            });
                        }
                    }
                });
            } else {
                setIsAuthenticated(false);
            }
        } catch (e) {
            SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error in checkUser: ", e);
        }
    };
    const RefreshTokens = () => {
        try {
            let cognitoUser = userPool.getCurrentUser();
            if (!cognitoUser) {
                cognitoUser = new CognitoUser({
                    Username: username,
                    Pool: userPool,
                });
            }
            const RefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken });

            cognitoUser.refreshSession(RefreshToken, (err, session) => {
                if (err) {
                    SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Token refresh failed.", err);
                    //alert("Token refresh failed: " + err.message);
                    return;
                } else {
                    setAccessToken(session.getAccessToken().getJwtToken());
                    setIdToken(session.getIdToken().getJwtToken());
                    setRefreshToken(session.getRefreshToken().getToken());
                    if (username !== "") storeItem(PersistantObjectType.USER_NAME, username);
                    storeItem(PersistantObjectType.ACCESS_TOKEN, session.accessToken);
                    storeItem(PersistantObjectType.ID_TOKEN, session.idToken);
                    storeItem(PersistantObjectType.REFRESH_TOKEN, session.refreshToken);
                    saveStoredItems();
                }
            });
        } catch (e) {
            SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error in RefreshTokens: ", e);
        }
    };

    const onChooseTenant = async (tenantName: string): Promise<void> => {
        localStorage.setItem("sqw_selectedTenant", tenantName);
        SQW_Log(DebugLevel.LOG, "onChooseTenant: ", tenantName);
        setCurrentTenant(tenantName);
        await GotoTenantURL(tenantName);
        if (getTenantName() === tenantName) {
            setIsValidTenant(true);
            setTenantsLoaded(true);
        } else {
            SQW_Log(
                DebugLevel.INFO_WITH_INFO_TYPE,
                "onChooseTenant:  Setting isValidTenant to false.",
            );
            setIsValidTenant(false);
        }
    };

    const cognitoAuthenticateUser = (username: string, password: string): Session => {
        const session = {
            accessToken: "",
            idToken: "",
            refreshToken: "",
            error: "",
        };
        const authenticationDetails = new AuthenticationDetails({
            Username: username,
            Password: password,
        });

        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        });

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                SQW_Log(DebugLevel.LOG, "Authentication successful:", result);
                localStorage.setItem("sqw_u", username);
                session.accessToken = result.getAccessToken().getJwtToken();
                session.idToken = result.getIdToken().getJwtToken();
                session.refreshToken = result.getRefreshToken().getToken();
                storeItem(PersistantObjectType.USER_NAME, username);
                storeItem(PersistantObjectType.ACCESS_TOKEN, session.accessToken);
                storeItem(PersistantObjectType.ID_TOKEN, session.idToken);
                storeItem(PersistantObjectType.REFRESH_TOKEN, session.refreshToken);
                saveStoredItems();
                setIsAuthenticated(true);
                setAccessToken(session.accessToken);
                setIdToken(session.idToken);
                setRefreshToken(session.refreshToken);
                setUsername(username);

                if (setSignedInUser) setSignedInUser(username);

                refreshTokenInterval.current = setInterval(RefreshTokens, 300000); // Refresh every 5 minutes
            },
            onFailure: (err) => {
                SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Authentication failed:", err);
                DisplayAlert("Authentication Failed", "Authentication failed: " + err.message);
                session.error = "Authentication failed: " + err.message;
            },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                SQW_Log(
                    DebugLevel.WARN_WITH_WARN_TYPE,
                    "New password required:",
                    userAttributes,
                    requiredAttributes,
                );
                session.error = "New password required";
            },
        });
        return session;
    };

    const signOut = () => {
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
            cognitoUser.signOut();
            setUsername("");
            setIsAuthenticated(false);
            setTenantsLoaded(false);
            setIsValidTenant(false);
            setAccessToken("");
            setIdToken("");
            setRefreshToken("");
            clearLocalStorage();
            clearInterval(refreshTokenInterval.current as NodeJS.Timer);
            SQW_Log(DebugLevel.LOG, "User logged out successfully");
        }
    };

    const DisplayAlert = (title: string, message: string) => {
        setAlertMessage(message);
        setAlertTitle(title);
        setAlertOpen(true);
    };

    const CloseAlert = () => {
        setAlertOpen(false);
    };

    return (
        <>
            <AlertDialog
                open={alertOpen}
                onClose={CloseAlert}
                title={alertTitle}
                message={alertMessage}
            />
            <CognitoAuthContext.Provider
                value={{
                    isAuthenticated,
                    cognitoAuthenticateUser(username, password) {
                        return cognitoAuthenticateUser(username, password);
                    },
                    signOut,
                    isLoading: false,
                    DisplayAlert,
                }}
            >
                {isAuthenticated && tenantsLoaded ?
                    isValidTenant ?
                        children
                    :   <TenantChooser
                            username={username}
                            onChooseTenant={onChooseTenant}
                            onSignOut={signOut}
                        />

                :   <LoginForm />}
            </CognitoAuthContext.Provider>
        </>
    );
};

export default CognitoAuthProvider;
