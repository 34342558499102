import { Button, TextField } from "@aws-amplify/ui-react";
import { SQW_Log, DebugLevel } from "services/Logging";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { experimentalStyled as styled } from "@mui/material/styles";
import * as React from "react";
import { GreenButton } from "components/Buttons";

import {
    API_Authenticate,
    API_GetUserLinkedCustomers,
    API_RegisterNewUser,
    API_RemoveCustomerFromUser,
} from "../services/GetInvoiceData";
import { getOverrideProps } from "../ui-components/utils";
import UpdateUsersTenants from "./UpdateUsersTenants";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

interface UpdateCustomerProps {
    userid: string;
    onSuccess: () => void;
}

export default function UpdateCustomers(props: UpdateCustomerProps) {
    const [selectedPage, setSelectedPage] = React.useState("default");
    const [customers, setCustomers] = React.useState([] as string[]);
    const [customerName, setCustomerName] = React.useState("");
    const [addRelationOpen, setAddRelationOpen] = React.useState(false);
    const [confirmationOpen, setConfirmationOpen] = React.useState(false);

    function UsersTable() {
        //SQW_Log(DebugLevel.LOG, "Calling UsersTable");
        return (
            <>
                <TableContainer component={Paper}>
                    <Table aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell align='left'>
                                    <h5>Customer</h5>
                                </TableCell>
                                <TableCell align='left'></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customers
                                .sort((a, b) => (a > b ? 1 : -1))
                                .map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            "&:last-child td, &:last-child th": { border: 0 },
                                        }}
                                    >
                                        <TableCell
                                            component='th'
                                            scope='row'
                                        >
                                            {row}
                                        </TableCell>
                                        <TableCell align='right'>
                                            <GreenButton
                                                style={{ padding: "30px" }}
                                                onClick={() => {
                                                    showDeleteConfirmation(row);
                                                }}
                                            >
                                                Delete
                                            </GreenButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }

    function ConfirmationBox() {
        return (
            <Dialog
                open={confirmationOpen}
                onClose={handleConfirmationClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{"Delete?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        {"Are you sure you want to delete this user/customer relationship?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleConfirmationClose}
                        autoFocus
                    >
                        No
                    </Button>
                    <Button onClick={handleConfirm}>Yes</Button>
                </DialogActions>
            </Dialog>
        );
    }
    const handleConfirmationOpen = () => {
        setConfirmationOpen(true);
    };
    const handleConfirmationClose = () => {
        setConfirmationOpen(false);
    };
    async function handleConfirm() {
        setConfirmationOpen(false);
        const token = await API_Authenticate();
        const ret = await API_RemoveCustomerFromUser(token, customerName, props?.userid);
        if (ret && ret.IsSuccessful) {
            alert("Customer " + customerName.trim() + " removed from " + props?.userid);
            getData();
        } else {
            if (ret) {
                alert(ret.Error);
                SQW_Log(DebugLevel.LOG, "Error when trying to remove customer: ", ret);
            } else
                alert(
                    "Unknown error when trying to remove customer " +
                        customerName.trim() +
                        " from " +
                        props?.userid,
                );
        }
    }

    function showDeleteConfirmation(customer: string) {
        setCustomerName(customer);
        handleConfirmationOpen();
    }

    async function onSaveCustomer() {
        if (!props?.userid) {
            alert("Could not get user id.  Please try again.");
            return;
        }
        if (!customerName) {
            alert("Could not get customer #.  Please try again.");
            return;
        }
        try {
            const token = await API_Authenticate();
            const ret = await API_RegisterNewUser(token, customerName, props?.userid, true);
            if (ret && ret.IsSuccessful) {
                {
                    alert("Customer " + customerName + " added to " + props?.userid);
                    getData();
                }
            } else {
                if (ret) {
                    alert(ret.Error);
                    SQW_Log(DebugLevel.LOG, "Error when trying to add customer: ", ret);
                    return;
                } else {
                    alert(
                        "Unknown error when trying to add customer " +
                            customerName.trim() +
                            " to " +
                            props?.userid,
                    );
                    return;
                }
            }
        } catch (err) {
            alert(err);
            return;
        }
        setAddRelationOpen(false);
        //props.onSuccess();
    }

    function onCustomerClose(): void {
        setAddRelationOpen(false);
    }

    function AddRelationshipBox() {
        return (
            <Dialog
                open={addRelationOpen}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <div>
                    <FormControl sx={{ m: 1, minWidth: 300 }}>
                        <TextField
                            id='outlined-basic'
                            label='Customer #'
                            value={customerName}
                            onChange={(e) => setCustomerName((e.target as HTMLInputElement).value)}
                        />
                        <br />
                        <br />
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={6}
                            >
                                <Button
                                    onClick={() => {
                                        onCustomerClose();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                            >
                                <Button
                                    onClick={() => {
                                        onSaveCustomer();
                                    }}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </FormControl>
                </div>
            </Dialog>
        );
    }

    async function getData() {
        const token = await API_Authenticate();
        const data = await API_GetUserLinkedCustomers(token, props.userid);
        //SQW_Log(DebugLevel.LOG, "Got customers: ", data);

        if (!data) return;

        setCustomers(data.data);
    }

    React.useEffect(() => {
        getData();
    }, []);

    function showPage() {
        switch (selectedPage) {
            case "default":
                return (
                    <>
                        <UsersTable />
                        <br />
                        <br />
                        <Button
                            onClick={() => {
                                setAddRelationOpen(true);
                            }}
                        >
                            Add Customer
                        </Button>
                        <br />
                        <br />
                        <Button
                            children='Go Back'
                            type='reset'
                            onClick={(event) => {
                                event.preventDefault();
                                props.onSuccess();
                            }}
                            {...getOverrideProps(null, "ClearButton")}
                        ></Button>
                    </>
                );
            default:
                return (
                    <div>
                        <h3>{selectedPage}</h3>
                        <UpdateUsersTenants
                            idProp={selectedPage}
                            onSuccess={() => {
                                setSelectedPage("default");
                            }}
                        />
                    </div>
                );
        }
    }

    return (
        <Box
            width={"600px"}
            sx={{ flexGrow: 1 }}
        >
            {ConfirmationBox()}
            {AddRelationshipBox()}
            {showPage()}
        </Box>
    );
}
