import { executeQuery } from "helpers/DynamoDBClient";
import { SQW_Log, DebugLevel } from "services/Logging";

const tableNames = {
    tenants: "Tenants-zbottfauvnbtdfkrlxmkbmjpcy-staging",
    tenantsUsers: "TenantsUsers-zbottfauvnbtdfkrlxmkbmjpcy-staging",
    users: "Users-zbottfauvnbtdfkrlxmkbmjpcy-staging",
    clients: "Clients-zbottfauvnbtdfkrlxmkbmjpcy-staging",
    hubs: "Hubs-zbottfauvnbtdfkrlxmkbmjpcy-staging",
};

type TableName = keyof typeof tableNames;

export function getTable(tableName: TableName): string {
    return `"${tableNames[tableName]}"`;
}

export async function InsertTenant(
    tenantName: string,
    description: string,
    url: string,
    authuser: string,
    authkey: string,
    address1: string,
    address2: string,
    usesHubs: boolean,
    city: string,
    state: string,
    zip: string,
    phone: string,
    email: string,
) {
    const parameters: any = [
        tenantName.trim().toLowerCase(),
        tenantName.trim().toLowerCase(),
        description,
        url,
        usesHubs,
        authkey,
        authuser,
        address1,
        address2,
        city,
        state,
        zip,
        phone,
        email,
    ];
    const qry = `INSERT INTO ${getTable("tenants")} (id, subdomain, name, url, usesHubs, uniqueid, createdby, address1, address2, city, state, zip, phone, email) 
    VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)`;
    const result = executeQuery(qry, parameters);
    return result;
}

export async function SelectTenant(tenantName: string) {
    const parameters: any = [{ S: tenantName.trim().toLowerCase() }];

    const qry = `SELECT * FROM ${getTable("tenants")} WHERE subdomain = ?`;
    const result = executeQuery(qry, parameters);
    return result;
}

export async function SelectAllTenants() {
    const result = executeQuery(`SELECT * FROM ${getTable("tenants")}`, []);
    return result;
}

export async function SelectAllUsers() {
    const parameters: any = [];

    const result = executeQuery(`SELECT * FROM ${getTable("users")}`, parameters);
    return result;
}

export async function SelectUser(username: string) {
    const parameters: any = [{ S: username }];

    const result = executeQuery(
        `SELECT * FROM ${getTable("users")} WHERE username = ?`,
        parameters,
    );
    return result;
}

export async function SelectAllTenantsUsers() {
    const parameters: any = [];

    const result = executeQuery(`SELECT * FROM ${getTable("tenantsUsers")}`, parameters);
    return result;
}

export type UserWithTenants = { userid: string; tenants: string[] };

export async function SelectUsersWithTenants() {
    const users: UserWithTenants[] = [];
    const parameters: any = [];

    const result = await executeQuery(`SELECT * FROM ${getTable("tenantsUsers")}`, parameters);

    /*if (result) {
        for (let i = 0; i < result.data.listTenantsUsers.items.length; i++) {
            users.push({ userid: item.usersId, tenants: [item.tenantsId] });
        }
    }*/
    return users;
}

export async function SelectUserTenants(username: string) {
    const parameters: any = [{ S: username }];

    const qry = `SELECT usersId, tenantsId FROM ${getTable("tenantsUsers")} WHERE usersId = ?`;
    SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "qry:", qry);
    SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "params:", parameters);
    const result = executeQuery(qry, parameters);
    return result;
}

export async function InsertUser(
    tenantName: string,
    username: string,
    firstname: string,
    lastname: string,
) {
    const parameters: any = [];

    const result = executeQuery("", parameters);
    if (!result) {
        return result;
    }
    const parameters2: any = [];

    const result2 = executeQuery("", parameters);
    return result2;
}

export async function InsertTenantUserRelation(tenantName: string, username: string) {
    const parameters: any = [];

    const result = executeQuery("", parameters);
    return result;
}

export async function DeleteTenantUserRelationship(id: string) {
    const parameters: any = [];

    const result = executeQuery("", parameters);
    return result;
}

export async function SelectAllClients() {
    const parameters: any = [];

    const result = executeQuery("", parameters);
    return result;
}

export async function SelectClient(companycode: string) {
    const parameters: any = [];

    const result = executeQuery("", parameters);
    return result;
}

export async function DeleteClient(companycode: string) {
    const parameters: any = [];

    const result = executeQuery("", parameters);
    return result;
}

export async function SelectAllHubs() {
    const parameters: any = [];

    const result = executeQuery("", parameters);
    return result;
}

export async function DeleteHub(hubid: string) {
    const parameters: any = [];

    const result = executeQuery("", parameters);
    return result;
}

export async function SelectHubsByTenantID(tenantID: string) {
    const parameters: any = [{ S: tenantID }];
    const qry = `SELECT * FROM ${getTable("hubs")} WHERE tenantid = ?`;
    const result = executeQuery(qry, parameters);
    return result;
}
