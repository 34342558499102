import React from "react";
import { SQW_Log, DebugLevel } from "services/Logging";
import { Container, TextField, Button, Typography, Box, Link } from "@mui/material";
import {
    API_Authenticate,
    API_RegisterNewUser,
    GetCompanyLogo,
    getTenantNameFromSubdomain,
    RegisterUser,
} from "services/GetInvoiceData";
import { confirmSignUp, createNewUser, resendConfirmation } from "helpers/CognitoAuth";
import { CognitoAuthContext } from "helpers/CognitoAuthRoute";

export type Props = {
    confirm: boolean;
    setConfirm: React.Dispatch<React.SetStateAction<boolean>>;
    setCreateAccount: React.Dispatch<React.SetStateAction<boolean>>;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
};

const CreateAccount: React.FC<Props> = ({
    confirm,
    setConfirm,
    setCreateAccount,
    setMessage,
    setErrorMessage,
}) => {
    const [logo, setLogo] = React.useState("");
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [accountNumber, setAccountNumber] = React.useState("");
    const [submitted, setSubmitted] = React.useState(false);
    const [emptyFields, setEmptyFields] = React.useState<string[]>([]);
    const [invalidPassword, setInvalidPassword] = React.useState("");
    const [noMatchPassword, setNoMatchPassword] = React.useState(false);
    const [firstSubmit, setFirstSubmit] = React.useState(false);
    const [confirmId, setConfirmId] = React.useState("");

    const { DisplayAlert } = React.useContext(CognitoAuthContext);

    React.useEffect(() => {
        const fetchCompanyLogo = async () => {
            const logo = await GetCompanyLogo();
            setLogo(logo);
        };
        fetchCompanyLogo();
    });

    React.useEffect(() => {
        if (submitted) {
            if (!firstSubmit) {
                setFirstSubmit(true);
            } else {
                registerUser();
            }
        }
    }, [submitted, firstSubmit]);

    React.useEffect(() => {
        validateFields();
    }, [firstName, lastName, username, password, confirmPassword, accountNumber]);

    function validatePassword(password: string) {
        const minLength = 8;
        const hasNumber = /\d/;
        const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/;

        if (password.length < minLength) {
            return "Password must be at least 8 characters long";
        }

        if (!hasNumber.test(password)) {
            return "Password must contain at least one number";
        }

        if (!hasSymbol.test(password)) {
            return "Password must contain at least one symbol";
        }

        return "";
    }

    const validateFields = () => {
        let v = true;
        const empty = [];

        if (firstSubmit) {
            if (firstName === "") {
                empty.push("firstName");
                v = false;
            }
            if (lastName === "") {
                empty.push("lastName");
                v = false;
            }
            if (username === "") {
                empty.push("username");
                v = false;
            }
            if (password === "") {
                empty.push("password");
                v = false;
            }
            if (confirmPassword === "") {
                empty.push("confirmPassword");
                v = false;
            }
            if (accountNumber === "") {
                empty.push("accountNumber");
                v = false;
            }
        }
        const err = validatePassword(password);
        setInvalidPassword(err);
        if (err.length > 1) {
            v = false;
        }
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "pwd", password, confirmPassword);
        const noMatch = password !== confirmPassword;
        setNoMatchPassword(noMatch);
        if (noMatch) {
            v = false;
        }

        setEmptyFields(empty);
        return v;
    };

    const submitForm = () => {
        setSubmitted(true);
    };

    const confirmAccount = async () => {
        try {
            const response = await confirmSignUp(username, confirmId);
            if (response && response.error != "") {
                DisplayAlert(
                    "Error",
                    "Could not confirm email.  " +
                        (response.error?.toString() ?? "Error confirming email."),
                );
                return;
            }
            setConfirm(false);
            setCreateAccount(false);
            setSubmitted(false);
            setMessage("Email confirmed.  You can now log in.");
        } catch (error) {
            DisplayAlert(
                "Error",
                "Could not confirm email signup.  " +
                    (error?.toString() ?? "Error confirming email."),
            );
        }
    };

    const callResendConfirmation = async () => {
        try {
            if (username === "") {
                DisplayAlert("Missing info", "You must enter in an email.");
                return;
            }

            const response = await resendConfirmation(username);
            if (response && response.error != "") {
                DisplayAlert(
                    "Error",
                    response.error?.toString() ?? "Error resending confirmation email.",
                );
                return;
            }
            if (response) {
                DisplayAlert("Confirmation sent", "Confirmation sent.");
            }
        } catch (error) {
            DisplayAlert("Error", error?.toString() ?? "Exception resending confirmation email.");
        }
    };

    const registerUser = async () => {
        try {
            if (validateFields()) {
                const tenant = getTenantNameFromSubdomain();
                if (tenant === null) {
                    DisplayAlert(
                        "Warning",
                        "Invalid tenant.  You cannot create an account from this site.",
                    );
                    setSubmitted(false);
                    return;
                }

                const regUser = await RegisterUser(
                    accountNumber,
                    username.toLowerCase(),
                    firstName,
                    lastName,
                );
                if (!regUser) {
                    DisplayAlert("Error", "Could not register user.");
                    setSubmitted(false);
                    return;
                }

                /*//Create account in ServQuest
                const token = await API_Authenticate();
                const regNewUser = await API_RegisterNewUser(token, accountNumber, username, false);

                SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "regNewUser", regNewUser);

                if (!regNewUser) throw new Error("Could not register new user with API.");
                */
                const response = await createNewUser(
                    firstName,
                    lastName,
                    username.toLowerCase(),
                    password,
                    accountNumber,
                    tenant,
                );
                if (response && response.error != "") {
                    DisplayAlert("Error", response.error?.toString() ?? "Error registering user.");
                    setSubmitted(false);
                    return;
                }
                setConfirm(true);
            } else {
                setSubmitted(false);
            }
        } catch (error) {
            SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error registering user:", error);
            setSubmitted(false);
        }
    };

    return (
        <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            mt={8}
        >
            {confirm ?
                <>
                    <Typography
                        variant='h5'
                        gutterBottom
                        align='left'
                        width='100%'
                    >
                        Confirmation
                    </Typography>
                    <Typography
                        variant='body2'
                        gutterBottom
                        align='left'
                        width='100%'
                    >
                        An email confirmation has been sent. Please enter your email and
                        confirmation code.
                    </Typography>
                    <TextField
                        error={emptyFields.includes("username")}
                        helperText={emptyFields.includes("username") && "Field cannot be empty."}
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        id='email'
                        label='Email'
                        name='email'
                        autoComplete='email'
                        value={username}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setUsername(e.target.value);
                        }}
                    />
                    <TextField
                        error={emptyFields.includes("confirmId")}
                        helperText={emptyFields.includes("confirmId") && "Field cannot be empty."}
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        id='confirm'
                        label='Confirmation ID'
                        name='confirm'
                        autoComplete='confirm'
                        value={confirmId}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfirmId(e.target.value);
                        }}
                    />
                    <Box mt={2}>
                        <Typography variant='body2'>
                            If you have not received a confirmation code, you can resend the
                            confirmation email by clicking{" "}
                            <Link
                                style={{ color: "#58B55D" }}
                                onClick={() => {
                                    callResendConfirmation();
                                }}
                            >
                                Resend Confirmation
                            </Link>
                            .
                        </Typography>
                    </Box>
                    <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        style={{ marginTop: 20 }}
                        onClick={confirmAccount}
                    >
                        Confirm
                    </Button>
                </>
            :   <>
                    <Typography
                        variant='h5'
                        gutterBottom
                        align='left'
                        width='100%'
                    >
                        Create Account
                    </Typography>
                    <TextField
                        error={emptyFields.includes("firstName")}
                        helperText={emptyFields.includes("firstName") && "Field cannot be empty."}
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        id='firstName'
                        label='First Name'
                        name='firstName'
                        autoComplete='fname'
                        autoFocus
                        value={firstName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFirstName(e.target.value);
                        }}
                    />
                    <TextField
                        error={emptyFields.includes("lastName")}
                        helperText={emptyFields.includes("lastName") && "Field cannot be empty."}
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        id='lastName'
                        label='Last Name'
                        name='lastName'
                        autoComplete='lname'
                        value={lastName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setLastName(e.target.value);
                        }}
                    />
                    <TextField
                        error={emptyFields.includes("username")}
                        helperText={emptyFields.includes("username") && "Field cannot be empty."}
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        id='email'
                        label='Email'
                        name='email'
                        autoComplete='email'
                        value={username}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setUsername(e.target.value);
                        }}
                    />
                    <TextField
                        error={emptyFields.includes("password")}
                        helperText={
                            invalidPassword !== "" ? invalidPassword : (
                                emptyFields.includes("password") && "Field cannot be empty."
                            )
                        }
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        name='password'
                        label='Password'
                        type='password'
                        id='password'
                        autoComplete='current-password'
                        value={password}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setPassword(e.target.value);
                        }}
                    />
                    <TextField
                        error={emptyFields.includes("confirmPassword")}
                        helperText={
                            noMatchPassword ? "Passwords must match." : (
                                emptyFields.includes("confirmPassword") && "Field cannot be empty."
                            )
                        }
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        name='confirmPassword'
                        label='Confirm Password'
                        type='password'
                        id='confirmPassword'
                        autoComplete='current-password'
                        value={confirmPassword}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfirmPassword(e.target.value);
                        }}
                    />
                    <TextField
                        error={emptyFields.includes("accountNumber")}
                        helperText={
                            emptyFields.includes("accountNumber") && "Field cannot be empty."
                        }
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        id='accountNumber'
                        label='Account #'
                        name='accountNumber'
                        value={accountNumber}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setAccountNumber(e.target.value);
                        }}
                    />
                    <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        style={{ marginTop: 20 }}
                        onClick={submitForm}
                    >
                        Create Account
                    </Button>{" "}
                </>
            }
        </Box>
    );
};

export default CreateAccount;
