import * as React from "react";
//import { experimentalStyled as styled } from '@mui/material/styles';
import { SQW_Log, DebugLevel } from "services/Logging";
import { Button, Flex } from "@aws-amplify/ui-react";
import { Checkbox, Dialog, FormControl, Grid, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { DynamoDBClient, ScanCommand } from "@aws-sdk/client-dynamodb";
import { TextField } from "@aws-amplify/ui-react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useIsMobile } from "helpers/CustomHooks";
import { SelectAllTenants } from "services/GraphQlHelper_New";
import { ddbClient } from "helpers/DynamoDBClient";

import config from "../amplifyconfiguration.json";
import { API_Authenticate, API_GetAPIVersion, Settings } from "../services/GetInvoiceData";
import { getOverrideProps } from "../ui-components/utils";

const akid = config.aws_image_bucket_access_key;
const sak = config.aws_image_bucket_secret_key;

interface UpdateClientProps {
    onSuccess: () => void;
    onCancel: () => void;
}

export default function TenantStatus(props: UpdateClientProps) {
    //const [selectedPage, setSelectedPage] = React.useState("default");
    const [settings, setSettings] = React.useState([] as Settings[]);
    const [addRelationOpen, setAddRelationOpen] = React.useState(false);
    const [setting, setSetting] = React.useState({} as Settings);
    const [fileDialogOpen, setFileDialogOpen] = React.useState(false);
    const [templateDialogOpen, setTemplateDialogOpen] = React.useState(false);
    const [saveURL, setSaveURL] = React.useState(false);
    const isMobile = useIsMobile();
    const [tenants, setTenants] = React.useState([] as any[]);
    const [showTenantHistory, setShowTenantHistory] = React.useState(false);

    function UsersTable() {
        //SQW_Log(DebugLevel.LOG, "Calling UsersTable");
        return (
            <>
                <TableContainer component={Paper}>
                    <Table aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                {!isMobile && (
                                    <TableCell align='left'>
                                        <h5>Tenant Name</h5>
                                    </TableCell>
                                )}
                                <TableCell align='left'>
                                    <h5>Subdomain</h5>
                                </TableCell>
                                <TableCell align='left'>
                                    <h5>API Version</h5>
                                </TableCell>
                                {showTenantHistory && (
                                    <TableCell align='left'>
                                        <h5>Ping Date</h5>
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tenants &&
                                tenants
                                    .sort((a, b) => (a.pingdate < b.pingdate ? 1 : -1))
                                    .map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                            }}
                                        >
                                            {!isMobile && (
                                                <TableCell
                                                    key={"11-" + index}
                                                    component='th'
                                                    scope='row'
                                                >
                                                    {row.name}
                                                </TableCell>
                                            )}
                                            <TableCell
                                                key={"1-" + index}
                                                component='th'
                                                scope='row'
                                            >
                                                {row.tenantid}
                                            </TableCell>
                                            <TableCell
                                                key={"2-" + index}
                                                component='th'
                                                scope='row'
                                            >
                                                {row.version}
                                            </TableCell>
                                            {showTenantHistory && (
                                                <TableCell
                                                    key={"4-" + index}
                                                    component='th'
                                                    scope='row'
                                                >
                                                    {row.pingdate}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }

    async function getTenantStatus(url: string) {
        const token = await API_Authenticate();
        const data = await API_GetAPIVersion(url, token);
        //SQW_Log(DebugLevel.LOG, "Got Version: ", data);
        if (data) return data.Version;
        else return "Error";
    }

    React.useEffect(() => {
        getTenants();
    }, []);

    React.useEffect(() => {
        if (showTenantHistory) getTenantHistory();
        else getTenants();
    }, [showTenantHistory]);

    async function getTenants() {
        const tnts = [];
        //SQW_Log(DebugLevel.LOG, "Selecting tenants...");

        const allTnts = await SelectAllTenants();
        if (allTnts && allTnts.length > 0) {
            for (let i = 0; i < allTnts.length; i++) {
                const version = await getTenantStatus(String(allTnts[i].url ?? ""));
                if (version) {
                    const tdata = {
                        version: version,
                        name: allTnts[i].name,
                        tenantid: allTnts[i].subdomain,
                        pingdate: null,
                    };
                    tnts.push(tdata);
                }
            }
            SQW_Log(DebugLevel.LOG, "Tenant Statuses: ", tnts);
            setTenants(tnts);
        }
    }

    async function getTenantHistory() {
        /*const config = {
            region: "us-east-1",
            credentials: { accessKeyId: akid, secretAccessKey: sak },
        };*/
        const docClient = await ddbClient();
        const input = { TableName: "PingLog" };
        const tenantHist = [];
        const command = new ScanCommand(input);

        const dbResp = docClient ? await docClient.send(command) : undefined;

        if (dbResp) {
            const items = dbResp.Items;

            if (!items) return;

            for (const x of items) {
                //SQW_Log(DebugLevel.LOG, "History: ", x);
                let createTime = x.createdAt.S;
                //SQW_Log(DebugLevel.LOG, "History created: ", createTime);
                if (!createTime) createTime = "";

                if (x.subdomain) {
                    const tdata = {
                        version: x.response.S,
                        name: x.subdomain.S,
                        tenantid: x.subdomain.S,
                        pingdate: createTime,
                    };
                    tenantHist.push(tdata);
                }
            }
            setTenants(tenantHist);
        }
    }

    function showPage() {
        return (
            <>
                <UsersTable />
                <br />
                <br />
                <br />
                <Button
                    children='Go Back'
                    type='reset'
                    onClick={(event) => {
                        event.preventDefault();
                        props.onSuccess();
                    }}
                    {...getOverrideProps(null, "ClearButton")}
                ></Button>
            </>
        );
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Stack direction={"row"}>
                <Checkbox
                    checked={showTenantHistory}
                    onChange={(e: any) => setShowTenantHistory(e.target.checked)}
                />
                <Typography marginTop={"9px"}>Show History</Typography>
            </Stack>
            {showPage()}
        </Box>
    );
}
