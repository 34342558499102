import * as React from "react";
//import { experimentalStyled as styled } from '@mui/material/styles';
import { Button, Flex, Radio, RadioGroupField } from "@aws-amplify/ui-react";
import { SQW_Log, DebugLevel } from "services/Logging";
import {
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { TextField } from "@aws-amplify/ui-react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useIsMobile } from "helpers/CustomHooks";
import UploadFile from "helpers/S3ImageStorage";
import { GreenButton } from "components/Buttons";

import {
    API_Authenticate,
    API_GetSpecialImages,
    API_SaveSpecialImage,
    GetTenantName,
    SpecialImage,
} from "../services/GetInvoiceData";
import { getOverrideProps } from "../ui-components/utils";

/*const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));*/

interface UpdateClientProps {
    onSuccess: () => void;
    onCancel: () => void;
}

export default function UpdateSpecialImages(props: UpdateClientProps) {
    //const [selectedPage, setSelectedPage] = React.useState("default");
    const [specialImages, setSpecialImages] = React.useState([] as SpecialImage[]);
    const [addRelationOpen, setAddRelationOpen] = React.useState(false);
    const [specialImage, setSpecialImage] = React.useState({} as SpecialImage);
    const [fileDialogOpen, setFileDialogOpen] = React.useState(false);
    const [saveSpecialImage, setSaveSpecialImage] = React.useState(false);
    const isMobile = useIsMobile();
    const [actionType, setActionType] = React.useState("Item #");

    function UsersTable() {
        //SQW_Log(DebugLevel.LOG, "Calling UsersTable");
        return (
            <>
                <TableContainer component={Paper}>
                    <Table aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell align='left'>
                                    <h5>URL</h5>
                                </TableCell>
                                <TableCell align='left'>
                                    <h5>Active</h5>
                                </TableCell>
                                <TableCell align='left'>
                                    <h5>Alt Text</h5>
                                </TableCell>
                                <TableCell align='left'>
                                    <h5>Click Action</h5>
                                </TableCell>
                                <TableCell align='left'></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* {clients.sort((a, b) => (a > b ? 1 : -1)).map((row, index) => ( */}
                            {specialImages &&
                                specialImages
                                    .sort((a, b) => (a.norder > b.norder ? 1 : -1))
                                    .map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                            }}
                                        >
                                            <TableCell
                                                key={"111-" + index}
                                                component='th'
                                                scope='row'
                                            >
                                                <img
                                                    key={"12-" + index}
                                                    src={row.cfullfilename}
                                                    alt={row.calt}
                                                    height={100}
                                                />
                                                {/*<a
                                                    href={row.cfullfilename}
                                                    target='_blank'
                                                >
                                                    {row.cfullfilename}
                                        </a>*/}
                                            </TableCell>
                                            <TableCell
                                                key={"1-" + index}
                                                component='th'
                                                scope='row'
                                            >
                                                <Checkbox
                                                    key={"21-" + index}
                                                    id='cb-outlined-basic'
                                                    checked={row.bactive}
                                                />
                                            </TableCell>
                                            <TableCell
                                                key={"11-" + index}
                                                component='th'
                                                scope='row'
                                            >
                                                {row.calt}
                                            </TableCell>
                                            <TableCell
                                                key={"2-" + index}
                                                component='th'
                                                scope='row'
                                            >
                                                {row.ctitle}
                                            </TableCell>
                                            <TableCell
                                                key={"3-" + index}
                                                align='right'
                                            >
                                                <Flex
                                                    key={"4-" + index}
                                                    direction='row'
                                                >
                                                    <GreenButton
                                                        style={{ padding: "30px" }}
                                                        key={"5-" + index}
                                                        onClick={() => {
                                                            setSpecialImage(row);
                                                            setAddRelationOpen(true);
                                                        }}
                                                    >
                                                        Update
                                                    </GreenButton>
                                                </Flex>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button
                    onClick={() => {
                        setSpecialImage({
                            uuid: "",
                            calt: "",
                            cfullfilename: "",
                            bactive: true,
                            norder: specialImages ? specialImages.length + 1 : 1,
                        } as SpecialImage);
                        setAddRelationOpen(true);
                    }}
                >
                    Add New
                </Button>
            </>
        );
    }

    React.useEffect(() => {
        if (saveSpecialImage) {
            onSaveSpecialImage();
            setSaveSpecialImage(false);
        }
    }, [saveSpecialImage]);

    async function onSaveSpecialImage() {
        if (!specialImage) {
            alert("Could not get data.  Please try again.");
            return;
        }
        try {
            const token = await API_Authenticate();
            const ret = await API_SaveSpecialImage(token, specialImage);

            if (ret && ret.IsSuccessful) {
                //SQW_Log(DebugLevel.LOG, "Saved settings: ", ret);
                getSpecialImageData();
            } else {
                if (ret) {
                    alert(ret.Error);
                    SQW_Log(DebugLevel.LOG, "Error when trying to save special image: ", ret);
                    return;
                } else {
                    alert("Unknown error when trying to save special image.");
                    return;
                }
            }
        } catch (err) {
            alert(err);
            return;
        }
        setAddRelationOpen(false);
    }

    /*function onSettingsClose(): void {
        setAddRelationOpen(false);
    }*/

    function setImageURL(url: string) {
        //SQW_Log(DebugLevel.LOG, "url: ", url);

        setSpecialImage({
            ...specialImage,
            cfullfilename: url,
        });
    }

    React.useEffect(() => {
        let aType = "Item #";
        if (specialImage) {
            if (
                specialImage?.ctitle?.startsWith("http://") ||
                specialImage?.ctitle?.startsWith("https://")
            )
                aType = "URL";
            else if (specialImage?.ctitle?.startsWith("Category:")) aType = "Category Name";
        }
        setActionType(aType);
    }, [specialImage]);

    function AddEditScreen() {
        const [openFileDialog, setOpenFileDialog] = React.useState(false);

        return (
            <Dialog open={addRelationOpen}>
                <DialogTitle>
                    {specialImage?.uuid === "" ? "Add Special" : "Update Special"}
                </DialogTitle>
                <DialogContent>
                    <UploadFile
                        setURL={setImageURL}
                        open={openFileDialog}
                        setOpen={setOpenFileDialog}
                        tenantId={GetTenantName()}
                        directory='logos'
                    />
                    <img
                        src={specialImage?.cfullfilename}
                        alt={specialImage?.calt}
                        height={100}
                    />
                    <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Button
                                onClick={() => {
                                    setOpenFileDialog(true);
                                }}
                            >
                                Upload Image
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={5}
                        >
                            <Typography id='modal-modal-title'>Alternate Name</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={7}
                        >
                            <TextField
                                style={{ width: "100%" }}
                                id='outlined-basic'
                                label=''
                                value={specialImage?.calt}
                                onChange={(e) =>
                                    setSpecialImage({
                                        ...specialImage,
                                        calt: (e.target as HTMLInputElement).value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid
                            item
                            xs={5}
                        >
                            <Typography id='modal-modal-title'>Active</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={7}
                        >
                            <Checkbox
                                id='cb-outlined-basic'
                                checked={specialImage.bactive}
                                onClick={(e: any) => {
                                    //SQW_Log(DebugLevel.LOG, "e: ", e.target);
                                    /*SQW_Log(DebugLevel.LOG, 
                                        "e: checked ",
                                        (e.target as HTMLInputElement).checked,
                                    );*/
                                    setSpecialImage({
                                        ...specialImage,
                                        bactive: (e.target as HTMLInputElement).checked,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={5}
                        >
                            <Typography id='modal-modal-title'>Display Order</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={7}
                        >
                            <TextField
                                style={{ width: "100%" }}
                                id='outlined-basic'
                                label=''
                                value={specialImage?.norder}
                                onChange={(e) => {
                                    if (e.target) {
                                        if (isNaN(Number((e.target as HTMLInputElement).value))) {
                                            alert("Order must be a number");
                                            return;
                                        }
                                    }

                                    setSpecialImage({
                                        ...specialImage,
                                        norder: Number((e.target as HTMLInputElement).value),
                                    });
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={5}
                        >
                            <Typography id='modal-modal-title'>Click Action</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <RadioGroupField
                                legend='Action Type'
                                name='actionType'
                                value={actionType}
                                onChange={(e) =>
                                    setActionType((e.target as HTMLInputElement).value)
                                }
                            >
                                <Radio value='Item #'>Item</Radio>
                                <Radio value='Category Name'>Category</Radio>
                                <Radio value='URL'>URL</Radio>
                            </RadioGroupField>
                        </Grid>
                        <Grid
                            item
                            xs={5}
                        >
                            <Typography id='modal-modal-title'>{actionType}</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={7}
                        >
                            <TextField
                                style={{ width: "100%" }}
                                id='outlined-basic'
                                label=''
                                value={specialImage?.ctitle?.replace("Category:", "")}
                                onChange={(e) =>
                                    setSpecialImage({
                                        ...specialImage,
                                        ctitle:
                                            actionType === "Category Name" ?
                                                "Category:" + (e.target as HTMLInputElement).value
                                            :   (e.target as HTMLInputElement).value,
                                    })
                                }
                            />
                        </Grid>
                        <Grid
                            item
                            xs={7}
                        >
                            <Stack
                                direction='row'
                                spacing={2}
                                justifyContent={"space-between"}
                            >
                                <Button
                                    onClick={() => {
                                        setSaveSpecialImage(true);
                                        setAddRelationOpen(false);
                                    }}
                                >
                                    Save
                                </Button>
                                <Button
                                    onClick={() => {
                                        setAddRelationOpen(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }

    async function getSpecialImageData() {
        const token = await API_Authenticate();
        const data = await API_GetSpecialImages(token);
        //SQW_Log(DebugLevel.LOG, "Got Images: ", data);
        if (data) setSpecialImages(data.Images);
    }

    React.useEffect(() => {
        getSpecialImageData();
    }, []);

    function showPage() {
        return (
            <>
                <UsersTable />
                <br />
                <br />
                <Button
                    children='Go Back'
                    type='reset'
                    onClick={(event) => {
                        event.preventDefault();
                        props.onSuccess();
                    }}
                    {...getOverrideProps(null, "ClearButton")}
                ></Button>
            </>
        );

        function editRow(row: any) {
            setSpecialImage(row);
            setAddRelationOpen(true);
        }

        /*  switch(selectedPage)
            {
                case "default":
                    return (
                        <>
                        <UsersTable />
                        <br/><br/>
                        <Button
                            children="Save All Settings"
                            type="submit"
                            onClick={(event) => {
                                event.preventDefault();
                                onSaveAllSettings();
                            }}
                            {...getOverrideProps(null, "ClearButton")}
                            ></Button>
                        <br/><br/>
                        <Button
                            children="Go Back"
                            type="reset"
                            onClick={(event) => {
                                event.preventDefault();
                                props.onSuccess();
                            }}
                            {...getOverrideProps(null, "ClearButton")}
                            ></Button>
                        </>
                    );
                default:
                    return (
                        <div>
                            <h3>{selectedPage}</h3>
                            
                        </div>
                    );
            }*/
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            {showPage()}
            {AddEditScreen()}
        </Box>
    );
}
