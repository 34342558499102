import { Box, Stack } from "@mui/material";
import { SQW_Log, DebugLevel } from "services/Logging";
import * as React from "react";
import { Customer, WebInventoryItem } from "interfaces/InvoiceInterfaces";

import { API_Authenticate, API_GetSuggestedItems } from "../services/GetInvoiceData";
import ProductCard2 from "./Item/ProductCard";

type Props = {
    citemno: string;
    customer: Customer;
    handleAddToCart: (clickedItem: WebInventoryItem) => void;
    handleItemDisplay: (clickedItem: WebInventoryItem) => void;
};

const SuggestedItems: React.FC<Props> = ({
    citemno,
    customer,
    handleAddToCart,
    handleItemDisplay,
}) => {
    const [sugItems, setSugItems] = React.useState([] as WebInventoryItem[]);

    React.useEffect(() => {
        const fetchData = async () => {
            //SQW_Log(DebugLevel.LOG, "Getting suggested items.");
            const token = await API_Authenticate();
            const sItems = await API_GetSuggestedItems(
                token,
                citemno,
                customer.ccustno,
                customer.cscono,
                customer.cschedid,
            );
            SQW_Log(DebugLevel.LOG, "Suggested sItems: ", sItems.Items);

            if (sItems?.Items && sItems.Items.length > 0) {
                //Remove duplicates from the suggested items
                const uniqueItems = [] as WebInventoryItem[];

                sItems.Items.forEach((item: WebInventoryItem) => {
                    if (!uniqueItems.find((x) => x.citemno === item.citemno)) {
                        uniqueItems.push(item);
                    }
                });
                SQW_Log(DebugLevel.LOG, "Unique Items: ", uniqueItems);
                if (uniqueItems.length > 0) {
                    setSugItems(uniqueItems);
                }
            }
        };

        fetchData();
    }, [citemno, customer]);

    return (
        <>
            {/*<Grid container spacing={1}>*/}

            {sugItems && sugItems.length > 0 ?
                <>
                    <h3> Suggested Items</h3>
                    <Stack alignItems='center'>
                        <Box
                            m={2}
                            display='flex'
                            justifyContent='center'
                            sx={{ flexWrap: "wrap", alignItems: "center" }}
                        >
                            {sugItems?.map((item) => (
                                <div
                                    key={"dv_" + item.citemno}
                                    style={{ padding: "10px" }}
                                >
                                    <ProductCard2
                                        key={"pc_" + item.citemno}
                                        item={item}
                                        handleAddToCart={handleAddToCart}
                                        handleItemDisplay={handleItemDisplay}
                                    />
                                </div>
                            ))}
                        </Box>
                    </Stack>
                </>
            :   null}
        </>
    );
};

export default SuggestedItems;
