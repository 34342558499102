import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import theme from "theme/theme";
import { Box, styled } from "@mui/system";
import { getMainAppSize } from "services/GetInvoiceData";
import { SQW_Log, DebugLevel } from "services/Logging";

const CustomBoxBlock = styled(Box)(({ theme }) => ({
    margin: "auto",
    display: "block",
    backgroundColor: "#F6F7F9",
    minHeight: `${getMainAppSize()}px`,
    [theme.breakpoints.down("sm")]: {
        maxWidth: "100vw",
        //marginTop: theme.spacing(2),
    },
    [theme.breakpoints.between("md", "lg")]: {
        maxWidth: "1080px",
        //marginTop: theme.spacing(6),
    },
    [theme.breakpoints.up("lg")]: {
        maxWidth: "1440px",
        //marginTop: theme.spacing(6),
    },
}));

const CustomBoxFlexOuter = styled(Box)(({ theme }) => ({
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F6F7F9",
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
        maxWidth: "100vw",
        overflowX: "hidden",
        //marginTop: theme.spacing(2),
    },
    [theme.breakpoints.between("md", "lg")]: {
        maxWidth: "1080px",
        //marginTop: theme.spacing(6),
    },
    [theme.breakpoints.up("lg")]: {
        maxWidth: "1440px",
        //marginTop: theme.spacing(6),
    },
}));

const CustomBoxFlexInner = styled(Box)(({ theme }) => ({
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F6F7F9",
    overflow: "hidden",

    [theme.breakpoints.down("sm")]: {
        maxWidth: "100vw",
        overflowX: "hidden",
        //marginTop: theme.spacing(2),
    },
    [theme.breakpoints.between("md", "lg")]: {
        maxWidth: "1080px",
        //marginTop: theme.spacing(6),
    },
    [theme.breakpoints.up("lg")]: {
        maxWidth: "1440px",
        //marginTop: theme.spacing(6),
    },
}));

const CustomBox: React.FC<{
    children: ReactNode | ReactNode[];
    setHeight: (height: number) => void;
}> = ({ children, setHeight }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        //initially set the height to 0 until useLayoutEffect can calculate the height
        setHeight(0);
    }, []);

    useLayoutEffect(() => {
        try {
            SQW_Log(
                DebugLevel.ERROR_WITH_ERROR_TYPE,
                "CustomBox: useLayoutEffect: ",
                ref.current?.clientHeight,
            );
            if (ref.current && setHeight) {
                setHeight(ref.current.clientHeight);
            }
        } catch (exception) {
            SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "CustomBox: useLayoutEffect: ", exception);
        }
    }, [children]);

    return (
        <>
            <CustomBoxFlexOuter ref={ref}>
                <CustomBoxBlock>
                    <CustomBoxFlexInner>{children}</CustomBoxFlexInner>
                </CustomBoxBlock>
            </CustomBoxFlexOuter>
        </>
    );
};

export default CustomBox;
