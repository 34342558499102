// File: src/services/GetInvoiceData.tsx

import { fetchAuthSession } from "aws-amplify/auth";
import { SQW_Log, DebugLevel } from "services/Logging";
import { globalTenant, globalTenantInfo } from "helpers/Globals";
import { Buffer } from "buffer";

import { CCProfile, ScheduleAddress } from "../interfaces/InvoiceInterfaces";
import { SelectHubsByTenantID, SelectTenant } from "./GraphQlHelper_New";
import config from "../amplifyconfiguration.json";
import { GetUserName, PersistantObjectType, saveStoredItems, storeItem } from "./PersistantObjects";
import { jwtDecode } from "jwt-decode";
import { CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
//import { globalTenant, globalTenantInfo, globalUser } from "helpers/Globals";

// import crypto from "crypto";
//import { GetAuthToken, GetSettings } from "./PersistantObjects";

//var Buffer = require('buffer').Buffer

export let headerSize = 80;
export let footerSize = 80;

export function setHeaderSize(size: number) {
    headerSize = size;
}
export function setFooterSize(size: number) {
    footerSize = size;
}

export function getMainAppSize() {
    return window.innerHeight - headerSize - footerSize - 35;
}

/*SettingsExpirationMinutes is the amount of time in minutes that the settings are stored.  After that time it will reload the settings.*/
const SettingsExpirationMinutes = 60;
const TokenExpirationMinutes = 10;

let url = "";
let authuserid = "";
let authKey = "";
const tenant = "";
const username = "";
let companyname = "";
let companylogo = ""; //"https://www.prismvs.com/hubfs/raw_assets/public/Prism_Visual_Software_September2021/images/prism-visual-software-logo.svg";
let companySideImage = "";
let parsedToken: any;
const cognitoauthtoken: string | null = "";
const defaultLogo = "https://prism-web-images.s3.amazonaws.com/logos/prism.jpg";
const defaultSideImage = "https://prism-web-images.s3.amazonaws.com/login_images/prism.jpg";

/*These should remain constant*/
const ENDPOINT_Auth = "api/Prism/Authenticate";
const ENDPOINT_OpenInv = "api/Prism/V1/Invoices/GetOpenInvoices";
const ENDPOINT_PayMethods = "api/Prism/V1/Payment/GetPaymentProfiles";
const ENDPOINT_SetPONum = "api/Prism/V1/Invoices/UpdatePONumber";
const ENDPOINT_PayInvoices = "api/Prism/V1/Payment/PayInvoices";
const ENDPOINT_GetCustomerCredits = "api/Prism/V1/Customer/GetCustomerCredits";
const ENDPOINT_SaveCCProfile = "api/Prism/V1/Payment/SaveCCProfile";
const ENDPOINT_GetScheduleAddress = "api/Prism/V1/Customer/GetScheduleAddresses";
const ENDPOINT_GetSurcharge = "api/Prism/V1/Payment/GetInvoiceSurcharge";
const ENDPOINT_AddItemsToSchedule = "api/Prism/Tickets/AddItemsToSchedule";
const ENDPOINT_CreateTicket = "api/Prism/Tickets/CreateTicket";
const ENDPOINT_GetSuggestedItems = "api/Prism/V1/Inventory/GetSuggestedItems";
const ENDPOINT_GetScheduleHeader = "api/Prism/V1/Schedules/GetScheduleHeader";
const ENDPOINT_GetScheduleCaseCount = "api/Prism/V1/Schedules/GetScheduleCaseCount";
const ENDPOINT_GetInvoiceReport = "api/Prism/V1/Invoices/GetInvoiceReport";
const ENDPOINT_GetUserInvoices = "api/Prism/V1/Invoices/GetUserInvoices";
const ENDPOINT_GetTicketReport = "api/Prism/V1/Tickets/GetTicketReport";
const ENDPOINT_GetUserTickets = "api/Prism/V1/Tickets/GetUserOrders";
const ENDPOINT_CreateServiceTicket = "api/Prism/V1/Tickets/CreateServiceTicket";
const ENDPOINT_GetWebCategories = "api/Prism/V1/Inventory/GetWebCategories";
const ENDPOINT_GetWebItems = "api/Prism/V1/Inventory/GetWebItems";
const ENDPOINT_GetAllWebItems = "api/Prism/V1/Inventory/GetAllWebItems";
const ENDPOINT_GetLogoURL = "api/Prism/V1/GetLogoURL";
const ENDPOINT_GetVersionNumber = "api/Prism/V1/GetVersionNumber";
const ENDPOINT_RegisterNewUser = "api/Prism/V1/Customer/RegisterNewUser";
const ENDPOINT_RemoveCustomerFromUser = "api/Prism/V1/Customer/RemoveCustomerFromUser";
const ENDPOINT_GetCustomers = "api/Prism/V1/Customer/GetUserLinkedCustomers";
const ENDPOINT_GetSettings = "api/Prism/V1/GetSettings";
const ENDPOINT_SaveSettings = "api/Prism/V1/SaveSettings";
const ENDPOINT_GetNextDeliveryDate = "api/Prism/V1/Schedules/GetNextDeliveryDate";
const ENDPOINT_GetUsersHubs = "api/Prism/V1/Customer/GetUsersHubs";
const ENDPOINT_SetDefaultCCProfile = "api/Prism/V1/Payment/SetDefaultCCProfile";
const ENDPOINT_DeleteCCProfile = "api/Prism/V1/Payment/DeleteCCProfile";
const ENDPOINT_GetSpecialImages = "api/Prism/V1/Inventory/GetSpecialImages";
const ENDPOINT_GetCustomerCheckoutInfo = "api/Prism/V1/Customer/GetCustomerCheckoutInfo";
const ENDPOINT_SaveSpecialImage = "api/Prism/V1/Inventory/UpdateInsertSpecialImage";
//const ENDPOINT_PayByCreditCard = "api/Prism/V1/Payment/PayByCreditCard";

const akid = config.aws_image_bucket_access_key;
const sak = config.aws_image_bucket_secret_key;

export type Settings = {
    name: string;
    value: string;
    type?: string;
    label?: string;
    truevalue?: string;
    falsevalue?: string;
};
export const defaultSettings: Settings[] = [
    {
        name: "SendEmailConfirmationsToCustomers",
        value: "1",
        type: "checkbox",
        label: "Send Email Confirmations To Customers",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "SendEmailConfirmationsToClient",
        value: "1",
        type: "checkbox",
        label: "Send Email Confirmations To Client",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "EmailConfirmationsToClientAddress",
        value: "",
        type: "textbox",
        label: "Client Email Address for Confirmations",
    },
    {
        name: "showviewopeninvoices",
        value: "1",
        type: "checkbox",
        label: "Show Web Bill Pay",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "showmainstore",
        value: "1",
        type: "checkbox",
        label: "Show Web Store",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "showmodifycreditcards",
        value: "1",
        type: "checkbox",
        label: "Show Modify Credit Cards",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "showdashboardpage",
        value: "1",
        type: "checkbox",
        label: "Show Dashboard",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "showinvoicereport",
        value: "1",
        type: "checkbox",
        label: "Show Invoice Report",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "showticketreport",
        value: "1",
        type: "checkbox",
        label: "Show Web Store",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "showservicerequests",
        value: "1",
        type: "checkbox",
        label: "Show Service Requests",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "showreports",
        value: "1",
        type: "checkbox",
        label: "Show Service Requests",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "ShowPrices",
        value: "1",
        type: "checkbox",
        label: "Show Prices",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "WebsiteLogo",
        value: "",
        type: "textbox",
        label: "Webstore Main Logo",
    },
    {
        name: "AllowWebstorePaymentByCreditCard",
        value: "0",
        type: "checkbox",
        label: "Allow customers to place orders using credit card.",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "ShowNextDeliveryDate",
        value: "0",
        type: "checkbox",
        label: "Show Next Delivery Date on web store.",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "defaultpage",
        value: "mainstore",
        type: "textbox",
        label: "Default Landing Page (mainstore, viewopeninvoices or dashboardpage)",
    },
    {
        name: "ShowAllItemsForEntireCatalog",
        value: "0",
        type: "checkbox",
        label: "Show All items for entire catalog, regardless of what is on schedule.",
        truevalue: "1",
        falsevalue: "0",
    },
    {
        name: "TenantID",
        value: "",
        type: "textbox",
        label: "The Tenant ID for this database.",
    },
    {
        name: "enableDataDogMonitoring",
        value: "false",
        type: "checkbox",
        label: "Enable Logging",
        truevalue: "true",
        falsevalue: "false",
    },
    {
        name: "companyconfirmationemaildonotsend",
        value: "false",
        type: "checkbox",
        label: "Do not send email confirmation",
        truevalue: "true",
        falsevalue: "false",
    },
    {
        name: "companyconfirmationemailurl",
        value: "",
        type: "textbox",
        label: "The URL of the confirmation email template for orders.  Leave blank if no customization needed.",
    },
    {
        name: "companyconfirmationemailsubject",
        value: "",
        type: "textbox",
        label: "The subject line for the confirmation email.  Leave blank for subject of Order Confirmation.",
    },
    {
        name: "companyconfirmationemailaddress",
        value: "",
        type: "textbox",
        label: "The email address confirmation emails should come from.",
    },
    {
        name: "companyconfirmationdisplayemailaddress",
        value: "",
        type: "textbox",
        label: "The email address that should appear on confirmation emails.",
    },
    {
        name: "companyconfirmationphone",
        value: "",
        type: "textbox",
        label: "The phone number displayed on confirmation emails.",
    },
    {
        name: "companyconfirmationname",
        value: "",
        type: "textbox",
        label: "The company name displayed on confirmation emails.",
    },
    {
        name: "companyconfirmationemailserviceurl",
        value: "",
        type: "textbox",
        label: "The URL of the confirmation email template for services.  Leave blank if no customization needed.",
    },
];
/*
function getCookie(name: string): string | null {
    //SQW_Log(DebugLevel.LOG, "Get Cookie Called.");
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i=0; i < ca.length; i++) {
        let c = ca[i];
        //SQW_Log(DebugLevel.LOG, c);
        let ind = c.indexOf(nameEQ);
        if(ind >= 0){
            c = c.substring(ind, c.length);
            //SQW_Log(DebugLevel.LOG, "substring val: " + c);
            //while (c.indexOf(nameEQ) !== 0 && c.length>nameEQ.length) c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}*/

/*
function getCookie2(name: string): string | null {
    //SQW_Log(DebugLevel.LOG, "Get Cookie Called.");
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i=0; i < ca.length; i++) {
        let c = ca[i];
        //SQW_Log(DebugLevel.LOG, c);
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
*/

export async function getSecrets2() {
    /*if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        await setAWSTokens();
        //SQW_Log(DebugLevel.LOG, "getSecrets2: AccessToken: ", cognitoauthtoken);
    }
    else
    {
        //SQW_Log(DebugLevel.LOG, "Production: asl;dfkj;laskjdf;laskjdf;laskjdf;lasdkjf;laksjdf;lkajsdf;lksajd;lkjlsdkfjlkdjflknvlkdnslkfjlsakjdflksajdf;lkasjdf;lkajf;lkasnv;lkvn;laskjd;lsakdj;laskdj;laskdj;lasdkjf;laskdjf;lasdkjf;lasdjf;lasdfjk");
        tokenID = getCookie("idToken");
        cognitoauthtoken = getCookie("accessToken");
        //SQW_Log(DebugLevel.LOG, "atk: " + cognitoauthtoken);
        parsedToken = parseJwt(tokenID);

        tenant = parsedToken["custom:TenantId"];
        companyname = parsedToken["custom:Company"];
        cognitoclientid = parsedToken["aud"];
        cognitodomain = "test-prismvs.auth.us-east-1.amazoncognito.com";
        username = parsedToken.email;
        localStorage.setItem('user', username);
    
    }*/
    const tenantObj = await SelectTenant(getTenantNameFromSubdomain() ?? "");
    await fetchSecrets();
    await setAWSTokens();

    if (tenantObj && tenantObj.length > 0) {
        url = tenantObj[0].url;
        //SetSettings();
        //storeSettings();
    }

    //SQW_Log(DebugLevel.LOG, "Tenant ID: " + tenant);
    //SQW_Log(DebugLevel.LOG, "User: " + username);

    /*const secrets = await getSecretValue("TenantID_" + tenant);
    const jsonSecrets = JSON.parse(secrets);
    url = jsonSecrets?.URL;
    if(!url.endsWith("/"))
        url = url + "/";
    authuserid = jsonSecrets?.User;
    authKey = jsonSecrets?.AuthKey;*/

    //SQW_Log(DebugLevel.LOG, "URL: " + url);
    //SQW_Log(DebugLevel.LOG, "User: " + authuserid);
    //SQW_Log(DebugLevel.LOG, "AuthKey: " + authKey);
    //SQW_Log(DebugLevel.LOG, "username: " + username);
    //SetLogoURL();
}

async function getHubData(tenantData: any) {
    const hub = localStorage.getItem("sqw_hub");
    //SQW_Log(DebugLevel.LOG, "getHubData: hub: " + hub);
    if (!hub || hub === "" || !tenantData.usesHubs) {
        SQW_Log(DebugLevel.LOG, "Hub not found from localStorage or hubs is turned off: ");
        return tenantData;
    }

    const hubs = await SelectHubsByTenantID(tenantData.subdomain);
    if (!hubs) {
        SQW_Log(DebugLevel.LOG, "Hubs not found: " + hub);
        return tenantData;
    }
    const hubData = hubs.find((x) => x.chubno.trim().toLowerCase() === hub?.trim().toLowerCase());
    if (!hubData) {
        SQW_Log(DebugLevel.LOG, "Hub not found: " + hub);
        return tenantData;
    }

    tenantData.address1 = hubData.address1;
    tenantData.address2 = hubData.address2;
    tenantData.city = hubData.city;
    tenantData.state = hubData.state;
    tenantData.zip = hubData.zip;
    tenantData.companyname = hubData.companyname;
    tenantData.email = hubData.email;
    tenantData.phone = hubData.phone;
    if (hubData.logo !== "") tenantData.logo = hubData.logo;

    //SQW_Log(DebugLevel.LOG, "hub:  tenantData: ", tenantData);
    return tenantData;
}

/*export async function GetTenantInfoFromName(tenantName: string) {
    let tenantInfo: TenantInfo = {
        id: "",
        subdomain: "",
        usesHubs: false,
        name: "",
        url: "",
        uniqueid: "",
        createdby: "",
        logo: null,
        loginsideimage: null,
        companyname: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        phone: null,
        email: null,
        hubs: null,
        users: null,
        linkedclient: null,
        createdAt: "",
        updatedAt: "",
    };

    /*var hubStrg = localStorage.getItem("hub");
    const hub = hubStrg ? hubStrg : "";
    try {
        if (tenantData && tenantData.find((x) => x.subdomain === tenantName && x.hub === hub)) {
            var tnt = tenantData.find((x) => x.subdomain === tenantName && x.hub === hub);
            if (tnt && new Date().getUTCHours() - tnt?.date.getUTCHours() > 1) tenantData = [];
            else {
                //SQW_Log(DebugLevel.LOG, "GetTenantInfoFromName: Tenant found in cache: ", tnt?.data);
                localStorage.setItem("tenant", JSON.stringify(tnt?.data));
                localStorage.setItem("tenantURL", tnt?.data?.url);
                isConnectedToTenant = true;
                return tnt?.data;
            }
        }

        const td = localStorage.getItem("tenantDate");

        if (td && new Date().getUTCHours() - new Date(td).getUTCHours() <= 1) {
            const tntd = localStorage.getItem("tenant");
            const tntdo = JSON.parse(tntd!);
            tenantData?.push({
                subdomain: tntdo.subdomain,
                hub: hub,
                date: new Date(),
                data: tntdo,
            });
            //SQW_Log(DebugLevel.LOG, "GetTenantInfoFromName: Tenant found in localStorage: ", tntdo);
            isConnectedToTenant = true;
            return tntdo;
        }
    } catch (err) {
        SQW_Log(DebugLevel.LOG, "GetTenantInfoFromName: Error: ", err);
    }

    //IdentityImports.Amplify.configure(IdentityConfigs.amplifyConfig);
    try {
        try {
            if (!globalAuthContext || globalAuthContext?.isLoading) {
                return undefined;
            }
        } catch (err2) {
            return undefined;
        }

        const res = await SelectTenant(tenantName);
        //SQW_Log(DebugLevel.LOG, "GetTenantInfoFromName: Tenant= ", res);
        localStorage.setItem("tenantURL", "");
        localStorage.setItem("tenant", "");
        if (!res || res.length === 0 || res[0].subdomain !== tenantName) {
            SQW_Log(DebugLevel.LOG, "Tenant name " + tenantName + " not found.");
        } else {
            var tn = res[0];
            //SQW_Log(DebugLevel.LOG, "Tenant from dynamo: ", tn);
            tn = await getHubData(tn);
            tenantData?.push({ subdomain: tn.subdomain, date: new Date(), data: tn, hub: hub });
            localStorage.setItem("tenant", JSON.stringify(tn));
            localStorage.setItem("tenantDate", new Date().toDateString());
            localStorage.setItem("tenantURL", tn.url);
            //SQW_Log(DebugLevel.LOG, "Set tenant");
            isConnectedToTenant = true;
            return tn;
        }
    } catch (err) {
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "Error geting tenant info for ", tenantName);
    }
    return tenantInfo;
}*/

interface API_TOKEN {
    Token: string;
    dcreate: string;
}

let apiToken = {} as API_TOKEN;

export async function SetAuthToken() {
    let token = await API_Authenticate_GetToken();
    if (!token) {
        throw new Error("Could not authenticate user.");
    }
    token = token.replaceAll('"', "");
    SQW_Log(DebugLevel.LOG, "SetAuthToken: Token", token);
    apiToken = { Token: token, dcreate: new Date().toISOString() };
    //var encryptedData = await encryptString(token);
    //var authtoken = JSON.stringify({ Token: encryptedData, dcreate: new Date().toISOString() });
    //SQW_Log(DebugLevel.LOG, "SetAuthToken: Encrypted Data", authtoken);
    //localStorage.setItem("sqw_authtoken", authtoken);

    return token;
}

export const encryptString = async (data: string): Promise<string> => {
    try {
        const encryptedData = await aesEncrypt(data);
        return JSON.stringify(encryptedData);
    } catch (err) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error encrypting: ", err);
    }
    return "";
};
export async function GetAuthToken() {
    if (apiToken && apiToken.Token?.length > 0) {
        if (
            apiToken.dcreate &&
            new Date().getTime() - new Date(apiToken.dcreate).getTime() <
                TokenExpirationMinutes * 60000
        ) {
            return apiToken.Token;
        }
    }
    return SetAuthToken();
}

export const decryptString = async (data: string): Promise<string> => {
    try {
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "J116: Decrypt Data: 1", data);
        const encryptedObject = JSON.parse(data);

        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "J116: Decrypt Data: 2", encryptedObject);
        const decryptedData = await aesDecrypt(encryptedObject);
        return decryptedData;
    } catch (err) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error decrypting: ", err);
        throw new Error("Error decrypting: " + err);
    }
    return "";
};

export async function GetAuthTokenOld() {
    try {
        var storedData = localStorage.getItem("sqw_authtoken");
        SQW_Log(DebugLevel.LOG, "GetAuthToken: Stored Data", storedData);
        if (storedData && storedData !== null && storedData.length > 0) {
            SQW_Log(DebugLevel.LOG, "Token: Stored Data about to parse", storedData);
            const parsedData = JSON.parse(storedData);
            if (parsedData?.dcreate) {
                const dcreate = new Date(parsedData.dcreate);
                const now = new Date();
                const diff = now.getTime() - dcreate.getTime();
                const diffMins = diff / (1000 * 60);
                if (diffMins > TokenExpirationMinutes) {
                    SQW_Log(DebugLevel.LOG, "GetAuthToken: Token has expired.");
                    return SetAuthToken();
                } else {
                    storedData = JSON.stringify(parsedData.Token);
                    SQW_Log(DebugLevel.LOG, "GetAuthToken: Valid Token found enc.", storedData);
                    try {
                        const token = await decryptString(storedData);
                        SQW_Log(DebugLevel.LOG, "GetAuthToken: Token found dec.", token);
                        return token;
                    } catch (err) {
                        SQW_Log(DebugLevel.LOG, "GetAuthToken: Error decrypting token: ", err);
                        localStorage.removeItem("sqw_authtoken");
                        return SetAuthToken();
                    }
                }
            }
        }

        return SetAuthToken();
    } catch (err) {
        SQW_Log(DebugLevel.LOG, "GetAuthToken: Error getting AuthToken: ", err);
        storedData = null;
    }
    //Try to get auth token from api one more time after exception
    try {
        return SetAuthToken();
    } catch (err) {
        SQW_Log(DebugLevel.LOG, "GetAuthToken: Error getting AuthToken again: ", err);
        return "";
    }
}

export async function GotoTenantURL(tenantid: string) {
    const host = window.location.host;
    const urlsplit = host.toLowerCase().split(".");

    if (urlsplit[0].toLowerCase() === tenantid.toLowerCase()) return;

    let skipDomain = 0;
    if (urlsplit[0] !== "localhost" && urlsplit[0] !== "127.0.0.0" && urlsplit.length > 2) {
        skipDomain = 1;
    }
    let url = window.location.protocol + "//" + tenantid;
    for (let int = skipDomain; int < urlsplit.length; int++) {
        url += "." + urlsplit[int];
    }
    window.location.href = url;
    return url;
}
/*export async function GetTenantInfo() {
    return await GetTenant();
}*/

/*export async function GetTenantInfo() {
    const host = window.location.host;
    const subdomain = host.split(".")[0];

    //SQW_Log(DebugLevel.LOG, "host", host);
    //SQW_Log(DebugLevel.LOG, "subdomain", subdomain);
    const tenantName = subdomain;

    return await GetTenantInfoFromName(tenantName);
}

var isConnectedToTenant = true;

function SetConnectedToTenant() {
    try {
        GetTenantInfo()
            .then((tenantInfo) => {
                if (tenantInfo) {
                    //SQW_Log(DebugLevel.LOG, "SetConnectedToTenant: tenantInfo: ", tenantInfo);
                    isConnectedToTenant = true;
                } else isConnectedToTenant = false;
            })
            .catch((err) => {
                SQW_Log(DebugLevel.LOG, "Error setting connected to tenant2: ", err);
            });
    } catch (err) {
        SQW_Log(DebugLevel.LOG, "Error setting connected to tenant: ", err);
    }
}
SetConnectedToTenant();

export function IsConnectedToTenant() {
    return isConnectedToTenant;
}*/

/*
function parseJwt (token: string | null) {
    if(token === null)
    {
        //SQW_Log(DebugLevel.LOG, "idToken is null.");
        return "";
    }

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    //SQW_Log(DebugLevel.LOG, jsonPayload);

    return JSON.parse(jsonPayload);
}*/

/*
export async function GetSettings_Old() {
    try {
        var parsedData = null;

        var storedData = localStorage.getItem("settings");
        if (storedData && storedData !== null && storedData.length > 0) {
            //SQW_Log(DebugLevel.LOG, "GetSettings: Stored Data about to parse", storedData);
            parsedData = JSON.parse(storedData.toString());
            if (parsedData?.tenant && parsedData?.dcreate) {
                if (parsedData.tenant !== GetTenantName()) {
                    //SQW_Log(DebugLevel.LOG, "GetSettings: Tenant name mismatch.");
                    parsedData = null;
                } else {
                    var dcreate = new Date(parsedData.dcreate);
                    var now = new Date();
                    var diff = now.getTime() - dcreate.getTime();
                    var diffMins = diff / (1000 * 60);
                    if (diffMins > SettingsExpirationMinutes) {
                        //SQW_Log(DebugLevel.LOG, "GetSettings: Settings expired.");
                        parsedData = null;
                    } else {
                        var decryptedData = decrypt(parsedData.Settings.toString());
                        parsedData = JSON.parse(decryptedData);
                        //SQW_Log(DebugLevel.LOG, "GetSettings: Stored Settings found.", parsedData);
                    }
                }
            } else {
                //SQW_Log(DebugLevel.LOG, "GetSettings: Stored Settings not found.  Calling API.");
                parsedData = null;
            }
        }
        if (!parsedData || parsedData === null) {
            //SQW_Log(DebugLevel.LOG, "GetSettings: No stored settings found.  Calling API.");
            parsedData = await SetSettings_Old();
        }

        if (!parsedData || parsedData === null) {
            //SQW_Log(DebugLevel.LOG, "GetSettings: No settings found. Using default settings.");
            return defaultSettings;
        }
        //SQW_Log(DebugLevel.LOG, "GetSettings: Parsed Data", parsedData);
        return parsedData;
    } catch (err) {
        SQW_Log(DebugLevel.LOG, "GetSettings: Error parsing settings: ", err);
        storedData = null;
    }
    return defaultSettings;
}

export async function SetSettings_Old() {
    var token = await API_Authenticate();
    var settings = await API_GetSettings(token);
    //SQW_Log(DebugLevel.LOG, "SetSettings: Settings", settings);
    if (!settings) {
        return defaultSettings;
    }
    var newJSON = FormatSettings(settings.Settings);
    var jsonString = JSON.stringify(newJSON);
    var encryptedData = encrypt(jsonString); ///Haven't been able to get encryption to work
    localStorage.setItem(
        "settings",
        JSON.stringify({ Settings: encryptedData, dcreate: new Date(), tenant: GetTenantName() }),
    );
    //SQW_Log(DebugLevel.LOG, "SetSettings: Encrypted Data", encryptedData);
    return newJSON;
}
*/

export function getSelectedHub() {
    const hub = localStorage.getItem("sqw_hub");
    if (hub) {
        return hub;
    }

    return "";
}

export async function getUserGroups(): Promise<string[]> {
    const groupsret: string[] = [];
    try {
        const res = await fetchAuthSession();
        if (!res) {
            //SQW_Log(DebugLevel.LOG, "Could not fetch auth session.");
            return [];
        }
        const groups = res.tokens?.accessToken.payload["cognito:groups"];
        //SQW_Log(DebugLevel.LOG, "myGroups:", groups);

        if (groups) {
            return groups as string[];
            /*JSON.parse(groups.toString()).forEach((group: string) => {
                groupsret.push(group);
            })*/
        }
    } catch (err) {
        SQW_Log(DebugLevel.LOG, "Error getting groups: ", err);
    }

    return groupsret;
}

function toBuffer(input: any, encoding: BufferEncoding = "utf8") {
    if (typeof input === "string") {
        return Buffer.from(input, encoding);
    } else if (Buffer.isBuffer(input)) {
        return input;
    } else {
        throw new Error("Unsupported input type");
    }
}

/*export function encryptOld(text: string) {
    let cipher = crypto.createCipheriv(algorithm, key, iv);
    let encrypted = cipher.update(text, "utf8", "hex");
    encrypted += cipher.final("hex");
    return encrypted;
}*/

export function getInt(value: any) {
    if (value === null || value === undefined) return 0;
    if (typeof value === "number") return value;
    if (typeof value === "string") return parseInt(value);
    return 0;
}

const maxItemsToLoadPerApiCall = 500000;

export const getAPIAllProducts = async (callingProc: string) => {
    try {
        /*if (!customer || !customer.ccustno || customer.ccustno === "") {
            SQW_Log(DebugLevel.LOG, "746: getAPIProducts customer is null or empty");
            return;
        }*/

        const token = await API_Authenticate();
        /*const apiItems = await API_GetWebItems(
            token,
            GetUserName(),
            customer.ccustno,
            customer.cscono,
            customer.cinvno,
            ["ALL"], //selectedCategory || ["ALL"],
            "0",
            1,
            maxItemsToLoadPerApiCall,
            "",
            [], //cartItems.map((item: any) => item.citemno),
            "",
        );*/
        const apiItems = await API_GetAllWebItems(token, GetUserName());
        SQW_Log(DebugLevel.LOG, "getAPIAllProducts: ", apiItems);
        return apiItems;
    } catch (error) {
        SQW_Log(DebugLevel.LOG, "Error loading items: ", error);
    }
    return [];
};

interface TokenPayload {
    exp: number; // Expiration time in seconds since the epoch
}

function decodeToken(token: string): TokenPayload {
    if (token && token.length > 0) {
        try {
            return jwtDecode<TokenPayload>(token);
        } catch (error) {
            SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error decoding token:", error);
        }
    }
    return { exp: 0 };
}

export const hasTokenExpired = (token: string): boolean => {
    const decoded = decodeToken(token);
    const tokenExpired = decoded.exp < Date.now() / 1000;
    return tokenExpired;
};

export const userPool = new CognitoUserPool({
    UserPoolId: config.aws_user_pools_id,
    ClientId: config.aws_user_pools_web_client_id,
});

export async function UpdateTokens() {
    const session = await fetchAuthSessionCognito();
    if (session) {
        const idToken = session.getIdToken().getJwtToken();
        const accessToken = session.getAccessToken().getJwtToken();
        //const accessToken = session.tokens?.accessToken;
        storeItem(PersistantObjectType.ID_TOKEN, idToken);
        storeItem(PersistantObjectType.ACCESS_TOKEN, accessToken);
        saveStoredItems();
        return idToken;
    }
    return null;
}

export const fetchAuthSessionCognito = async () => {
    const user = userPool.getCurrentUser();
    if (user) {
        return new Promise<CognitoUserSession | null>((resolve, reject) => {
            user.getSession(
                (
                    err: any,
                    session: CognitoUserSession | PromiseLike<CognitoUserSession | null> | null,
                ) => {
                    if (err) {
                        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error getting session:", err);
                        reject(err);
                    } else {
                        resolve(session);
                    }
                },
            );
        });
    }
    return null;
};

/*export const getRemainingAPIProducts = async (
    callingProc: string,
    customer: ScheduleAddress,
    loadedItems: any,
    totalPages: number,
    key: string,
) => {
    let pageNumber = 2;
    try {
        if (!customer || !customer.ccustno || customer.ccustno === "") {
            SQW_Log(DebugLevel.LOG, "746: getRemainingAPIProducts customer is null or empty");
            return;
        }

        //setIsLoading(true);
        while (pageNumber <= totalPages) {
            const token = await API_Authenticate();
            const apiItems = await API_GetWebItems(
                token,
                GetUserName(),
                customer.ccustno,
                customer.cscono,
                customer.cinvno,
                ["ALL"], //selectedCategory || ["ALL"],
                "0",
                pageNumber,
                maxItemsToLoadPerApiCall,
                "",
                [], //cartItems.map((item: any) => item.citemno),
                "",
            );
            SQW_Log(DebugLevel.LOG, "getRemainingAPIProducts: ", apiItems);
            if (apiItems) {
                loadedItems = [...loadedItems, ...apiItems.Items];
                localStorage.setItem(key, JSON.stringify(loadedItems));
                localStorage.setItem(key + "_LoadedItems", String(loadedItems.length));
            }
            pageNumber++;
        }
        const totalItems = localStorage.getItem(key + "_TotalItems");
        if (totalItems) {
            if (getInt(totalItems) > loadedItems.length) {
                SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "getRemainingAPIProducts: Total Items does not match loaded items.");
                SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "getRemainingAPIProducts: Total Items: ", String(getInt(totalItems)));
                SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "getRemainingAPIProducts: Loaded Items: ", String(loadedItems.length));
                localStorage.setItem(key + "_LoadedItems", totalItems);
            }
        }

        return loadedItems;
    } catch (error) {
        SQW_Log(DebugLevel.LOG, "Error loading items: ", error);
    }

    return [];
};*/

/*export const encrypt = (data: any) => {
    try {
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "D3331: Encrypting: ", data);
        const pw = process.env.REACT_APP_P1 ?? initialKey;
        const encryptedData = sjcl.encrypt(pw, JSON.stringify(data));
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "D3331: Encrypted Data: ", encryptedData);
        return JSON.stringify(encryptedData);
    } catch (err) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error encrypting: ", err);
    }
    return "";
};

export const decrypt = (data: string) => {
    try {
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "D3331:  Decrypting", data);
        const sjclData = JSON.parse(data);
        const pw = process.env.REACT_APP_P1 ?? initialKey;
        const decryptedData = sjcl.decrypt(pw, sjclData);
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "D3331:  Decrypted", decryptedData);
        return JSON.parse(decryptedData);
    } catch (err) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error decrypting: ", err);
    }
    return "";
};*/

// export const encryptString = (data: string): string => {
//     try {
//         const pw = process.env.REACT_APP_P1 ?? initialKey;
//         const encryptedData = sjcl.encrypt(pw, data);
//         if (typeof encryptedData === "string") return encryptedData;
//         return JSON.stringify(encryptedData);
//     } catch (err) {
//         SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error encrypting: ", err);
//     }
//     return "";
// };

// export const decryptString = (data: string): string => {
//     try {
//         const pw = process.env.REACT_APP_P1 ?? initialKey;
//         const decryptedData = sjcl.decrypt(pw, data);
//         if (typeof decryptedData === "string") return decryptedData;
//         return JSON.stringify(decryptedData);
//     } catch (err) {
//         SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error decrypting: ", err);
//     }
//     return "";
// };

export async function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

/*export function encrypt(text: string) {
    if (process.env.NODE_ENV && process.env.NODE_ENV === "development") {
        return text;
    }
    const cipher = crypto.createCipheriv(algorithm, key, iv);
    let encrypted = cipher.update(text, "utf8", "base64");
    encrypted += cipher.final("base64");
    return encrypted;
}

export function decrypt(encryptedData: string) {
    if (process.env.NODE_ENV && process.env.NODE_ENV === "development") {
        return encryptedData;
    }
    const decipher = crypto.createDecipheriv(algorithm, key, iv);
    let decrypted = decipher.update(encryptedData, "base64", "utf8");
    decrypted += decipher.final("utf8");
    return decrypted;
}*/

export async function setAWSTokens() {
    return "";

    /*const res = await fetchAuthSession();

    if (!res) {
        SQW_Log(DebugLevel.LOG, "Could not fetch auth session.");
        return;
    }

    //SQW_Log(DebugLevel.LOG, "res: ", res);
    //SQW_Log(DebugLevel.LOG, "myIDToken:", res.tokens?.idToken);

    let accessToken = res.tokens?.accessToken?.toString();
    if (!accessToken) {
        accessToken = res.credentials?.sessionToken?.toString();
    }
    //SQW_Log(DebugLevel.LOG, "myAccessToken:", accessToken);

    //let accessToken = res.tokens?.idToken?.toString();
    if (accessToken) {
        cognitoauthtoken = accessToken;
        //SQW_Log(DebugLevel.LOG, "Access Token: ", accessToken);
    }

    let idToken = res.tokens?.idToken;
    if (idToken) {
        parsedToken = idToken.payload; //parseJwt(idToken);
        tenant = parsedToken["custom:TenantId"];
        companyname = parsedToken["custom:Company"];
        //cognitoclientid = parsedToken["aud"];
        //cognitodomain = "test-prismvs.auth.us-east-1.amazoncognito.com";
        username = parsedToken.email;
        localStorage.setItem("user", username);
    } else {
    }

    return cognitoauthtoken;*/
}

// export function handleSignOut() {
//     const redirectdomain = "test-prismvs.com";
//     //const logouturl = `https://${cognitodomain}/logout?client_id=${cognitoclientid}&response_type=code&redirect_uri=https%3A%2F%2F${redirectdomain}%2Fparseauth`;
//     const logouturl = `https://${redirectdomain}/signout`;
//     window.location.href = logouturl;
// }

export async function GetTenant() {
    if (globalTenantInfo) return globalTenantInfo();

    const tenant = getTenantNameFromSubdomain();
    if (tenant === null) return null;
    const tenantObj = await SelectTenant(tenant);
    if (tenantObj && tenantObj.length > 0) return tenantObj[0];

    return {};
}

export function GetTenantName() {
    return getTenantNameFromSubdomain() ?? "";
}

async function getURL(): Promise<string> {
    //if (url !== "") return url;
    const tenantInfo = await GetTenant();
    SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "getURL: ", tenantInfo);
    url = tenantInfo?.url ?? "";

    return url;
}

export async function fetchSecrets() {
    try {
        //await setAWSTokens();
        //SQW_Log(DebugLevel.LOG, "Getting tenant...");
        //SQW_Log(DebugLevel.LOG, "secrets tk id: " + cognitoauthtoken);
        //SQW_Log(DebugLevel.LOG, "tenant object", tenantObj);

        const tenantObj = await SelectTenant(getTenantNameFromSubdomain() ?? "");

        if (!tenantObj || tenantObj.length === 0) {
            SQW_Log(DebugLevel.LOG, "FS: Tenant object not found.");
            return;
        }

        url = tenantObj[0].url;
        if (!url) return;

        if (!url.endsWith("/")) url = url + "/";
        authuserid = tenantObj[0].createdby;
        authKey = tenantObj[0].uniqueid;

        /*if (GetUserName() === "dgikow@prismvs.com") {
            SQW_Log(DebugLevel.LOG, "HERE ARE THE DEETS");
            SQW_Log(DebugLevel.LOG, "   Tenant: " + tenantObj.name);
            SQW_Log(DebugLevel.LOG, "   URL: " + url);
            SQW_Log(DebugLevel.LOG, "   User: " + authuserid);
            SQW_Log(DebugLevel.LOG, "   AuthKey: " + authKey);
        }*/
        /*
        //const response = await fetch('https://ns0kfl3u2m.execute-api.us-east-1.amazonaws.com/prism-api-secrets?SecretId=TenantID_' + tenant, {
        const response = await fetch('https://ns0kfl3u2m.execute-api.us-east-1.amazonaws.com/prism-api-secrets?SecretId=' + tenantObj.name, {
                method: 'GET', 
            headers: {
                'Authorization': (cognitoauthtoken ? cognitoauthtoken : "")
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch secrets');
        }

        const data = await response.json();

        url = data.URL;
        if(!url.endsWith("/"))
        url = url + "/";
        authuserid = data.User;
        authKey = data.AuthKey;
        //SQW_Log(DebugLevel.LOG, "Secrets:" + JSON.stringify(data));
        */
    } catch (error) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error fetching secrets:", error);
    }
}

export function GetCompanyName() {
    return companyname;
}

export function SetCompanyLogo(logourl: string) {
    companylogo = logourl;
}

export function GetCompanyLogo() {
    if (companylogo === "") return GetLogoURL();

    return companylogo;
}

export function GetCompanySideImage() {
    if (companySideImage === "") GetLogoURL();

    return companySideImage;
}

export function getTenantNameFromSubdomain() {
    try {
        const parsedUrl = window.location.host.split(":");
        const hostnameParts = parsedUrl[0].split(".");
        if (hostnameParts.length > 2) {
            // Assuming the subdomain is the first part of the hostname
            SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "Tenant", hostnameParts[0]);
            if (hostnameParts[0] === "" || hostnameParts[0] === "www") return null;
            return hostnameParts[0];
        }
        return null; // No subdomain found
    } catch (error) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "getTenantNameFromSubdomain Error:", error);
        return null;
    }
}

/*export function GetTenantName(): string {

    //return globalTenant;
}*/

export function GetAuthKey() {
    SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "GetAuthKey: ", authKey);
    if (authKey === "")
        return (
            fetchSecrets().then(() => {
                return authKey;
            }) || ""
        );
    else return authKey;
}

export async function API_Authenticate() {
    try {
        let token = await GetAuthToken();

        if (!token) return "";

        token = token.replaceAll('"', "").replaceAll("\\", "");

        return token;
    } catch (err) {
        SQW_Log(DebugLevel.LOG, "API_Authenticate: Error", err);
    }

    return "";
}

export async function API_Authenticate_GetToken() {
    url = await getURL();

    authKey = await GetAuthKey();

    return fetch(url + ENDPOINT_Auth, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ UserID: authuserid, AuthKey: authKey }),
    })
        .then((response) => handleResponse(response, false))
        .catch(handleAPIError);
}

function getHeaders(token: string) {
    return {
        "Content-Type": "application/json",
        "accept": "application/json",
        "Authorization": `Bearer ${token.replaceAll('"', "")}`,
    };
}

function getHeadersTest(token: string) {
    return {
        "Content-Type": "application/json",
        "accept": "application/json",
        "Authorization": `Bearer ${token.replaceAll('"', "")}`,
    };
}

const openNewTab = (url: string = "") => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
    return newWindow;
};

function showPDF(blob: Blob) {
    const _global = window;
    /*typeof window === "object" && window.window === window
                    ? window
                    : typeof self === "object" && self.self === self
                    ? self
                    : typeof global === "object" && global.global === global
                    ? global
                    : window;*/

    const isMacOSWebView =
        _global.navigator &&
        /Macintosh/.test(navigator.userAgent) &&
        /AppleWebKit/.test(navigator.userAgent) &&
        !/Safari/.test(navigator.userAgent);

    //var popup = _global.open("#", "_blank");
    /*var popup = openNewTab();
    if (popup) {
        popup.document.title = popup.document.body.innerText = "downloading...";
    }*/

    const force = blob.type === "application/octet-stream";
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isChromeIOS = /CriOS\/[\d]+/.test(navigator.userAgent);

    if (
        (isChromeIOS || (force && isSafari) || isMacOSWebView) &&
        typeof FileReader !== "undefined"
    ) {
        const reader = new FileReader();
        reader.onloadend = function () {
            const res = reader.result;
            if (!res) return;

            let url = res.toString();
            url = isChromeIOS ? url : url.replace(/^data:[^;]*;/, "data:attachment/file;");
            if (!url) return;

            openNewTab(url);

            //if (popup) popup.location.href = url;
            //popup = null;
        };
        reader.readAsDataURL(blob);
    } else {
        const URL = _global.URL || _global.webkitURL;
        const url = URL.createObjectURL(blob);
        openNewTab(url);
        //if (popup) popup.location = url;
        //else _global.location.href = url;
        // popup = null;
        setTimeout(function () {
            URL.revokeObjectURL(url);
        }, 4e4);
    }
}

export function API_GetInvoiceReport(token: string, cuid: string, cinvno: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetInvoiceReport, {
            method: "POST",
            headers: getHeadersTest(token),
            body: JSON.stringify({ cinvcuid: cuid, cinvno: cinvno }),
        })
            .then((res) => {
                //SQW_Log(DebugLevel.LOG, "here 1");
                if (res.ok) {
                    //SQW_Log(DebugLevel.LOG, "here 2");
                    res.arrayBuffer().then((data) => {
                        //SQW_Log(DebugLevel.LOG, "buffer ", data);

                        const newBlob = new Blob([data], { type: "application/pdf" });

                        showPDF(newBlob);
                        //SQW_Log(DebugLevel.LOG, "blob: ", newBlob);
                        const pdf = window.URL.createObjectURL(newBlob);
                        //const pdf = window.URL.createObjectURL(data);
                        //SQW_Log(DebugLevel.LOG, "pdf: ", pdf);
                        //window.open(pdf);
                        return pdf;
                    });
                } else {
                    return res.text().then((text) => {
                        throw new Error(text);
                    });
                    /*//SQW_Log(DebugLevel.LOG, "here 3");
                    //SQW_Log(DebugLevel.LOG, "Res: ", res);
                    handleResponse(res);*/
                }
                /*var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = pdf;
                link.download = "Faktura.pdf";
                link.click();
                window.URL.revokeObjectURL(pdf);
                link.remove();*/
            })
            .catch(handleAPIError);
    });
}

export function API_GetTicketReport(token: string, username: string, csono: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetTicketReport, {
            method: "POST",
            headers: getHeadersTest(token),
            body: JSON.stringify({ username: username, csono: csono }),
        })
            .then((res) => {
                //SQW_Log(DebugLevel.LOG, "here 1");
                if (res.ok) {
                    //SQW_Log(DebugLevel.LOG, "here 2");
                    res.arrayBuffer().then((data) => {
                        //SQW_Log(DebugLevel.LOG, "buffer ", data);

                        const newBlob = new Blob([data], { type: "application/pdf" });
                        showPDF(newBlob);
                        //SQW_Log(DebugLevel.LOG, "blob: ", newBlob);
                        const pdf = window.URL.createObjectURL(newBlob);
                        //const pdf = window.URL.createObjectURL(data);
                        //SQW_Log(DebugLevel.LOG, "pdf: ", pdf);
                        //window.open(pdf);
                        return pdf;
                    });
                } else {
                    return res.text().then((text) => {
                        throw new Error(text);
                    });
                    /*//SQW_Log(DebugLevel.LOG, "here 3");
                    //SQW_Log(DebugLevel.LOG, "Res: ", res);
                    handleResponse(res);*/
                }
                /*var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = pdf;
                link.download = "Faktura.pdf";
                link.click();
                window.URL.revokeObjectURL(pdf);
                link.remove();*/
            })
            .catch(handleAPIError);
    });
}

export function API_GetAllWebItems(token: string, username: string) {
    const body = JSON.stringify({
        cusername: username,
    });

    SQW_Log(DebugLevel.INFO, "API_GetAllWebItems: ", body);

    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetAllWebItems, {
            method: "POST",
            headers: getHeaders(token),
            body: body,
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetWebItems(
    token: string,
    username: string,
    ccustno: string,
    cscono: string,
    cschedid: string,
    categories: string[],
    filterid: string,
    page: number,
    pageSize: number,
    searchString: string,
    currentItems: string[],
    sort: string,
) {
    const body = JSON.stringify({
        username: username,
        ccustno: ccustno,
        cscono: cscono,
        cschedid: cschedid,
        categories: categories,
        filterid: filterid,
        currentpage: page,
        numitemsonpage: pageSize,
        search: searchString,
        sendlog: "1",
        currentcart: currentItems,
        sort: sort,
        chubno: getSelectedHub(),
    });

    //SQW_Log(DebugLevel.LOG, "API_GetWebItems: ", body);

    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetWebItems, {
            method: "POST",
            headers: getHeaders(token),
            body: body,
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetWebItem(token: string, username: string, citemno: string) {
    const body = JSON.stringify({
        username: username,
        ccustno: "",
        cscono: "",
        cschedid: "",
        categories: "",
        filterid: "",
        currentpage: 1,
        numitemsonpage: 1,
        search: "",
        sendlog: "1",
        currentcart: "",
        sort: "",
        chubno: getSelectedHub(),
        citemno: citemno,
    });

    //SQW_Log(DebugLevel.LOG, "API_GetWebItem: ", body);

    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetWebItems, {
            method: "POST",
            headers: getHeaders(token),
            body: body,
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetSettings(token: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetSettings, {
            method: "POST",
            headers: getHeaders(token),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetAPIVersion(url: string, token: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetVersionNumber, {
            method: "POST",
            headers: getHeaders(token),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetSpecialImages(token: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetSpecialImages, {
            method: "POST",
            headers: getHeaders(token),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export type SpecialImage = {
    uuid: string;
    calt: string;
    cfilename: string;
    cfullfilename: string;
    ctitle: string;
    bactive: boolean;
    norder: number;
    centerby: string;
};

export function API_SaveSpecialImage(token: string, specialImage: SpecialImage) {
    const body = JSON.stringify({
        uuid: specialImage.uuid || "",
        cfilename: specialImage.cfilename || "",
        cfullfilename: specialImage.cfullfilename,
        centerby: specialImage.centerby || GetUserName(),
        calt: specialImage.calt,
        ctitle: specialImage.ctitle || "",
        bactive: specialImage.bactive,
        norder: specialImage.norder || 1,
    });
    //SQW_Log(DebugLevel.LOG, "body: ", body);
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_SaveSpecialImage, {
            method: "POST",
            headers: getHeaders(token),
            body: body,
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetUsersHubs(token: string, username: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetUsersHubs, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ username: username }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_SaveSettings(token: string, settings: Settings[]) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_SaveSettings, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ Settings: settings }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetWebCategories(token: string, username: string) {
    //SQW_Log(DebugLevel.LOG, "API_GetWebCategories: ", token, username);
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetWebCategories, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ chubno: getSelectedHub(), username: username }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetUserLinkedCustomers(token: string, username: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetCustomers, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ chubno: getSelectedHub(), username: username }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_RegisterNewUser(
    token: string,
    ccustno: string,
    username: string,
    bypass: boolean,
) {
    const bypassStr = bypass ? "true" : "false";

    /*SQW_Log(DebugLevel.LOG, 
        "API_RegisterNewUser: ccustno: " +
            ccustno +
            " username: " +
            username +
            " token: " +
            token +
            " byp: " +
            bypassStr,
    );*/

    return getURL().then((url) => {
        return fetch(url + ENDPOINT_RegisterNewUser, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({
                username: username,
                ccustno: ccustno,
                bpa: bypassStr,
            }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

// export function ClearLocalStorage() {
//     localStorage.clear();
// }

export function API_RemoveCustomerFromUser(token: string, ccustno: string, username: string) {
    //SQW_Log(DebugLevel.LOG, "ccustno: " + ccustno + " username: " + username + " token: " + token);
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_RemoveCustomerFromUser, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ username: username, ccustno: ccustno }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetNextDeliveryDate(token: string, ccustno: string, cschedid: string) {
    //SQW_Log(DebugLevel.LOG, "ccustno: " + ccustno + " cschedid: " + cschedid + " token: " + token);
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetNextDeliveryDate, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ ccustno: ccustno, cschedid: cschedid }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetUserInvoices(token: string, username: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetUserInvoices, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ chubno: getSelectedHub(), username: username }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetUserTickets(token: string, username: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetUserTickets, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ chubno: getSelectedHub(), username: username }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetLogoURL(token: string, username: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetLogoURL, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ chubno: getSelectedHub(), username: username }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

/*export async function GetLogoURL()
{
    if(authKey == "")
        await getSecrets2();
    const token = await API_Authenticate();
    const resp = await API_GetLogoURL(token, username);
    if(resp)
        companylogo = resp.url;

    return companylogo;
}*/

export async function RegisterUser(
    ccustno: string,
    username: string,
    firstname: string,
    lastname: string,
) {
    const config = getAWSConfig();
    const tenant = getTenantNameFromSubdomain();
    const route = "prism_register_new_user";
    const url =
        (config.aws_webapi_url.endsWith("/") ?
            config.aws_webapi_url
        :   config.aws_webapi_url + "/") + route;
    const body = {
        token: config.aws_webapi_token,
        tenantid: tenant,
        ccustno: ccustno,
        username: username,
        firstname: firstname,
        lastname: lastname,
    };

    console.log("RegisterUser: ", url, body);

    try {
        const resp = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        //Check the reponse to see if status was ok and

        console.log("RegisterUser: ", resp);
        if (resp) {
            if (resp.ok) {
                SQW_Log(DebugLevel.CRITICAL, "New User Registered: ", username);
                return true;
            } else {
                if (resp.status === 409) {
                    SQW_Log(DebugLevel.CRITICAL, "User already exists: ", username);
                    return true;
                }
                const data = await resp.json();
                SQW_Log(
                    DebugLevel.ERROR_WITH_ERROR_TYPE,
                    "Error registering new user: ",
                    data?.Message || "Unknown error",
                );
            }
        }
    } catch (err) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error registering new user: ", err);
    }
    return false;
}

export async function SetCompanyInfo() {
    const config = getAWSConfig();
    const tenant = getTenantNameFromSubdomain();
    const route = "prism_get_tenant_info";
    const url =
        (config.aws_webapi_url.endsWith("/") ?
            config.aws_webapi_url
        :   config.aws_webapi_url + "/") + route;
    const body = {
        token: config.aws_webapi_token,
        tenantid: tenant,
    };

    try {
        const resp = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        if (resp) {
            const data = await resp.json();
            if (data && data.length > 0) {
                SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "Company Info: ", data[0]);
                companyname = data[0].companyname.S;
                companylogo = data[0].logo.S;
                companySideImage = data[0].loginsideimage.S;
                SQW_Log(DebugLevel.LOG, "companySideImage", companySideImage);
                return true;
            }
        }
    } catch (err) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error getting company info: ", err);
    }
    companyname = "Advantage Route";
    companylogo = defaultLogo;
    companySideImage = defaultSideImage;
    return false;
}
let lastTenantInfoFetch = "";

export async function GetLogoURL() {
    const host = window.location.host;
    const subdomain = host.split(".")[0];

    if (lastTenantInfoFetch !== subdomain) {
        if (await SetCompanyInfo()) lastTenantInfoFetch = subdomain;
    }
    return companylogo;

    /*const t = await GetTenant();
    if (!t) {
        //SQW_Log(DebugLevel.LOG, "Tenant for subdomain " + subdomain + " not found.  Showing default.");
        //SQW_Log(DebugLevel.LOG, "Logo: Default");
        companylogo = defaultLogo;
        companySideImage = defaultSideImage;
        return defaultLogo;
    }

    if (t.logo && t.logo !== "") {
        //setTenant(t);
        //SQW_Log(DebugLevel.LOG, "tenant: ", t);
        companylogo = t.logo;
        //SQW_Log(DebugLevel.LOG, "Logo: ", companylogo);
        //SQW_Log(DebugLevel.LOG, "883: Side Image: ", t.loginsideimage);
        companySideImage =
            t.loginsideimage && t.loginsideimage !== "" ?
                t.loginsideimage
            :   "https://prism-web-images.s3.amazonaws.com/login_images/" + subdomain + ".jpg";
    } else {
        //SQW_Log(DebugLevel.LOG, "Showing default tenant.");
        //SQW_Log(DebugLevel.LOG, "Logo: Default");
        companylogo = defaultLogo;
        companySideImage = defaultSideImage;
    }

    return companylogo;*/
}

/*  Add Items to Schedule */

export type OrderItems = {
    citemno: string;
    nqty: number;
};

export function API_AddItemsToSchedule(
    token: string,
    ccustno: string,
    cscono: string,
    cschedid: string,
    orderitems: OrderItems[],
) {
    return fetch(url + ENDPOINT_AddItemsToSchedule, {
        method: "POST",
        headers: getHeaders(token),
        body: JSON.stringify({
            ccustno: ccustno,
            cscono: cscono,
            cschedid: cschedid,
            items: orderitems,
        }),
    })
        .then(handleResponse)
        .catch(handleAPIError);
}

/*  End add Items to Schedule */

export function API_GetCustomerCheckoutInfo(
    token: string,
    ccustno: string,
    cscono: string,
    cschedid: string,
) {
    return fetch(url + ENDPOINT_GetCustomerCheckoutInfo, {
        method: "POST",
        headers: getHeaders(token),
        body: JSON.stringify({
            ccustno: ccustno,
            cscono: cscono,
            cschedid: cschedid,
        }),
    })
        .then(handleResponse)
        .catch(handleAPIError);
}

/*  Create Ticket */

export function API_CreateTicket(
    token: string,
    ccustno: string,
    cschedid: string,
    cpono: string,
    corderby: string,
    cpaycode: string,
    cmessage: string,
    total: number,
    chargecc: number = 0,
    ccprofid: string,
    shipdate: Date,
    deliverydate: Date,
) {
    /*SQW_Log(DebugLevel.LOG, 
        "API_CreateTicket: ",
        JSON.stringify({
            ccustno: ccustno,
            cschedid: cschedid,
            cpono: cpono,
            corderby: corderby,
            cpaycode: cpaycode,
            cmessage: cmessage,
            total: total,
            chargecc: chargecc,
            ccprofid: ccprofid,
            shipdate: shipdate,
            deliverydate: deliverydate,
        }),
    );*/

    return getURL().then((url) => {
        return fetch(url + ENDPOINT_CreateTicket, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify(
                {
                    ccustno: ccustno,
                    cschedid: cschedid,
                    cpono: cpono,
                    corderby: corderby,
                    cpaycode: cpaycode,
                    cmessage: cmessage,
                    total: total,
                    chargecc: chargecc,
                    customer_payment_profile_id: ccprofid,
                    shipdate: shipdate,
                    deliverydate: deliverydate,
                },
                (key, value) => (typeof value === "bigint" ? value.toString() : value), // return everything else unchanged
            ),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

/*  End Create Ticket */

/*  Create SVC Ticket */

export function API_CreateServiceTicket(
    token: string,
    ccustno: string,
    cschedid: string,
    cpono: string,
    username: string,
    message: string,
) {
    /*SQW_Log(DebugLevel.LOG, 
        "API_CreateServiceTicket: ",
        JSON.stringify({
            ccustno: ccustno,
            cschedid: cschedid,
            cpono: cpono,
            corderby: username,
            cmessage: message,
        }),
    );*/

    return getURL().then((url) => {
        return fetch(url + ENDPOINT_CreateServiceTicket, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify(
                {
                    ccustno: ccustno,
                    cschedid: cschedid,
                    cpono: cpono,
                    username: username,
                    message: message,
                },
                (key, value) => (typeof value === "bigint" ? value.toString() : value), // return everything else unchanged
            ),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

/*  End Create Ticket */

/* Get Suggested Items */
export function API_GetSuggestedItems(
    token: string,
    citemno: string,
    ccustno: string,
    cscono: string,
    cschedid: string,
) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetSuggestedItems, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({
                citemno: citemno,
                ccustno: ccustno,
                cscono: cscono,
                cschedid: cschedid,
                chubno: getSelectedHub(),
            }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}
/* End Get Suggested Items */

/* Get Schedule Header */
export function API_GetScheduleHeader(token: string, ccustno: string, cschedid: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetScheduleHeader, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ ccustno: ccustno, cschedid: cschedid }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}
/* End Get Schedule Header */

/* Get Schedule Case Count */
export function API_GetScheduleCaseCount(token: string, ccustno: string, cschedid: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetScheduleCaseCount, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ ccustno: ccustno, cschedid: cschedid }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}
/* End Get Schedule Case Count */

export function API_GetOpenInvoices(token: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_OpenInv, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ chubno: getSelectedHub(), username: username }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

/* Save CC Profile */
export function API_SaveCCProfile(
    token: string,
    ccprofile: CCProfile,
    ccustno: string,
    username: string,
) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_SaveCCProfile, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({
                PaymentCardProfile: JSON.stringify(
                    ccprofile,
                    (key, value) => (typeof value === "bigint" ? value.toString() : value), // return everything else unchanged
                ),
                ccustno: ccustno,
                username: username,
            }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

/* End Save CC Profile */

export function API_DeleteCCProfile(token: string, nprofid: string) {
    //SQW_Log(DebugLevel.LOG, "deleting nprofid: ", nprofid.toString());
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_DeleteCCProfile, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ nprofid: nprofid.toString() }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_SetDefaultCCProfile(token: string, nprofid: string) {
    //SQW_Log(DebugLevel.LOG, "updating nprofid: ", nprofid.toString());
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_SetDefaultCCProfile, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ nprofid: nprofid.toString() }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

/* Get Schedule Addresses */
export function API_GetScheduleAddresses(token: string, username: string, ctype: string) {
    //SQW_Log(DebugLevel.LOG, "API_GetScheduleAddresses user: ", username);
    //SQW_Log(DebugLevel.LOG, "API_GetScheduleAddresses token:", token);
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetScheduleAddress, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ chubno: getSelectedHub(), username: username, ctype: ctype }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}
/* End Get Schedule Addresses */

/* Get Surcharge */
export function API_GetSurcharge(
    token: string,
    ccustno: string,
    cinvno: string,
    nbalance: number,
    customer_profile_id: bigint,
    customer_payment_profile_id: bigint,
    username: string,
) {
    /*SQW_Log(DebugLevel.LOG, 
        "API_GetSurcharge Request: ",
        JSON.stringify(
            {
                ccustno: ccustno,
                cinvno: cinvno,
                nbalance: nbalance,
                customer_profile_id: customer_profile_id,
                customer_payment_profile_id: customer_payment_profile_id,
                username: username,
            },
            (key, value) => (typeof value === "bigint" ? value.toString() : value),
        ),
    );*/
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetSurcharge, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify(
                {
                    ccustno: ccustno,
                    cinvno: cinvno,
                    nbalance: nbalance,
                    customer_profile_id: customer_profile_id,
                    customer_payment_profile_id: customer_payment_profile_id,
                    username: username,
                },
                (key, value) => (typeof value === "bigint" ? value.toString() : value),
            ),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}
/* End Get Surcharge */

export function API_GetPaymentMethods(ccustno: string, token: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_PayMethods, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ ccustno: ccustno, ctype: "CC" }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_SetPONumber(ponumber: string, invcuid: string, token: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_SetPONum, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({
                cpono: ponumber,
                cinvcuid: invcuid,
                username: username,
            }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

export function API_GetCustomerCredits(ccustno: string, cscono: string, token: string) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_GetCustomerCredits, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify({ ccustno, cscono, username: username }),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

/*export function API_GetCustomerCredits(ccustno: string): Promise<number> {
    return new Promise((resolve, reject) => {
        if (ccustno.trim() === "01-000306") {
            resolve(20.00);
        } else {
            resolve(0);
        }
    });
}*/

export function API_PayInvoices(
    InvoiceCuids: any,
    total: number,
    customer_profile_id: any,
    customer_payment_profile_id: any,
    useCredits: boolean,
    username: string,
    token: string,
) {
    return getURL().then((url) => {
        return fetch(url + ENDPOINT_PayInvoices, {
            method: "POST",
            headers: getHeaders(token),
            body: JSON.stringify(
                {
                    InvoiceCuids,
                    total,
                    customer_profile_id,
                    customer_payment_profile_id,
                    useCredits,
                    username,
                },
                (key, value) => (typeof value === "bigint" ? value.toString() : value), // return everything else unchanged)
            ),
        })
            .then(handleResponse)
            .catch(handleAPIError);
    });
}

interface CognitoSignUp {
    clientId: string;
    username: string;
    password: string;
    email: string;
}

export const getAWSConfig = () => {
    return config;
};

const algorithm = "AES-CBC";

async function generateKey() {
    const key = await window.crypto.subtle.generateKey(
        {
            name: algorithm,
            length: 256,
        },
        true,
        ["encrypt", "decrypt"],
    );
    return key;
}

/*export const setKeys = async () => {
    SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "J113: Checking for Key", process.env.PK1);
    if (!process.env.PK1) {
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "J113: Generate AES key");

        const key = await window.crypto.subtle.generateKey({ name: algorithm, length: 256 }, true, [
            "encrypt",
            "decrypt",
        ]);

        // Export the key to raw format
        const rawKey = await window.crypto.subtle.exportKey("raw", key);
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "J113: Generate raw key", rawKey);

        const base64Key = Buffer.from(rawKey).toString("base64");
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "J113: Generate base64 key", base64Key);

        SQW_Log(DebugLevel.LOG, "Key stored in .env file");
    }
};*/
let testRun = 0;
async function loadKey() {
    try {
        testRun++;
        const base64Key = process.env.REACT_APP_PK1;
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "J113: Loading Key From env ", base64Key, testRun);
        if (!base64Key) {
            SQW_Log(DebugLevel.DEBUG, "J113: Base64 key is undefined", testRun);
            throw new Error("J113: Base64 key is undefined");
        }
        const rawKey = Buffer.from(base64Key, "base64");

        return await window.crypto.subtle.importKey(
            "raw",
            rawKey,
            { name: algorithm, length: 256 },
            true,
            ["encrypt", "decrypt"],
        );
    } catch (err) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error loading key: ", err);
        throw err;
    }
}

export type EncryptObject = {
    iv: any;
    ciphertext: string;
};

// Function to convert ArrayBuffer to Base64 string
function arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export async function aesEncrypt(plaintext: string): Promise<EncryptObject> {
    try {
        const ec = new TextEncoder();
        const key = await loadKey();

        const iv = window.crypto.getRandomValues(new Uint8Array(16));

        /*const key = await window.crypto.subtle.importKey("raw", keyBuffer, { name: "AES-CBC" }, false, [
        "encrypt",
        "decrypt",
    ]);*/

        const ciphertext = await window.crypto.subtle.encrypt(
            {
                name: algorithm,
                iv: iv,
            },
            key,
            ec.encode(plaintext),
        );
        const base64text = arrayBufferToBase64(ciphertext);
        const base64iv = arrayBufferToBase64(iv.buffer);

        SQW_Log(DebugLevel.INSANE, "J113: -B3: Unencoded text: ", plaintext);

        SQW_Log(DebugLevel.INSANE, "J113: -B3: Encoded base64 text: ", base64text);

        SQW_Log(DebugLevel.INSANE, "J113: -B3: Encoded base64 iv: ", base64iv);
        const encryptedObj = {
            iv: base64iv,
            ciphertext: base64text,
        };
        return encryptedObj;
    } catch (err) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error encrypting: ", err);
        return { iv: "", ciphertext: "" };
    }
}

export async function aesDecrypt(encryptedObj: EncryptObject): Promise<string> {
    try {
        SQW_Log(DebugLevel.INSANE, "J113: Decrypt: ", encryptedObj);
        /*const key = await window.crypto.subtle.importKey("raw", keyBuffer, { name: "AES-CBC" }, false, [
        "encrypt",
        "decrypt",
    ]);*/

        if (!encryptedObj.iv) {
            SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "DCP: Invalid eciv object.");
        }
        if (!encryptedObj.ciphertext) {
            SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "DCP: Invalid ct object.");
            return "";
        }

        const iv = Buffer.from(encryptedObj.iv, "base64");
        SQW_Log(DebugLevel.INSANE, "J113: Decrypt: IV", iv);
        const ciphertext = Buffer.from(encryptedObj.ciphertext, "base64");

        const key = await loadKey();

        SQW_Log(DebugLevel.INSANE, "J113: Encrypt: Imported Key", key);

        const dec = new TextDecoder();

        SQW_Log(DebugLevel.INSANE, "J113: Decrypt: Decrypting ", ciphertext);
        const plaintext = await crypto.subtle.decrypt(
            {
                name: algorithm,
                iv: iv,
            },
            key,
            ciphertext,
        );

        SQW_Log(DebugLevel.INSANE, "J114: Decoded text: ", dec.decode(plaintext));
        return dec.decode(plaintext);
    } catch (err) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error decrypting: ", err);
        return "";
    }
}

/*
export const signUpWithCognito = async (
    clientId: string,
    username: string,
    password: string,
    email: string,
) => {
    const configA = {
        region: "us-east-1",
        credentials: { accessKeyId: akid, secretAccessKey: sak },
    };
    const client = new CognitoIdentityProviderClient(configA);
    //SQW_Log(DebugLevel.LOG, "T1");
    try {
        const cognitoUser = await getCognitoUser(username);
        //SQW_Log(DebugLevel.LOG, "Cognito User", cognitoUser);

        if (cognitoUser) return;
    } catch (err) {
        SQW_Log(DebugLevel.LOG, "Error getting user...", err);
    }
    //SQW_Log(DebugLevel.LOG, "T2");
    if (password == "") {
        const command = new AdminCreateUserCommand({
            UserPoolId: config.aws_user_pools_id,
            Username: username,
            UserAttributes: [{ Name: "email", Value: email }],
        });
        return client.send(command);
    } else {
        const command = new AdminCreateUserCommand({
            UserPoolId: config.aws_user_pools_id,
            Username: username,
            TemporaryPassword: password,
            UserAttributes: [{ Name: "email", Value: email }],
        });
        return client.send(command);
    }
};

export const disableCognitoUser = async (username: string) => {
    const configA = {
        region: "us-east-1",
        credentials: { accessKeyId: akid, secretAccessKey: sak },
    };
    const client = new CognitoIdentityProviderClient(configA);
    const userPoolId = config.aws_user_pools_id;
    const command = new AdminDisableUserCommand({
        UserPoolId: userPoolId,
        Username: username,
    });

    return client.send(command);
};

export const enableCognitoUser = async (username: string) => {
    const configA = {
        region: "us-east-1",
        credentials: { accessKeyId: akid, secretAccessKey: sak },
    };
    const client = new CognitoIdentityProviderClient(configA);
    const userPoolId = config.aws_user_pools_id;
    const command = new AdminEnableUserCommand({
        UserPoolId: userPoolId,
        Username: username,
    });

    return client.send(command);
};

export const getCognitoUser = async (username: string) => {
    const configA = {
        region: "us-east-1",
        credentials: { accessKeyId: akid, secretAccessKey: sak },
    };
    const client = new CognitoIdentityProviderClient(configA);
    const userPoolId = config.aws_user_pools_id;
    const command = new AdminGetUserCommand({
        UserPoolId: userPoolId,
        Username: username,
    });

    return client.send(command);
};
*/
/*
export function API_PayByCreditCard(
    token: string,
    total: number,
    customer_payment_profile_id: any,
    ccustno: string,
    cscono: string,
    csono: string,
    ccompany: string,
    username: string
  ) {
    //SQW_Log(DebugLevel.LOG, "Pay by CC custno: ", ccustno);
    //SQW_Log(DebugLevel.LOG, "Pay by CC profid: ", customer_payment_profile_id);
    //SQW_Log(DebugLevel.LOG, "Pay by CC username: ", username);
    //SQW_Log(DebugLevel.LOG, "Pay by CC token: ", token);
    
    
    return fetch(url + ENDPOINT_PayByCreditCard, {
        method: 'POST',
        headers:  getHeaders(token),
        body: JSON.stringify({ total, customer_payment_profile_id, ccustno, cscono, csono, ccompany, username } , (key, value) =>
        typeof value === 'bigint'
            ? value.toString()
            : value // return everything else unchanged)
    
        )}
    )
    .then(handleResponse)
    .catch(handleAPIError);
}*/

// Functions below are not accessible outside of the prismapi.tsx document. Only meant to be used with the above functions.

function handleResponse(response: Response, expectsJson = true): Promise<any> {
    //SQW_Log(DebugLevel.LOG, "Response: ", response);
    if (!response.ok) {
        return response.json().then((error) => {
            throw new Error(error.message || "Server Error!");
        });
    }
    return expectsJson ? response.json() : response.text();
}

/*
function handleArrayBufferResponse(response: Response): Promise<any> {
    if (!response.ok) {
        return response.json().then(error => {
            throw new Error(error.message || 'Server Error!');
        });
    }
    return response.arrayBuffer();
}*/

/*export function GetUserName(): string {
    return GetUserName() || localStorage.getItem("sqw_user") || "";
}*/

function handleAPIError(error: any) {
    if (!error)
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "API Request failure: Error is undefined");

    SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "API Request Failure: ", error);
    SQW_Log(DebugLevel.LOG, "API Request Failure: ", error.message);
    //throw error;
}
