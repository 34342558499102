import { Box, Checkbox, Container } from "@mui/material";
import { SQW_Log, DebugLevel } from "services/Logging";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import dayjs from "dayjs";
import * as React from "react";
import { useState } from "react";
import { GetUserName } from "services/PersistantObjects";

import { CCProfile, CreditCard } from "../interfaces/InvoiceInterfaces";
import { API_Authenticate, API_SaveCCProfile } from "../services/GetInvoiceData";
import LoadingSpinner from "./LoadingSpinner";
import { GreenButton, WhiteButton } from "./Buttons";

interface CreditCardProps {
    ccustno: string;
    data: CreditCard | null;
    isNew: boolean;
    onClose: (nprofid: string, saved: boolean) => void;
}

const CreditCardInformation: React.FC<CreditCardProps> = (props) => {
    const [profileID, setProfileID] = useState<bigint>(BigInt("0"));
    const [customerProfileID, setCustomerProfileID] = useState<bigint>(BigInt("0"));
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [company, setCompany] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [zip, setZip] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [fax, setFax] = useState<string>("");
    const [ccNum, setCCNum] = useState<string>("");
    const [expDate, setExpDate] = useState<dayjs.Dayjs | null>(null);
    const [ccv, setCCV] = useState<string>("");
    const [isDefault, setIsDefault] = useState<boolean>(false);
    const [creditCardInfo, setCreditCardInfo] = useState({} as CCProfile);
    const [saveCreditCard, setSaveCreditCard] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [expDateRequiredError, setExpDateRequiredError] = useState(false);
    const [saving, setSaving] = useState(false);

    /*
        const [firstName, setFirstName] = useState<string>(!props.data ? "" : props.data.getValue("cfirstname"));
    const [lastName, setLastName] = useState<string>(!props.data ? "" : props.data.getValue("clastname"));
    const [company, setCompany] = useState<string>(!props.data ? "" : props.data.getValue("ccompany"));
    const [address, setAddress] = useState<string>(!props.data ? "" : props.data.getValue("caddr"));
    const [city, setCity] = useState<string>(!props.data ? "" : props.data.getValue("ccity"));
    const [state, setState] = useState<string>(!props.data ? "" : props.data.getValue("cstate"));
    const [zip, setZip] = useState<string>(!props.data ? "" : props.data.getValue("czip"));
    const [phone, setPhone] = useState<string>(!props.data ? "" : props.data.getValue("cphone"));
    const [fax, setFax] = useState<string>(!props.data ? "" : props.data.getValue("cfax"));
    const [ccNum, setCCNum] = useState<string>("");
    const [expDate, setExpDate] = useState<dayjs.Dayjs | null>(!props.data ? null : dayjs(props.data.getValue("cexpdate")));
    const [ccv, setCCV] = useState<string>("");
    const [isDefault, setIsDefault] = useState<boolean>(!props.data ? false : props.data.getValue("ldefault"));
    const [creditCardInfo, setCreditCardInfo] = useState({} as CCProfile);
    const [saveCreditCard, setSaveCreditCard] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    */

    function handleFNameChanged(e: { target: { value: React.SetStateAction<string> } }) {
        setFirstName(e.target.value);
    }
    function handleLNameChanged(e: { target: { value: React.SetStateAction<string> } }) {
        setLastName(e.target.value);
    }
    function handleCompanyChanged(e: { target: { value: React.SetStateAction<string> } }) {
        setCompany(e.target.value);
    }
    function handleAddressChanged(e: { target: { value: React.SetStateAction<string> } }) {
        setAddress(e.target.value);
    }
    function handleCityChanged(e: { target: { value: React.SetStateAction<string> } }) {
        setCity(e.target.value);
    }
    function handleStateChanged(e: { target: { value: React.SetStateAction<string> } }) {
        setState(e.target.value);
    }
    function handleZipChanged(e: { target: { value: React.SetStateAction<string> } }) {
        setZip(e.target.value);
    }
    function handlePhoneChanged(e: { target: { value: React.SetStateAction<string> } }) {
        setPhone(e.target.value);
    }
    function handleFaxChanged(e: { target: { value: React.SetStateAction<string> } }) {
        setFax(e.target.value);
    }
    function handleCCNumChanged(e: { target: { value: React.SetStateAction<string> } }) {
        setCCNum(e.target.value);
    }
    function handleExpDateChanged(e: {
        target: { value: React.SetStateAction<dayjs.Dayjs | null> };
    }) {
        setExpDate(e.target.value);
    }
    function handleCCVChanged(e: { target: { value: React.SetStateAction<string> } }) {
        setCCV(e.target.value);
    }
    function handleDefaultChanged(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        setIsDefault(checked);
    }

    const handleSaveCreditCard = () => {
        const ccprof = {} as CCProfile;

        if (!validateFields()) return;

        ccprof.billTo_address = address;
        ccprof.billTo_city = city;
        ccprof.billTo_company = company;
        ccprof.billTo_faxNumber = fax;
        ccprof.billTo_firstName = firstName;
        ccprof.billTo_lastName = lastName;
        ccprof.billTo_phoneNumber = phone;
        ccprof.billTo_state = state;
        ccprof.billTo_zip = zip;
        ccprof.CardType = "";
        ccprof.OneTimeCard = false;
        ccprof.autoUpdate = 1;
        ccprof.profile_id = profileID;
        ccprof.customer_profile_id = customerProfileID;
        ccprof.cardCode = ccv;
        ccprof.cardNumber = ccNum;
        if (expDate) ccprof.expirationDate = expDate?.format("YYYY-MM"); //
        ccprof.ldefault = isDefault ? 1 : 0;

        setCreditCardInfo(ccprof);
        setSaveCreditCard(true);
    };

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        handleSaveCreditCard();
        // handle submit logic using "val"
    };

    const validateFields = () => {
        let OK = true;

        if (expDate === null) {
            OK = false;
            setExpDateRequiredError(true);
        }

        return OK;
    };

    React.useEffect(() => {
        setFirstName(!props.data ? "" : props.data.cfirstname);
        setLastName(!props.data ? "" : props.data.clastname);
        setCompany(!props.data ? "" : props.data.ccompany);
        setAddress(!props.data ? "" : props.data.caddr);
        setCity(!props.data ? "" : props.data.ccity);
        setState(!props.data ? "" : props.data.cstate);
        setZip(!props.data ? "" : props.data.czip);
        setPhone(!props.data ? "" : props.data.cphone);
        setFax(!props.data ? "" : props.data.cfax);
        setExpDate(!props.data ? null : dayjs(props.data.cexpdate));
        setIsDefault(
            !props.data ? false
            : props.data.ldefault === 1 ? true
            : false,
        );
        //SQW_Log(DebugLevel.LOG, "cc-card: ProfileID:", !props.data ? "" : props.data.payment_profile_id);
        /*SQW_Log(DebugLevel.LOG, 
            "cc-card: ProfileID - Int:",
            !props.data ? 0 : BigInt(props.data.payment_profile_id),
        );*/
        setProfileID(!props.data ? BigInt("0") : BigInt(props.data.payment_profile_id));
        setCustomerProfileID(!props.data ? BigInt("0") : BigInt(props.data.cust_profile_id));
    }, [props.data]);

    React.useEffect(() => {
        const saveData = async () => {
            try {
                if (saveCreditCard) {
                    setSaving(true);
                    const token = await API_Authenticate();
                    const ret = await API_SaveCCProfile(
                        token,
                        creditCardInfo,
                        props.ccustno,
                        GetUserName(),
                    );
                    //SQW_Log(DebugLevel.LOG, "Save Credit Card Return: ", ret);
                    setSaveCreditCard(false);
                    if (!ret) {
                        setErrorMsg("Error saving credit card, could not get response from API.");
                        return;
                    }

                    if (!ret.IsSuccessful) {
                        setErrorMsg(
                            "Error saving credit card.  Error Code: " +
                                ret.PayError?.MessageCode +
                                " Message: " +
                                ret.PayError?.MessageText,
                        );
                        return;
                    }
                    //SQW_Log(DebugLevel.LOG, "cc-card: saveData onClose: ", ret.nprofid.toString());
                    props.onClose(ret.nprofid.toString(), true);
                }
            } catch (e) {
                SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error saving credit card: ", e);
            } finally {
                setSaving(false);
            }
        };
        saveData();
    }, [saveCreditCard]);

    const expDateErrorProps =
        expDateRequiredError ?
            {
                helperText: "Input is required",
                error: true,
            }
        :   {};

    return (
        <>
            <link
                rel='stylesheet'
                href='https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/css/bootstrap.min.css'
                integrity='sha384-9gVQ4dYFwwWSjIDZnLEWnxCjeSWFphJiwGPXr1jddIhOegiu1FwO5qRGvFXOdJZ4'
                crossOrigin='anonymous'
            ></link>
            {/*<Card sx={{ minWidth: 400, maxHeight: 550, overflow: 'auto' }}>
      <CardContent>*/}
            <Box>
                {saving ?
                    <>
                        <LoadingSpinner message='Saving...' />
                    </>
                :   <form onSubmit={onSubmit}>
                        <Box
                            id='paymethod'
                            className='row'
                        >
                            {/*<Box className="col-lg-6 col-md-6 col-sm-8 col-xs-12">*/}
                            <Box className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                <Box className='row'>
                                    <Box className='h6'>
                                        {/*<b>Credit Card Information</b> ({profileID.toString()})*/}
                                    </Box>
                                </Box>
                                <Box className='row'>
                                    <Box className='col-lg-6 col-md-6 col-sm-6 px-1'>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-0 label1'>
                                            First Name*
                                        </Box>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-0'>
                                            <input
                                                required
                                                name='ccfirstname'
                                                type='text'
                                                id='ctl00_ContentPlaceHolder1_ucc_ecallin_cc_payment_method_cfirstname'
                                                defaultValue={firstName}
                                                className='form-control'
                                                placeholder=' '
                                                onChange={handleFNameChanged}
                                            />
                                        </Box>
                                    </Box>
                                    <Box className='col-lg-6 col-md-6 col-sm-6 px-1'>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-0 label1'>
                                            Last Name*
                                        </Box>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-0'>
                                            <input
                                                required
                                                name='cclastname'
                                                type='text'
                                                id='ctl00_ContentPlaceHolder1_ucc_ecallin_cc_payment_method_clastname'
                                                defaultValue={lastName}
                                                className='form-control'
                                                placeholder=''
                                                onChange={handleLNameChanged}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className='row'>
                                    <Box className='col-lg-12 col-med-12 col-xs-12 px-1 label1'>
                                        Company
                                    </Box>
                                    <Box className='col-lg-12 col-med-12 col-xs-12 px-1'>
                                        <input
                                            name='cccompany'
                                            type='text'
                                            id='ctl00_ContentPlaceHolder1_ucc_ecallin_cc_payment_method_ccompany'
                                            defaultValue={company}
                                            className='form-control'
                                            placeholder=''
                                            onChange={handleCompanyChanged}
                                        />
                                    </Box>
                                </Box>
                                <Box className='row'>
                                    <Box className='col-lg-12 col-med-12 col-xs-12 px-1 label1'>
                                        Address*
                                    </Box>
                                    <Box className='col-lg-12 col-med-12 col-xs-12 px-1'>
                                        <input
                                            required
                                            name='ccaddr'
                                            type='text'
                                            id='ctl00_ContentPlaceHolder1_ucc_ecallin_cc_payment_method_caddr'
                                            defaultValue={address}
                                            className='form-control'
                                            placeholder=''
                                            onChange={handleAddressChanged}
                                        />
                                    </Box>
                                </Box>
                                <Box className='row'>
                                    <Box className='col-sm-6 col-xs-12 px-0'>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-1 label1'>
                                            City*
                                        </Box>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-1'>
                                            <input
                                                required
                                                name='cccity'
                                                type='text'
                                                id='ctl00_ContentPlaceHolder1_ucc_ecallin_cc_payment_method_ccity'
                                                defaultValue={city}
                                                className='form-control'
                                                placeholder=''
                                                onChange={handleCityChanged}
                                            />
                                        </Box>
                                    </Box>
                                    <Box className='col-sm-2  col-xs-7 px-0'>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-1 label1'>
                                            State*
                                        </Box>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-1'>
                                            <input
                                                required
                                                name='ccstate'
                                                type='text'
                                                id='ctl00_ContentPlaceHolder1_ucc_ecallin_cc_payment_method_cstate'
                                                defaultValue={state}
                                                className='form-control'
                                                placeholder=''
                                                onChange={handleStateChanged}
                                            />
                                        </Box>
                                    </Box>
                                    <Box className='col-sm-4  col-xs-5  px-0'>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-1 label1'>
                                            Zip*
                                        </Box>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-1'>
                                            <input
                                                required
                                                name='cczip'
                                                type='text'
                                                id='ctl00_ContentPlaceHolder1_ucc_ecallin_cc_payment_method_czip'
                                                defaultValue={zip}
                                                className='form-control'
                                                placeholder=''
                                                onChange={handleZipChanged}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className='row'>
                                    <Box className='col-sm-6 col-xs-12 px-0'>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-1 label1'>
                                            Phone
                                        </Box>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-1'>
                                            <input
                                                name='ccphone'
                                                type='text'
                                                id='ctl00_ContentPlaceHolder1_ucc_ecallin_cc_payment_method_cphone'
                                                defaultValue={phone}
                                                className='form-control'
                                                placeholder='XXX-XXX-XXXX'
                                                onChange={handlePhoneChanged}
                                            />
                                        </Box>
                                    </Box>
                                    <Box className='col-sm-6 col-xs-12 px-0'>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-1 label1'>
                                            Fax
                                        </Box>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-1'>
                                            <input
                                                name='ccfax'
                                                type='text'
                                                id='ctl00_ContentPlaceHolder1_ucc_ecallin_cc_payment_method_cfax'
                                                defaultValue={fax}
                                                className='form-control'
                                                placeholder='XXX-XXX-XXXX'
                                                onChange={handleFaxChanged}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            {/*<Box className="col-lg-6 col-md-6 col-sm-9 col-xs-12 px-4">*/}
                            <Box className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                {/*<Box className="row">
            <Box className="h6"><b>Credit Card Information</b></Box>
        </Box>*/}
                                <Box className='row'>
                                    <Box className='col-sm-10  col-xs-12 px-0'>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-1 label1'>
                                            Credit Card Number*
                                        </Box>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-1'>
                                            <input
                                                required
                                                name='cccardnum'
                                                type='text'
                                                id='ctl00_ContentPlaceHolder1_ucc_ecallin_cc_payment_method_ccardnum'
                                                className='form-control'
                                                placeholder='XXXXXXXXXXXXXXXX'
                                                onChange={handleCCNumChanged}
                                            />
                                        </Box>
                                    </Box>
                                    <Box className='col-sm-2  col-xs-6 px-0'>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-1 label1'>
                                            CCV*
                                        </Box>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-1'>
                                            <input
                                                required
                                                name='cccardcode'
                                                type='text'
                                                id='ctl00_ContentPlaceHolder1_ucc_ecallin_cc_payment_method_ccardcode'
                                                className='form-control'
                                                placeholder=''
                                                onChange={handleCCVChanged}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className='row'>
                                    <Box className='col-sm-6 col-xs-12 px-0 label'>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-1 label1'>
                                            Expiration Date*
                                        </Box>
                                        <Box className='col-lg-12 col-med-12 col-xs-12 px-1'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label={""}
                                                    views={["month", "year"]}
                                                    defaultValue={expDate}
                                                    onChange={(newValue) => setExpDate(newValue)}
                                                    slotProps={{
                                                        textField: {
                                                            variant: "outlined",
                                                            error: !!expDateRequiredError,
                                                            helperText:
                                                                expDateRequiredError ?
                                                                    "Expiration Date is required."
                                                                :   "",
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>

                                            {/*<input name="ccexpdate_m" type="text" id="ctl00_ContentPlaceHolder1_ucc_ecallin_cc_payment_method_cexpdate_m" className="form-control" placeholder="MM" />*/}
                                        </Box>
                                    </Box>
                                    <Box className='col-sm-2 col-xs-0 px-0 label'></Box>
                                    <Box className='col-sm-4 col-xs-12 px-0 label'>
                                        <label>
                                            <Checkbox
                                                onChange={handleDefaultChanged}
                                                checked={isDefault}
                                            />
                                            Set as Default
                                        </label>
                                    </Box>
                                    {/*<Box className="col-sm-5 col-xs-6 px-0">
                <Box className="col-lg-12 col-med-12 col-xs-12 px-1 label1">Exp. Year</Box>
                <Box className="col-lg-12 col-med-12 col-xs-12 px-1">
                    <input name="ccexpdate_y" type="text" id="ctl00_ContentPlaceHolder1_ucc_ecallin_cc_payment_method_cexpdate_y" className="form-control" placeholder="YYYY" />
                </Box>
    </Box>*/}
                                </Box>

                                {/*<Box className="row">
        <Box className="col-sm-12">
            <a target="_blank" href="cpage.aspx?id=TERMS">Terms &amp; Conditions</a><br />
            <br />
        </Box>
</Box>*/}

                                <Box
                                    className='row'
                                    sx={{ mt: "20px !important", mb: "20px" }}
                                >
                                    <Box className='col-sm-4'>
                                        <GreenButton
                                            variant='contained'
                                            type='submit'
                                            sx={{ width: "100%" }}
                                        >
                                            Save
                                        </GreenButton>
                                    </Box>
                                    <Box className='col-sm-4'>&nbsp;</Box>
                                    <Box className='col-sm-4'>
                                        <WhiteButton
                                            variant='contained'
                                            onClick={() => {
                                                props.onClose("", false);
                                            }}
                                            sx={{ width: "100%" }}
                                        >
                                            Cancel
                                        </WhiteButton>
                                    </Box>
                                </Box>
                                <Box className='row'>
                                    <br />
                                    {/*<input style={{backgroundColor: blue}} type="submit" />*/}

                                    {errorMsg !== "" ?
                                        <Box className='col-sm-9'>{errorMsg}</Box>
                                    :   null}
                                </Box>
                                <Box className='row'>
                                    <br />
                                    <br />
                                </Box>
                            </Box>
                        </Box>
                    </form>
                }
            </Box>
            {/*</CardContent>
    </Card>*/}
        </>
    );
};

export default CreditCardInformation;
