import React, { ReactNode } from "react";
import { Category } from "interfaces/InvoiceInterfaces";
import * as IdentityImports from "identity/helpers/imports";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import navItems from "./navItems";
import CustomBox from "../theme/components/Box";


type MainLayoutProps = {
    children: ReactNode;
    signOut?: any;
    showStoreBar?: boolean;
    selectedCategory?: string[];
    searchCategory?: string[];
    selectedFilter?: string;
    categories?: Category[];
    setSelectedCategory?: React.Dispatch<React.SetStateAction<string[]>>;
    setSelectedFilter?: React.Dispatch<React.SetStateAction<string>>;
    setCategories?: React.Dispatch<React.SetStateAction<Category[]>>;
    setSearchCategory?: React.Dispatch<React.SetStateAction<string[]>>;
    setOpenCart?: React.Dispatch<React.SetStateAction<boolean>>;
    setShowCartButton?: React.Dispatch<React.SetStateAction<boolean>>;
    setNumberOfCartItems?: React.Dispatch<React.SetStateAction<number>>;
    openCart?: boolean;
    showCartButton?: boolean;
    numberOfCartItems?: number;
    currentView?: string;
};

function MainLayout({ children, signOut, numberOfCartItems }: MainLayoutProps) {
    const { tokens } = IdentityImports.useTheme();
    const [CBHeight, setCBHeight] = React.useState(0);

    function setCustomBoxHeight(height: number) {
        setCBHeight(height);
    }

    return (
        <>
            <NavBar
                navItems={navItems}
                numberOfCartItems={numberOfCartItems}
                signOut={signOut}
            />
            <CustomBox setHeight={setCustomBoxHeight}>{children}</CustomBox>
            <Footer cbHeight={CBHeight} />
        </>
    );
}

export default MainLayout;
