import React, { useState, useContext } from "react";
import { useIsMobile } from "helpers/CustomHooks";
import { SQW_Log, DebugLevel, setDebuggingMode } from "services/Logging";
import { CognitoAuthContext } from "helpers/CognitoAuthRoute";
import { useNavigate } from "react-router-dom";
import {
    Container,
    TextField,
    Button,
    Link,
    Typography,
    Box,
    Tab,
    Tabs,
    Stack,
} from "@mui/material";
import {
    GetCompanyLogo,
    GetCompanyName,
    GetCompanySideImage,
    getTenantNameFromSubdomain,
    sleep,
} from "services/GetInvoiceData";
import { UserNotConfirmedException } from "@aws-sdk/client-cognito-identity-provider";
import { confirmForgottenPassword, forgotPassword } from "helpers/CognitoAuth";
import LoadingSpinner from "components/LoadingSpinner";
import { clearLocalStorage } from "services/PersistantObjects";

import CreateAccount from "./CreateAccount";
//import { AuthContext } from "../context/AuthContext";

const LoginForm: React.FC = () => {
    const [createAccount, setCreateAccount] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");
    const [tab, setTab] = useState("login");
    const [sideImage, setSideImage] = useState("");
    const [logo, setLogo] = React.useState("");
    const [confirm, setConfirm] = React.useState(false);
    const [tenant, setTenant] = React.useState("");
    const [confirmForgotPassword, setConfirmForgotPassword] = React.useState(false);
    const [forgotPasswordConfirmId, setForgotPasswordConfirmId] = React.useState("");
    const [firstSubmit, setFirstSubmit] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [emptyFields, setEmptyFields] = React.useState<string[]>([]);
    const [invalidPassword, setInvalidPassword] = React.useState("");
    const [noMatchPassword, setNoMatchPassword] = React.useState(false);
    const [autoLogin, setAutoLogin] = React.useState(false);
    const [authLoading, setAuthLoading] = React.useState(true);
    const [companyName, setCompanyName] = React.useState("Advantage Route");

    //const { isValidSession, isAuthenticated, isLoading, loggingIn } = useContext(AuthContext);
    const { cognitoAuthenticateUser, DisplayAlert } = useContext(CognitoAuthContext);

    const isMobile = useIsMobile();

    React.useEffect(() => {
        const fetchCompanyInfo = async () => {
            //isValidSession();
            const logo = await GetCompanyLogo();
            setLogo(logo);
            const sideImage = await GetCompanySideImage();
            setSideImage(sideImage);
            const companyName = await GetCompanyName();
            setCompanyName(companyName);
            //if (isAuthenticated) {
            //SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "User is authenticated. Navigating to /");
            //navigate("/");
            //    setAuthLoading(false);
            //}
        };
        const getTenant = async () => {
            const tenant = await getTenantNameFromSubdomain();
            setTenant(tenant ?? "");
        };

        getTenant();
        fetchCompanyInfo();
    }, []);

    /*React.useEffect(() => {
        if (isAuthenticated) {
            SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "User is authenticated. Navigating to /");
            navigate("/");
            setAuthLoading(false);
        } else {
            setAuthLoading(isLoading);
        }
    }, [isAuthenticated]);*/
    /*
    React.useEffect(() => {
    }, [isAuthenticated]);*/

    React.useEffect(() => {
        if (submitted) {
            if (!firstSubmit) {
                setFirstSubmit(true);
            } else {
                logInUser();
            }
        }
    }, [submitted, firstSubmit]);

    React.useEffect(() => {
        if (autoLogin) {
            logInUser();
        }
    }, [autoLogin]);

    React.useEffect(() => {
        if (createAccount) {
            setTab("create_account");
        } else {
            setTab("login");
        }
    }, [createAccount]);

    React.useEffect(() => {
        validateFields();
    }, [username, password, confirmPassword, forgotPasswordConfirmId]);

    function validatePassword(password: string) {
        const minLength = 8;
        const hasNumber = /\d/;
        const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/;

        if (password.length < minLength) {
            return "Password must be at least 8 characters long";
        }

        if (!hasNumber.test(password)) {
            return "Password must contain at least one number";
        }

        if (!hasSymbol.test(password)) {
            return "Password must contain at least one symbol";
        }

        return "";
    }

    const validateFields = () => {
        let v = true;
        const empty = [];

        if (firstSubmit) {
            if (username === "") {
                empty.push("username");
                v = false;
            }
            if (password === "") {
                empty.push("password");
                v = false;
            }
            if (confirmForgotPassword) {
                if (confirmPassword === "") {
                    empty.push("confirmPassword");
                    v = false;
                }
                if (forgotPasswordConfirmId === "") {
                    empty.push("forgotPasswordConfirmId");
                    v = false;
                }
            }
        }
        const err = validatePassword(password);
        setInvalidPassword(err);
        if (err.length > 1) {
            v = false;
        }
        if (confirmForgotPassword) {
            SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "pwd", password, confirmPassword);
            const noMatch = password !== confirmPassword;
            setNoMatchPassword(noMatch);
            if (noMatch) {
                v = false;
            }
        }

        setEmptyFields(empty);
        return v;
    };

    const handleLogin = async (e: { preventDefault: () => void }) => {
        setSubmitted(true);
    };

    const logInUser = async () => {
        let user = username;
        try {
            if (user === "clearlocalstore") {
                clearLocalStorage();
                return;
            }
            SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "A3334:  logInUser");
            //await clearLocalStorage();
            //await sleep(1000);
            if (!validateFields()) {
                setSubmitted(false);
                return;
            }
            SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "validated");
            if (confirmForgotPassword) {
                try {
                    SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "confirming forgot password");
                    const resp = await confirmForgottenPassword(
                        user,
                        password,
                        forgotPasswordConfirmId,
                    );
                    if (resp) {
                        SQW_Log(
                            DebugLevel.INFO_WITH_INFO_TYPE,
                            "Forgot Password Confirm Response",
                            resp,
                        );
                        setMessage(
                            "Your password has been changed.  Please log in with you new password.",
                        );
                        setConfirmForgotPassword(false);
                        setForgotPasswordConfirmId("");
                        setAutoLogin(true);
                        return;
                    } else {
                        setErrorMessage("Could not confirm new password.");
                    }
                } catch (error) {
                    if (error instanceof Error) {
                        setErrorMessage(error.message);
                    }
                }
                setSubmitted(false);
                return;
            }
            const response = cognitoAuthenticateUser(user.toLowerCase(), password);
            if (response?.idToken) {
                SQW_Log(
                    DebugLevel.INFO_WITH_INFO_TYPE,
                    "A3334: User authenticated",
                    user,
                    response,
                );
                //const tenants = await SelectUserTenants(username);
                //SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "A3334: Tenants:", tenants);
                setSubmitted(false);
                //navigate("/");
            }
            /*const response = await cognitoAuthenticateUser(user, password);
            if (response && response.length > 0) {
                SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "A3334: User authenticated", user);
                //const tenants = await SelectUserTenants(username);
                //SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "A3334: Tenants:", tenants);
                setSubmitted(false);
                navigate("/");
            }*/
        } catch (err) {
            if (err instanceof UserNotConfirmedException) {
                setConfirm(true);
                setCreateAccount(true);
            }
            SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "Error authentiating", err);
            setSubmitted(false);
            return;
        }
        setSubmitted(false);
    };
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        if (tenant === "" && newValue === "create_account") {
            //Don't allow to create account if not in tenant.
            return;
        }
        setCreateAccount(newValue === "create_account");
    };

    const callForgotPassword = async () => {
        try {
            if (!validateUsername()) {
                DisplayAlert("Error", "You must fill in Email with a valid email address.");
                return;
            }

            const response = await forgotPassword(username);

            if (response && response.error != "") {
                DisplayAlert(
                    "Error",
                    response.error?.toString() ??
                        "An error occurred while trying to reset your password.",
                );
                return;
            }
            if (response) {
                DisplayAlert("Code Sent", "Confirmation code has been sent to your email.");
                setConfirmForgotPassword(true);
            }
        } catch (error) {
            DisplayAlert(
                "Error",
                error?.toString() ?? "An error occurred while trying to reset password.",
            );
        }
    };

    const validateUsername = () => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (emailRegex.test(username)) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <Stack
                direction='row'
                spacing={0}
                overflow='hidden'
                maxHeight='100vh'
                maxWidth='100vw'
                style={{
                    marginTop: "0px",
                    width: "100vw",
                    height: "100vh",
                }}
                /*sx={{
                        justifyContent: "flex-start",
                        alignItems: "stretch",
                    }}*/
            >
                {!isMobile && (
                    <Box
                        overflow='hidden'
                        height='100vh'
                        maxWidth='60vw'
                    >
                        <img
                            src={sideImage}
                            alt='Side Image'
                            height={window.innerHeight}
                            style={{ marginBottom: 0 }}
                        />
                    </Box>
                )}
                <Box
                    height='100vh'
                    maxWidth={isMobile ? "100vw" : "60vw"}
                    minWidth={isMobile ? "200px" : "400px"}
                    overflow='auto'
                    justifyContent='center'
                    style={{ marginRight: "auto", marginLeft: "auto" }}
                >
                    <Container
                        maxWidth='sm'
                        style={{ paddingRight: "10px", paddingLeft: "10px" }}
                    >
                        <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='center'
                            mt={8}
                        >
                            <img
                                src={logo}
                                alt='Logo'
                                style={{ marginBottom: 20, maxHeight: 100 }}
                            />
                            {!confirm && tenant !== "" && (
                                <Tabs
                                    centered
                                    value={tab}
                                    onChange={handleTabChange}
                                >
                                    <Tab
                                        label='Login'
                                        value='login'
                                    />
                                    <Tab
                                        label='Create Account'
                                        value='create_account'
                                    />
                                </Tabs>
                            )}
                        </Box>

                        {createAccount ?
                            <CreateAccount
                                confirm={confirm}
                                setConfirm={setConfirm}
                                setCreateAccount={setCreateAccount}
                                setMessage={setMessage}
                                setErrorMessage={setErrorMessage}
                            />
                        :   <Box
                                display='flex'
                                flexDirection='column'
                                alignItems='center'
                                mt={8}
                            >
                                <Typography
                                    variant='h5'
                                    gutterBottom
                                    align='left'
                                    width='100%'
                                >
                                    Login
                                </Typography>
                                <Typography
                                    variant='body2'
                                    gutterBottom
                                    align='left'
                                    width='100%'
                                >
                                    {message && <p style={{ color: "green" }}>{message}</p>}
                                </Typography>
                                <TextField
                                    error={emptyFields.includes("username")}
                                    helperText={
                                        emptyFields.includes("username") && "Field cannot be empty."
                                    }
                                    variant='outlined'
                                    margin='normal'
                                    required
                                    fullWidth
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    id='email'
                                    label='Email'
                                    name='email'
                                    autoComplete='email'
                                    autoFocus
                                />
                                <TextField
                                    error={emptyFields.includes("password")}
                                    helperText={
                                        emptyFields.includes("password") && "Field cannot be empty."
                                    }
                                    variant='outlined'
                                    margin='normal'
                                    required
                                    fullWidth
                                    name='password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    label='Password'
                                    type='password'
                                    id='password'
                                    autoComplete='current-password'
                                    inputProps={{
                                        onKeyPress: (event) => {
                                            if (event.key === "Enter") {
                                                handleLogin(event);
                                                event.preventDefault();
                                            }
                                        },
                                    }}
                                />
                                {confirmForgotPassword && (
                                    <>
                                        <TextField
                                            error={emptyFields.includes("confirmPassword")}
                                            helperText={
                                                noMatchPassword ? "Passwords must match." : (
                                                    emptyFields.includes("confirmPassword") &&
                                                    "Field cannot be empty."
                                                )
                                            }
                                            variant='outlined'
                                            margin='normal'
                                            required
                                            fullWidth
                                            name='confirmPassword'
                                            label='Confirm Password'
                                            type='password'
                                            id='confirmPassword'
                                            autoComplete='current-password'
                                            value={confirmPassword}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setConfirmPassword(e.target.value);
                                            }}
                                        />
                                        <TextField
                                            error={emptyFields.includes("forgotPasswordConfirmId")}
                                            helperText={
                                                emptyFields.includes("forgotPasswordConfirmId") &&
                                                "Field cannot be empty."
                                            }
                                            variant='outlined'
                                            margin='normal'
                                            required
                                            fullWidth
                                            id='forgotPasswordConfirmId'
                                            label='Confirmation ID'
                                            name='forgotPasswordConfirmId'
                                            autoComplete='forgotPasswordConfirmId'
                                            value={forgotPasswordConfirmId}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setForgotPasswordConfirmId(e.target.value);
                                            }}
                                        />
                                    </>
                                )}
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    onClick={handleLogin}
                                >
                                    Sign in
                                </Button>
                                <Box mt={2}>
                                    <Link
                                        style={{ color: "#58B55D" }}
                                        variant='body2'
                                        onClick={callForgotPassword}
                                    >
                                        Forgot Password?
                                    </Link>
                                </Box>
                                <Box mt={2}>
                                    <Typography variant='body2'>
                                        By continuing, you agree to {companyName}'s{" "}
                                        <Link style={{ color: "#58B55D" }}>
                                            Terms and Conditions
                                        </Link>{" "}
                                        and <Link style={{ color: "#58B55D" }}>Privacy Policy</Link>
                                        .
                                    </Typography>
                                </Box>
                                {tenant != "" && (
                                    <Box mt={2}>
                                        <Typography variant='body2'>
                                            If you are an existing customer without a Web Account,
                                            please{" "}
                                            <Link
                                                style={{ color: "#58B55D" }}
                                                onClick={() => {
                                                    setCreateAccount(true);
                                                }}
                                            >
                                                Create an Account
                                            </Link>
                                            .
                                        </Typography>
                                    </Box>
                                )}
                                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                            </Box>
                        }
                    </Container>
                </Box>
            </Stack>
        </>
    );
};

export default LoginForm;
