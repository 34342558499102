import { Button, Flex, SelectField } from "@aws-amplify/ui-react";
import { SQW_Log, DebugLevel } from "services/Logging";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import { generateClient } from "aws-amplify/api";
import * as React from "react";
import { useEffect } from "react";
import { GetTenantName, getUserGroups } from "services/GetInvoiceData";

import {
    DeleteTenantUserRelationship,
    InsertTenantUserRelation,
    SelectAllTenants,
    SelectUserTenants,
} from "../services/GraphQlHelper";
import { getOverrideProps } from "../ui-components/utils";
const client = generateClient();

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

interface UpdateUsersTenantsProps {
    idProp?: string;
    onSuccess: () => void;
}

export default function UpdateUsers(props: UpdateUsersTenantsProps) {
    const [selectedPage, setSelectedPage] = React.useState("default");
    const [users, setUsers] = React.useState([] as any[]);
    const [confirmationOpen, setConfirmationOpen] = React.useState(false);
    const [addRelationOpen, setAddRelationOpen] = React.useState(false);
    const [message, setMessage] = React.useState(
        "Are you sure you want to delete this user tenant relationship?",
    );
    const [title, setTitle] = React.useState("Delete User Tenant Relationship");
    const [id, setId] = React.useState("");
    const [username, setUsername] = React.useState("dgikow@prismvs.com");
    const [selectedTenantName, setSelectedTenantName] = React.useState("");
    const [tenants, setTenants] = React.useState([] as any[]);
    const [isSiteAdmin, setIsSiteAdmin] = React.useState(false);

    const tenantName = GetTenantName();

    /*    React.useEffect(() => {
            getUserGroups().then((groups) => {
                if (!groups) return;
                if (groups.includes("site-admin-" + tenantName)) setIsSiteAdmin(true);
            });
        }, []);*/

    function deleteUserTenantRelationship(id: string) {
        setId(id);
        handleConfirmationOpen();
    }
    function deleteUserTenantRelationshipWorker(id: string) {
        DeleteTenantUserRelationship(id);
        props.onSuccess();
    }

    function addUserTenantRelationship(id: string) {}

    const handleConfirmationOpen = () => {
        setConfirmationOpen(true);
    };
    const handleConfirmationClose = () => {
        setConfirmationOpen(false);
    };
    const handleConfirm = () => {
        setConfirmationOpen(false);
        deleteUserTenantRelationshipWorker(id);
    };

    function ConfirmationBox() {
        return (
            <Dialog
                open={confirmationOpen}
                onClose={handleConfirmationClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleConfirmationClose}
                        autoFocus
                    >
                        No
                    </Button>
                    <Button onClick={handleConfirm}>Yes</Button>
                </DialogActions>
            </Dialog>
        );
    }
    function onSelectTenant() {
        if (!selectedTenantName || selectedTenantName === "") {
            alert("Please select a tenant");
            return;
        }
        //SQW_Log(DebugLevel.LOG, "onSelectTenant: ", selectedTenantName);
        //SQW_Log(DebugLevel.LOG, "users: ", users);
        if (users && users.find((u) => u?.tenantsId === selectedTenantName)?.id) {
            alert("User is already in this tenant");
            return;
        }
        /*SQW_Log(DebugLevel.LOG, 
            "onSelectTenant: passed dup check",
            users.find((u) => u?.tenantsId === selectedTenantName),
        );*/
        onChooseTenant(selectedTenantName);
    }

    function AddRelationshipBox() {
        return (
            <Dialog
                open={addRelationOpen}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <div>
                    <FormControl sx={{ m: 1, minWidth: 300 }}>
                        <SelectField
                            label='Tenant'
                            value={selectedTenantName}
                            onChange={(e) =>
                                setSelectedTenantName((e.target as HTMLSelectElement).value)
                            }
                        >
                            <option
                                key={342324332}
                                value={""}
                            >
                                {"Please select a tenant..."}
                            </option>
                            {isSiteAdmin ?
                                <option
                                    key={tenantName}
                                    value={tenantName}
                                >
                                    {tenantName}
                                </option>
                            :   tenants &&
                                tenants.length > 0 &&
                                tenants?.map((item, index) => (
                                    <option
                                        key={index}
                                        value={item?.tenantid}
                                    >
                                        {item?.tenantdescription}
                                    </option>
                                ))
                            }
                        </SelectField>
                        <br />
                        <br />
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={6}
                            >
                                <Button
                                    onClick={() => {
                                        onTenantChooserClose();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                            >
                                <Button
                                    onClick={() => {
                                        onSelectTenant();
                                    }}
                                >
                                    Select Tenant
                                </Button>
                            </Grid>
                        </Grid>
                    </FormControl>
                </div>
            </Dialog>
        );
    }

    React.useEffect(() => {
        const queryData = async () => {
            try {
                //SQW_Log(DebugLevel.LOG, "getting user/tenants:", props?.idProp);
                const records = props.idProp ? await SelectUserTenants(props.idProp) : null;

                const allusers = records?.data?.tenantsUsersByUsersId?.items || [];

                let users;
                let siteAdmin = false;

                const groups = await getUserGroups();

                if (groups && groups.includes("site-admin-" + tenantName)) {
                    setIsSiteAdmin(true);
                    siteAdmin = true;
                }

                if (siteAdmin) {
                    users =
                        allusers.find((x) => x.tenantsId == tenantName) ?
                            [{ id: tenantName, tenantsId: tenantName, usersId: "" }]
                        :   [];
                } else {
                    users = allusers;
                }
                setUsers(users);
                //SQW_Log(DebugLevel.LOG, "users: ", users);
            } catch (error) {
                SQW_Log(DebugLevel.LOG, "Error when trying to get users", error);
            }
        };
        queryData();
    }, [props?.idProp]);

    function showPage() {
        switch (selectedPage) {
            case "default":
                return (
                    <>
                        <br />
                        <br />
                        <Typography variant='h4'>
                            {props?.idProp}'s Current Tenant Relationships
                        </Typography>
                        <br />
                        <br />
                        <Grid
                            maxWidth={"600px"}
                            container
                            spacing={{ xs: 2, md: 2 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                            <Grid
                                xs={12}
                                sm={12}
                                md={12}
                            >
                                {users.map((user) => (
                                    <>
                                        <Item>
                                            <Flex
                                                justifyContent='space-between'
                                                alignItems='center'
                                            >
                                                <h5>{user.tenantsId}</h5>
                                                <Button
                                                    onClick={() => {
                                                        deleteUserTenantRelationship(user.id);
                                                    }}
                                                >
                                                    Delete
                                                </Button>
                                            </Flex>
                                        </Item>
                                        <br />
                                    </>
                                ))}
                            </Grid>
                        </Grid>
                        <br />
                        <br />
                        <Button
                            onClick={() => {
                                setAddRelationOpen(true);
                            }}
                        >
                            Add Tenant Relationship
                        </Button>
                        <br />
                        <br />
                        <Button
                            children='Go Back'
                            type='reset'
                            onClick={(event) => {
                                event.preventDefault();
                                props.onSuccess();
                            }}
                            {...getOverrideProps(null, "ClearButton")}
                        ></Button>
                    </>
                );
            default:
                return <div></div>;
        }
    }

    function onChooseTenant(tenantName: string): void {
        if (!props?.idProp) {
            alert("Could not get user id.  Please try again.");
            return;
        }
        try {
            InsertTenantUserRelation(tenantName, props?.idProp);
        } catch (err) {
            alert(err);
            return;
        }
        alert("User added to " + tenantName);
        setAddRelationOpen(false);
        props.onSuccess();
    }
    function onTenantChooserClose(): void {
        setAddRelationOpen(false);
    }

    type TenantSelectType = {
        tenantid: string;
        tenantdescription: string;
    };
    useEffect(() => {
        const fetchUserTenants = async () => {
            const tenants: TenantSelectType[] = [];
            //SQW_Log(DebugLevel.LOG, "fetchUserTenants: ");

            const userTenants = await SelectAllTenants();
            if (userTenants?.data?.listTenants?.items) {
                for (let i = 0; i < userTenants.data.listTenants.items.length; i++) {
                    const item = userTenants.data.listTenants.items[i];
                    tenants.push({
                        tenantid: item.subdomain,
                        tenantdescription: item.name,
                    });
                }
            }
            //SQW_Log(DebugLevel.LOG, "User Tenants: ", tenants);

            setTenants(tenants);
            //SQW_Log(DebugLevel.LOG, "tenants: ", tenants);
        };
        fetchUserTenants();
    }, []);

    return (
        <Box sx={{ flexGrow: 1 }}>
            {ConfirmationBox()}
            {AddRelationshipBox()}
            {showPage()}
        </Box>
    );
}
