import { useEffect, useMemo, useState, ChangeEvent, useCallback } from "react";
import { SQW_Log, DebugLevel } from "services/Logging";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Typography,
    Grid,
    TextField,
    InputAdornment,
    Pagination,
    Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
    type MRT_RowSelectionState,
} from "material-react-table";

import LoadingSpinner from "../components/LoadingSpinner";
import { truncateString } from "../helpers/truncateString";
import { OpenInvoice } from "../interfaces/InvoiceInterfaces";
import { ReactNativeNumberFormat } from "../components/ReactNativeNumberFormat";
import { getPONum } from "../helpers/getPONum";
import {
    API_Authenticate,
    API_GetInvoiceReport,
    API_GetOpenInvoices,
    API_SetPONumber,
} from "../services/GetInvoiceData";
import theme from "../theme/theme";

const ViewOpenInvoices = () => {
    const [openInvoices, setOpenInvoices] = useState<OpenInvoice[]>([]);
    const [authToken, setAuthToken] = useState("");
    const [loading, setLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoadingReport, setIsLoadingReport] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [isSmScreen, setIsSmScreen] = useState(window.innerWidth < 1000);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);

    const navigate = useNavigate();

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const filteredData = useMemo(() => {
        if (!searchTerm) return openInvoices;
        return openInvoices.filter((invoice) => {
            return (
                invoice.cinvno.toLowerCase().includes(searchTerm) ||
                invoice.ccustno.toLowerCase().includes(searchTerm) ||
                invoice.cpono.toLowerCase().includes(searchTerm)
            );
        });
    }, [openInvoices, searchTerm]);

    useEffect(() => {
        const handleResizeWindow = () => {
            const newWidth = window.innerWidth;
            setWidth(newWidth);
            setIsSmScreen(newWidth < 1000);
        };

        window.addEventListener("resize", handleResizeWindow);
        handleResizeWindow();

        return () => {
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const token = await API_Authenticate();

                setAuthToken(token);

                const invoiceData = await API_GetOpenInvoices(token);
                if (invoiceData) {
                    const data: OpenInvoice[] = invoiceData?.Invoices;

                    data.forEach(function (part: any, index: number) {
                        data[index].cpono = data[index].cpono.trim();
                    });

                    setOpenInvoices(data);
                }
            } catch (error) {
                SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Failed to load open invoices: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const poNumberChanged = useCallback(
        async (ponumber: string, invcuid: string) => {
            let token = authToken;
            setIsSaving(true);

            if (!token) {
                SQW_Log(
                    DebugLevel.ERROR_WITH_ERROR_TYPE,
                    "Authentication token was not passed. Getting new token.",
                );
                token = await API_Authenticate();
                setAuthToken(token);

                if (!token) {
                    SQW_Log(DebugLevel.LOG, "Could not retrieve an Authentication Token.");
                    alert("Could not retrieve an Authentication Token.");
                    setIsSaving(false);
                    return;
                }
            }

            try {
                //SQW_Log(DebugLevel.LOG, "Calling SetPONumber.");
                const message = await API_SetPONumber(ponumber, invcuid, token);
                if (message?.IsSuccessful === true) {
                    SQW_Log(DebugLevel.LOG, "Successfully changed the PO#.");
                    //SQW_Log(DebugLevel.LOG, "Updated PO#: " + ponumber + " for Invoice CUID " + invcuid + ".");
                } else {
                    /*SQW_Log(DebugLevel.LOG, 
                        "Setting the PO# has failed. Server returned error message: " +
                            JSON.stringify(message) +
                            ".",
                    );*/
                    alert(
                        "Setting the PO# has failed. Server returned error message: " +
                            JSON.stringify(message) +
                            ".",
                    );
                }
            } catch (error) {
                SQW_Log(
                    DebugLevel.ERROR_WITH_ERROR_TYPE,
                    "Failed to change PO#. Server returned error message: ",
                    error,
                );
                alert("Failed to save the provided PO#.");
            } finally {
                setIsSaving(false);
            }
        },
        [authToken],
    );

    const showReport = async (cuid: string, cinvno: string) => {
        try {
            setIsLoadingReport(true);

            //SQW_Log(DebugLevel.LOG, "Show Report for " + cuid);

            const token = await API_Authenticate();

            await API_GetInvoiceReport(token, cuid, cinvno);
        } catch (error) {
            SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Failed to load report: ", error);
            alert("Failed to load report: " + error);
        } finally {
            setIsLoadingReport(false);
        }

        /*const response = await API_GetInvoiceReport(token, cuid);

      if(response && response.IsSuccessful)
      {
        //SQW_Log(DebugLevel.LOG, "Report response: ", response);
        const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //SQW_Log(DebugLevel.LOG, "fileURL: ", fileURL);
        //Open the URL on new Window
        window.open(fileURL);
        //SQW_Log(DebugLevel.LOG, "Did something happen?");
      }
      else
      {
        if(response)
        {
          SQW_Log(DebugLevel.LOG, "Error showing report...  " + response.Error);
        }
        else
          SQW_Log(DebugLevel.LOG, "Error showing report...  No response from api.");
      }*/
    };

    const columns = useMemo<MRT_ColumnDef<OpenInvoice>[]>(
        () => [
            {
                accessorKey: "cuid",
                header: "cuid",
                enableEditing: false,
                size: 50,
            },
            {
                accessorKey: "cinvno",
                header: "Invoice#",
                enableEditing: false,
                size: 50,
                grow: true,
                Cell: ({ row }) => {
                    return isSmScreen ?
                            <Box
                                sx={{
                                    display: "grid",
                                    margin: "auto",
                                    gridTemplateColumns: "1fr",
                                    width: "100%",
                                }}
                            >
                                <Typography>
                                    Invoice#:
                                    <a
                                        href='#'
                                        onClick={() => {
                                            showReport(row.original.cuid, row.original.cinvno);
                                        }}
                                        style={{
                                            color: theme.palette.primary.main,
                                            textDecoration: "none",
                                        }}
                                    >
                                        {row.original.cinvno}
                                    </a>
                                </Typography>
                                <Typography>Customer#: {row.original.ccustno}</Typography>
                                <Typography>PO#: {row.original.cpono}</Typography>
                                <Typography>
                                    Date: {truncateString(row.original.dorder, 10)}
                                </Typography>
                                <Typography>
                                    Total:{" "}
                                    {ReactNativeNumberFormat({ value: row.getValue("nsalesamt") })}
                                </Typography>
                                <Typography>
                                    Balance:{" "}
                                    {ReactNativeNumberFormat({ value: row.getValue("nbal") })}
                                </Typography>
                            </Box>
                        :   <>
                                <a
                                    href='#'
                                    onClick={() => {
                                        showReport(
                                            row.getValue<string>("cuid").toString(),
                                            row.getValue<string>("cinvno"),
                                        );
                                    }}
                                    style={{
                                        color: theme.palette.primary.main,
                                        textDecoration: "underline",
                                    }}
                                >
                                    {row.getValue<string>("cinvno")}
                                </a>
                            </>;
                },
            },
            {
                accessorKey: "ccustno",
                header: "Customer",
                enableEditing: false,
                size: 50,
            },
            {
                accessorKey: "cpono",
                header: "PO#",
                size: 50,
                enableEditing: true,
                muiTableBodyCellProps: ({ table, cell }) => ({
                    onClick: () => {
                        table.setEditingCell(cell);
                    },
                }),
                muiEditTextFieldProps: ({ row }) => ({
                    onBlur: (event) => {
                        const value = event.target.value;
                        //validate data
                        SQW_Log(DebugLevel.LOG, "Changed PO number to " + value);
                        poNumberChanged(value, row.getValue("cuid"));
                        // table.setEditingCell(null) is called automatically onBlur internally
                    },
                }),
                Cell: ({ row }) => {
                    return (
                        <a
                            href='#'
                            onClick={() => {
                                getPONum(row.getValue("cpono"));
                            }}
                            style={{
                                color: theme.palette.primary.main,
                                textDecoration: "underline",
                            }}
                        >
                            {getPONum(row.getValue("cpono"))}
                        </a>
                    );
                },
            },
            {
                accessorKey: "cscono",
                header: "Site",
                enableEditing: false,
            },
            {
                accessorKey: "csaddr1",
                header: "Address",
                enableEditing: false,
            },
            {
                accessorKey: "cscity",
                header: "City",
                enableEditing: false,
            },
            {
                accessorKey: "csstate",
                header: "State",
                enableEditing: false,
            },
            {
                accessorKey: "cszip",
                header: "Zip",
                enableEditing: false,
            },
            {
                accessorKey: "dorder",
                header: "Date",
                enableEditing: false,
                size: 50,
                Cell: ({ row }) => {
                    return (
                        <div className='w-[70px] font-medium text-xs'>
                            {truncateString(row.getValue("dorder"), 10)}
                        </div>
                    );
                },
            },
            {
                accessorKey: "ccompany",
                header: "Company/Address",
                enableEditing: false,
                size: 150,
                Cell: ({ row }) => {
                    return (
                        <div className='flex space-x-2'>
                            <span className='max-w-[500px] truncate font-medium text-xs'>
                                {row.getValue("ccompany")}
                                <br />
                                {row.getValue("csaddr1")}
                                <br />
                                {row.getValue("cscity")}, {row.getValue("csstate")}{" "}
                                {row.getValue("cszip")}
                            </span>
                        </div>
                    );
                },
            },
            {
                accessorKey: "nsalesamt",
                header: "Total",
                enableEditing: false,
                size: 50,
                Cell: ({ row }) => {
                    return (
                        <div className='flex space-x-2'>
                            <span className='max-w-[500px] truncate font-medium text-xs'>
                                {ReactNativeNumberFormat({ value: row.getValue("nsalesamt") })}
                            </span>
                        </div>
                    );
                },
            },
            {
                accessorKey: "nbal",
                header: "Balance",
                enableEditing: false,
                size: 50,
                Cell: ({ row }) => {
                    return (
                        <div className='flex space-x-2'>
                            <span className='max-w-[500px] truncate text-sm font-medium text-xs'>
                                {ReactNativeNumberFormat({ value: row.getValue("nbal") })}
                            </span>
                        </div>
                    );
                },
            },
        ],
        [poNumberChanged, isSmScreen], //end
    );

    const handlePayInvoices = () => {
        const selectedRows = table.getSelectedRowModel().rows;
        if (selectedRows.length === 0) {
            alert("Please select at least one invoice to pay.");
        } else {
            let skip = false;
            const ccustno = selectedRows[0].getValue("ccustno");
            selectedRows.forEach((element) => {
                if (ccustno !== element.getValue("ccustno")) {
                    alert("All selected invoices must be for the same customer.");
                    skip = true;
                }
            });
            if (!skip) {
                navigate("/payinvoices", { state: { id: 1, name: JSON.stringify(selectedRows) } });
            }
        }
    };

    //optionally, you can manage the row selection state yourself
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

    useEffect(() => {
        //SQW_Log(DebugLevel.LOG, "setRowSelection: ", rowSelection);
    }, [rowSelection]);

    const table = useMaterialReactTable({
        columns,
        data: filteredData, // Using the state data.
        enableRowSelection: true,
        enableTopToolbar: false,
        enableBottomToolbar: false,
        enableEditing: true,
        editDisplayMode: "cell",
        enableColumnActions: false,
        enableColumnFilters: false,
        enableSorting: true,
        initialState: {
            columnVisibility: {
                cuid: false,
                csaddr1: false,
                cscity: false,
                csstate: false,
                cszip: false,
                cscono: false,
                cpono: !isSmScreen,
                ccustno: !isSmScreen,
                dorder: !isSmScreen,
                ccompany: !isSmScreen,
                nsalesamt: !isSmScreen,
                nbal: !isSmScreen,
            },
        },
        getRowId: (row) => row.cinvno, //give each row a more useful id
        onRowSelectionChange: setRowSelection,
        state: {
            pagination: {
                pageIndex: page,
                pageSize: pageSize,
            },
            rowSelection,
        },
        muiSelectAllCheckboxProps: {
            sx: {
                "&.MuiCheckbox-root": {
                    "color": theme.palette.common.black,
                    "&.Mui-checked": {
                        color: theme.palette.common.black,
                    },
                },
            },
        },
        muiTableBodyCellProps: {
            sx: {
                backgroundColor: theme.palette.common.white,
            },
        },
        muiTableHeadCellProps: {
            sx: {
                backgroundColor: theme.palette.common.white,
            },
        },
    });

    useEffect(() => {
        //SQW_Log(DebugLevel.LOG, "resetting column sizing...  useEffect: ", isSmScreen);
        if (!loading && table) {
            //SQW_Log(DebugLevel.LOG, "resetting column sizing...  small screen=", isSmScreen);
            table.setColumnVisibility({
                cuid: false,
                csaddr1: false,
                cscity: false,
                csstate: false,
                cszip: false,
                cscono: false,
                cpono: !isSmScreen,
                ccustno: !isSmScreen,
                dorder: !isSmScreen,
                ccompany: !isSmScreen,
                nsalesamt: !isSmScreen,
                nbal: !isSmScreen,
            });
        }
    }, [isSmScreen]);

    useEffect(() => {
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, JSON.stringify({ rowSelection }));
    }, [rowSelection]);

    const startIndex = page * pageSize + 1;
    const endIndex = Math.min(startIndex + pageSize - 1, filteredData.length);
    const totalPages = Math.ceil(filteredData.length / pageSize);

    return (
        <Box style={{ position: "relative" }}>
            <Grid
                container
                spacing={2}
                align-items='center'
                justifyContent='space-between'
                sx={{ mb: 2 }}
            >
                <Grid
                    item
                    xs={10}
                    sm={8}
                    md={6}
                >
                    <TextField
                        variant='outlined'
                        placeholder='Search Invoices'
                        value={searchTerm}
                        onChange={handleSearchChange}
                        fullWidth
                        sx={{ mb: 2, backgroundColor: theme.palette.common.white }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    container
                    alignItems='center'
                    justifyContent='flex-end'
                >
                    <Box
                        display='flex'
                        alignItems='center'
                    >
                        <Typography sx={{ fontWeight: 600, mr: 2 }}>
                            Selected {Object.keys(rowSelection).length} Invoices
                        </Typography>
                        <Button
                            variant='contained'
                            disableElevation
                            sx={{
                                "color": theme.palette.common.white,
                                "fontWeight": 600,
                                "&:active": {
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.common.white,
                                },
                            }}
                            onClick={handlePayInvoices}
                        >
                            Pay Invoices
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            {loading || isLoadingReport ?
                <LoadingSpinner
                    message={isLoadingReport ? "Loading Report..." : "Loading Invoices..."}
                />
            : isSaving ?
                <LoadingSpinner message='Saving...' />
            :   <>
                    <MaterialReactTable table={table} />
                </>
            }
            <Typography
                variant='caption'
                sx={{ float: "left", mt: 2 }}
            >
                Showing {startIndex} to {endIndex} of {filteredData.length} entries.
            </Typography>
            <Pagination
                count={totalPages}
                page={page + 1}
                onChange={(event, value) => setPage(value - 1)}
                showFirstButton
                showLastButton
                sx={{
                    "float": "right",
                    "mt": 2,
                    "& .MuiPaginationItem-root": {
                        "borderRadius": "4px",
                        "backgroundColor": theme.palette.common.white,
                        "color": theme.palette.text.primary,
                        "border": `1px solid ${theme.palette.text.primary}`,
                        "&:hover": {
                            color: theme.palette.primary.main,
                            border: `2px solid ${theme.palette.primary.main}`,
                            backgroundColor: theme.palette.common.white,
                            fontWeight: 600,
                        },
                        "&.Mui-selected": {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.common.white,
                        },
                    },
                }}
            />
        </Box>
    );
};

export default ViewOpenInvoices;
