import { Button, Flex, TextField } from "@aws-amplify/ui-react";
import { SQW_Log, DebugLevel } from "services/Logging";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { experimentalStyled as styled } from "@mui/material/styles";
import * as React from "react";
import { HubsUpdateForm } from "ui-components/index";
import { GreenButton } from "components/Buttons";

import {
    DeleteClient,
    DeleteHub,
    SelectAllClients,
    SelectAllHubs,
} from "../services/GraphQlHelper";
import ClientsUpdateForm from "../ui-components/ClientsUpdateForm";
import { getOverrideProps } from "../ui-components/utils";
//import UpdateClientsTenants from "./UpdateClientsTenants";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

interface UpdateHubProps {
    onSuccess: () => void;
}

export default function UpdateClients(props: UpdateHubProps) {
    const [selectedPage, setSelectedPage] = React.useState("default");
    const [hubs, setHubs] = React.useState([] as any[]);
    const [hubId, setHubId] = React.useState("");
    const [confirmationOpen, setConfirmationOpen] = React.useState(false);

    function UsersTable() {
        //SQW_Log(DebugLevel.LOG, "Calling HubsTable");
        return (
            <>
                <TableContainer component={Paper}>
                    <Table aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell align='left'>
                                    <h5>Hub ID</h5>
                                </TableCell>
                                <TableCell align='left'>
                                    <h5>chubno</h5>
                                </TableCell>
                                <TableCell align='left'>
                                    <h5>Tenant</h5>
                                </TableCell>
                                <TableCell align='left'></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {hubs
                                .sort((a, b) => (a > b ? 1 : -1))
                                .map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            "&:last-child td, &:last-child th": { border: 0 },
                                        }}
                                    >
                                        <TableCell
                                            key={"1" + index}
                                            component='th'
                                            scope='row'
                                        >
                                            {row.id}
                                        </TableCell>
                                        <TableCell
                                            key={"2" + index}
                                            component='th'
                                            scope='row'
                                        >
                                            {row.chubno}
                                        </TableCell>
                                        <TableCell
                                            key={"22" + index}
                                            component='th'
                                            scope='row'
                                        >
                                            {row?.tenant?.subdomain ? row.tenant.subdomain : ""}
                                        </TableCell>
                                        <TableCell
                                            key={"3" + index}
                                            align='right'
                                        >
                                            <Flex
                                                key={"4" + index}
                                                direction='row'
                                            >
                                                <GreenButton
                                                    style={{ padding: "30px" }}
                                                    key={"6" + index}
                                                    onClick={() => {
                                                        setSelectedPage(row.id);
                                                    }}
                                                >
                                                    Update
                                                </GreenButton>
                                                <GreenButton
                                                    style={{ padding: "30px" }}
                                                    key={"7" + index}
                                                    onClick={() => {
                                                        setHubId(row.id);
                                                        handleConfirmationOpen();
                                                    }}
                                                >
                                                    Delete
                                                </GreenButton>
                                            </Flex>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }

    function ConfirmationBox() {
        return (
            <Dialog
                open={confirmationOpen}
                onClose={handleConfirmationClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{"Delete?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        {"Are you sure you want to delete this hub?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleConfirmationClose}
                        autoFocus
                    >
                        No
                    </Button>
                    <Button onClick={handleConfirm}>Yes</Button>
                </DialogActions>
            </Dialog>
        );
    }
    const handleConfirmationOpen = () => {
        setConfirmationOpen(true);
    };
    const handleConfirmationClose = () => {
        setConfirmationOpen(false);
    };
    async function handleConfirm() {
        setConfirmationOpen(false);
        const ret = await DeleteHub(hubId);
        if (ret) {
            //SQW_Log(DebugLevel.LOG, "Return from delete hub: ", ret);
            alert("Hub " + hubId + " deleted");
        } else SQW_Log(DebugLevel.LOG, "Unknown error when trying to delete hub: ");
    }

    React.useEffect(() => {
        async function getData() {
            const data = await SelectAllHubs();
            //SQW_Log(DebugLevel.LOG, "Got hubs: ", data);

            if (!data?.data?.listHubs?.items) return;

            setHubs(data.data.listHubs.items);
        }

        getData();
    }, []);

    function showPage() {
        if (selectedPage.startsWith("UpdateClientsTenants:")) {
            const id = selectedPage.replace("UpdateClientsTenants:", "");

            return (
                <div>
                    <h3>{id}: Linked Tenants</h3>
                    {/*<UpdateClientsTenants
                        idProp={id}
                        onSuccess={() => {
                            setSelectedPage("default");
                        }}
                    />*/}
                </div>
            );
        }

        switch (selectedPage) {
            case "default":
                return (
                    <>
                        <UsersTable />
                        <br />
                        <br />
                        <Button
                            children='Go Back'
                            type='reset'
                            onClick={(event) => {
                                event.preventDefault();
                                props.onSuccess();
                            }}
                            {...getOverrideProps(null, "ClearButton")}
                        ></Button>
                    </>
                );
            default:
                return (
                    <div>
                        <h3>{selectedPage}</h3>
                        <HubsUpdateForm
                            id={selectedPage}
                            onSuccess={() => {
                                setSelectedPage("default");
                            }}
                            onCancel={() => {
                                setSelectedPage("default");
                            }}
                        />
                    </div>
                );
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            {ConfirmationBox()}
            {showPage()}
        </Box>
    );
}
