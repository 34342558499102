import { useEffect, useState } from "react";
import { SQW_Log, DebugLevel } from "services/Logging";
import { Box, Button, Grid } from "@mui/material";
import theme from "theme/theme";
import { SelectField } from "@aws-amplify/ui-react";

import {
    SelectAllTenants,
    SelectTenant,
    SelectUserTenants,
} from "../../services/GraphQlHelper_New";

export type Props = {
    username: string;
    onChooseTenant: (tenantName: string) => void;
    onSignOut: () => void;
};

const TenantChooser: React.FC<Props> = (props) => {
    const [selectedTenantName, setSelectedTenantName] = useState("");
    const [tenants, setTenants] = useState<TenantSelectType[]>([]);
    const [retryCount, setRetryCount] = useState(0);

    async function GetTenantDescription(tenantid: string | undefined) {
        if (!tenantid) return "";

        const tenant = await SelectTenant(tenantid);

        //SQW_Log(DebugLevel.LOG, "GetTenantDescription: ", tenant);
        if (!tenant || tenant.length === 0) return "";

        return String(tenant[0].name ?? "");
    }

    const TenantSelect = () => {
        return (
            <SelectField
                label='Choose A Tenant'
                value={selectedTenantName}
                onChange={(e) => setSelectedTenantName((e.target as HTMLSelectElement).value)}
            >
                <option
                    key={342324332}
                    value={""}
                >
                    {"Please select a tenant..."}
                </option>
                {tenants &&
                    tenants.length > 0 &&
                    tenants?.map((item, index) => (
                        <option
                            key={index}
                            value={item?.tenantid}
                        >
                            {item?.tenantdescription}
                        </option>
                    ))}
            </SelectField>
        );
    };
    type TenantSelectType = {
        tenantid: string;
        tenantdescription: string;
    };
    useEffect(() => {
        const fetchUserTenants = async () => {
            const tenants: TenantSelectType[] = [];

            if (retryCount > 10) return;
            if (props.username === "") {
                return;
            }
            let userTenants;
            try {
                if (props.username === "GetAllTenants") {
                    userTenants = await SelectAllTenants();
                    SQW_Log(DebugLevel.LOG, "User Tenants: ", tenants);
                } else {
                    userTenants = await SelectUserTenants(props.username);
                }

                if (userTenants) {
                    for (let i = 0; i < userTenants.length; i++) {
                        const item = userTenants[i];
                        if (item) {
                            tenants.push({
                                tenantid: String(item.tenantsId),
                                tenantdescription: await GetTenantDescription(
                                    String(item.tenantsId),
                                ),
                            });
                        }
                    }
                }
            } catch (error) {
                SQW_Log(DebugLevel.LOG, "TenantChooser: Error: ", error);
                setRetryCount(retryCount + 1);
            }
            setTenants(tenants);
            SQW_Log(DebugLevel.LOG, "TenantChooser: UserName", props.username);
            SQW_Log(DebugLevel.LOG, "TenantChooser: tenants: ", tenants);
        };
        fetchUserTenants();
    }, [props.username, retryCount]);

    function onChooseTenant(selectedTenantName: string) {
        if (!selectedTenantName || selectedTenantName === "") {
            alert("Please select a tenant");
            return;
        }
        props.onChooseTenant(selectedTenantName);
    }

    //const { toSignIn } = useAuthenticator();

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
            }}
        >
            <Box sx={{ width: "300px" }}>
                <TenantSelect />
                <Grid
                    container
                    spacing={2}
                    sx={{ mt: 2 }}
                >
                    <Grid
                        item
                        xs={6}
                    >
                        <Button
                            variant='outlined'
                            fullWidth
                            onClick={props.onSignOut}
                            sx={{
                                borderRadius: theme.shape.borderRadius,
                                backgroundColor: theme.palette.common.white,
                                boxShadow: "none",
                            }}
                        >
                            Sign Out
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <Button
                            variant='contained'
                            fullWidth
                            onClick={() => onChooseTenant(selectedTenantName)}
                            sx={{
                                borderRadius: theme.shape.borderRadius,
                                boxShadow: "none",
                            }}
                        >
                            Choose
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default TenantChooser;
