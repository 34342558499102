import { render } from "@react-email/render";
import { SES } from "@aws-sdk/client-ses";
import { DateTime } from "aws-sdk/clients/devicefarm";
import { GetSetting } from "services/PersistantObjects";
import { GetLogoURL } from "services/GetInvoiceData";

import config from "../amplifyconfiguration.json";
import { Email } from "./EmailTemplate";

const akid = config.aws_image_bucket_access_key;
const sak = config.aws_image_bucket_secret_key;
const defaultConfirmationURL =
    "https://prism-web-images-prod.s3.amazonaws.com/emailtemplates/DefaultEmailConfirmationTemplate.html";
const defaultServiceConfirmationURL =
    "https://prism-web-images-prod.s3.amazonaws.com/emailtemplates/DefaultEmailConfirmationServiceTemplate.html";

const ses = new SES({
    region: "us-east-1",
    credentials: { accessKeyId: akid, secretAccessKey: sak },
});

const emailHtml = `<!DOCTYPE html>
<html>
<body>

<h1>Your order</h1>
<p>Your order date is [[ORD_DATE]]</p>

</body>
</html>`;

const params = {
    Source: "test@servquestweb.com",
    Destination: {
        ToAddresses: ["dgikow@prismvs.com"],
    },
    Message: {
        Body: {
            Html: {
                Charset: "UTF-8",
                Data: emailHtml,
            },
        },
        Subject: {
            Charset: "UTF-8",
            Data: "This is a test email",
        },
    },
};

interface CompanyInfo {
    confirmationEmailURL: string;
    confirmationEmailServiceURL: string;
    confirmationEmailSubject: string;
    companyEmail: string;
    companyDisplayEmail: string;
    companyPhone: string;
    companyLogo: string;
    companyName: string;
    doNotSendConfirmation: boolean;
}

async function GetCompanyInfo() {
    const ci = {} as CompanyInfo;

    const donotsend = await GetSetting("companyconfirmationemaildonotsend", "false");
    if (donotsend.trim().toLowerCase() === "true") ci.doNotSendConfirmation = true;
    else ci.doNotSendConfirmation = false;

    ci.confirmationEmailURL = (await GetSetting("companyconfirmationemailurl", "")).toLowerCase();
    ci.confirmationEmailServiceURL = (
        await GetSetting("companyconfirmationemailserviceurl", "")
    ).toLowerCase();
    ci.confirmationEmailSubject = await GetSetting(
        "companyconfirmationemailsubject",
        "Order Confirmation",
    );
    ci.companyEmail = await GetSetting(
        "companyconfirmationemailaddress",
        "orders@servquestweb.com",
    );
    ci.companyPhone = await GetSetting("companyconfirmationphone", "");
    ci.companyName = await GetSetting("companyconfirmationname", "");
    ci.companyDisplayEmail = await GetSetting("companyconfirmationdisplayemailaddress", "");
    ci.companyLogo = await GetLogoURL();

    return ci;
}

export async function SendTestEmail() {
    //SendEmailConfirmation("dgikow@prismvs.com", "1/1/2024", "444444444");
    //SendEmailConfirmation("dgikow@prismvs.com", "test@servquestweb.com", "Test of send email from aws", emailHtml, new Date());
    /*var em = await ses.sendEmail(params);
    if (em) {
        //SQW_Log(DebugLevel.LOG, "Emailed", em);
        //SQW_Log(DebugLevel.LOG, "Emailed 2", em.MessageId);
    }*/
}

/*export async function SendEmailConfirmationURL(toEmail: string, fromEmail: string, subject: string, htmlURL: string, orderDate: DateTime) {
    //const htmlMessage = render({htmlURL});
    if (htmlURL === "")
        htmlURL = defaultConfirmationURL;

    const response = await fetch(htmlURL);

    if (response) {
        const htmlMessage = await response.text();
        SendEmailConfirmation(toEmail, fromEmail, subject, htmlMessage, orderDate, "44444444");
    }


}*/

async function GetMessage(htmlURL: string) {
    const response = await fetch(htmlURL);

    if (response) {
        const htmlMessage = await response.text();
        return htmlMessage;
    }

    return "";
}

export async function SendEmailConfirmationOrder(
    toEmail: string,
    orderDate: string,
    orderNumber: string,
) {
    const ci = await GetCompanyInfo();
    if (ci.doNotSendConfirmation) return;
    if (ci.confirmationEmailURL === "") ci.confirmationEmailURL = defaultConfirmationURL;
    SendEmailConfirmationOrderAndService(toEmail, orderDate, orderNumber, ci);
}

export async function SendEmailConfirmationService(
    toEmail: string,
    orderDate: string,
    orderNumber: string,
) {
    const ci = await GetCompanyInfo();
    if (ci.doNotSendConfirmation) return;

    if (ci.confirmationEmailServiceURL === "")
        ci.confirmationEmailURL = defaultServiceConfirmationURL;
    else ci.confirmationEmailURL = ci.confirmationEmailServiceURL;
    SendEmailConfirmationOrderAndService(toEmail, orderDate, orderNumber, ci);
}

export async function SendEmailConfirmationOrderAndService(
    toEmail: string,
    orderDate: string,
    orderNumber: string,
    ci: CompanyInfo,
) {
    const htmlURL = ci.confirmationEmailURL;

    let htmlMessage = await GetMessage(htmlURL);

    if (htmlMessage === "") return;

    const logo = ci.companyLogo;
    const companyEmail = ci.companyEmail;
    const companyDisplayEmail = ci.companyDisplayEmail;
    const companyPhone = ci.companyPhone;
    const subject = ci.confirmationEmailSubject;
    const companyName = ci.companyName;

    htmlMessage = htmlMessage.replaceAll("[[ORD_DATE]]", orderDate);
    htmlMessage = htmlMessage.replaceAll("[[ORDER_NUMBER]]", orderNumber);
    htmlMessage = htmlMessage.replaceAll("[[LOGO]]", logo);
    htmlMessage = htmlMessage.replaceAll("[[COMPANY_EMAIL]]", companyDisplayEmail);
    htmlMessage = htmlMessage.replaceAll("[[COMPANY_PHONE]]", companyPhone);
    htmlMessage = htmlMessage.replaceAll("[[COMPANY_NAME]]", companyName);

    const emParams = {
        Source: companyEmail,
        Destination: {
            ToAddresses: [toEmail],
        },
        Message: {
            Body: {
                Html: {
                    Charset: "UTF-8",
                    Data: htmlMessage,
                },
            },
            Subject: {
                Charset: "UTF-8",
                Data: subject,
            },
        },
    };

    const em = await ses.sendEmail(emParams);
    if (em) {
        //SQW_Log(DebugLevel.LOG, "Sent Email", em);
    }
}

export {};
