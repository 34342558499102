import { useEffect, useMemo, useState, ChangeEvent } from "react";
import { SQW_Log, DebugLevel } from "services/Logging";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Typography,
    Grid,
    TextField,
    InputAdornment,
    Pagination,
    Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
    type MRT_RowSelectionState,
} from "material-react-table";
import { GetUserName } from "services/PersistantObjects";

import LoadingSpinner from "../components/LoadingSpinner";
import { truncateString } from "../helpers/truncateString";
import { UserTickets } from "../interfaces/InvoiceInterfaces";
import {
    API_Authenticate,
    API_GetTicketReport,
    API_GetUserTickets,
} from "../services/GetInvoiceData";
import theme from "../theme/theme";

const PrintTickets = () => {
    const [userTickets, setUserTickets] = useState<UserTickets[]>([]);
    const [authToken, setAuthToken] = useState("");
    const [loading, setLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoadingReport, setIsLoadingReport] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [isSmScreen, setIsSmScreen] = useState(window.innerWidth < 1000);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);

    const navigate = useNavigate();
    let atok = "";

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const filteredData = useMemo(() => {
        if (!searchTerm) return userTickets;
        return userTickets.filter((ticket) => {
            return [
                ticket.csono,
                ticket.cpono,
                ticket.dorder,
                ticket.dcutoff,
                ticket.ccustno,
                ticket.cscono,
                ticket.csaddr1,
                ticket.cscity,
                ticket.csstate,
                ticket.cszip,
                ticket.cscompany,
            ].some((field) => field && field.toLowerCase().includes(searchTerm));
        });
    }, [userTickets, searchTerm]);

    useEffect(() => {
        const handleResizeWindow = () => {
            const newWidth = window.innerWidth;
            setWidth(newWidth);
            setIsSmScreen(newWidth < 1000);
        };

        window.addEventListener("resize", handleResizeWindow);
        handleResizeWindow();

        return () => {
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                //SQW_Log(DebugLevel.LOG, "Print Tickets: Calling Authenticate");
                const token = await API_Authenticate();
                if (token && token !== "") SQW_Log(DebugLevel.LOG, "Authenticated");
                else {
                    SQW_Log(DebugLevel.LOG, "Authentication failed...");
                    return;
                }
                setAuthToken(token);
                atok = token;
                //SQW_Log(DebugLevel.LOG, "Print Tickets: Calling GetUserInvoices");
                const ticketData = await API_GetUserTickets(token, GetUserName());
                //SQW_Log(DebugLevel.LOG, "Invoice Data: ", ticketData);
                if (ticketData) {
                    const data = ticketData?.data;
                    if (data) setUserTickets(data);
                }
            } catch (error) {
                SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Failed to load tickets: ", error);
            } finally {
                setLoading(false);
            }
        };
        //SQW_Log(DebugLevel.LOG, "Print Tickets: fetching data");
        fetchData();
    }, []);

    const showReport = async (cuid: string, csono: string) => {
        try {
            setIsLoadingReport(true);

            const token = await API_Authenticate();
            //SQW_Log(DebugLevel.LOG, "Show Report for T: ", csono);

            await API_GetTicketReport(token, GetUserName(), csono);
        } catch (error) {
            SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Failed to load report: ", error);
        } finally {
            setIsLoadingReport(false);
        }
    };

    const columns = useMemo<MRT_ColumnDef<UserTickets>[]>(
        () => [
            {
                accessorKey: "csono",
                header: "Ticket #",
                enableEditing: false,
                size: 50,
                maxSize: 50,
                Cell: ({ row }) => {
                    return isSmScreen ?
                            <Box
                                sx={{
                                    display: "grid",
                                    margin: "auto",
                                    gridTemplateColumns: "1fr",
                                    width: "100%",
                                }}
                            >
                                <Typography>
                                    Ticket #:{" "}
                                    <a
                                        href='#'
                                        onClick={() => {
                                            showReport("", row.getValue<string>("csono"));
                                        }}
                                        style={{
                                            color: theme.palette.primary.main,
                                            textDecoration: "none",
                                        }}
                                    >
                                        {row.getValue<string>("csono")}
                                    </a>
                                </Typography>
                                <Typography>PO #: {row.original.cpono}</Typography>
                                <Typography>
                                    Delivery Date: {truncateString(row.original.dorder, 10)}
                                </Typography>
                                <Typography>
                                    Cut Off Date: {truncateString(row.original.dcutoff, 10)}
                                </Typography>
                                <Typography>Customer #: {row.original.ccustno}</Typography>
                                <Typography>
                                    Site Address:
                                    <div className='flex space-x-2'>
                                        <span className='max-w-[500px] truncate font-medium text-xs'>
                                            {row.getValue("cscompany")}
                                            <br />
                                            {row.getValue("csaddr1")}
                                            <br />
                                            {row.getValue("cscity")}, {row.getValue("csstate")}{" "}
                                            {row.getValue("cszip")}
                                        </span>
                                    </div>
                                </Typography>
                            </Box>
                        :   <>
                                <a
                                    href='#'
                                    onClick={() => showReport("", row.getValue<string>("csono"))}
                                    style={{
                                        color: theme.palette.primary.main,
                                        textDecoration: "underline",
                                    }}
                                >
                                    {row.getValue<string>("csono")}
                                </a>
                            </>;
                },
            },
            {
                accessorKey: "cpono",
                header: "PO #",
                size: 50,
                maxSize: 50,
                enableEditing: false,
                Cell: ({ row }) => {
                    return <div>{row.getValue<string>("cpono")}</div>;
                },
            },
            {
                accessorKey: "dorder",
                header: "Delivery Date",
                enableEditing: false,
                size: 50,
                maxSize: 50,
                Cell: ({ row }) => {
                    return (
                        <div className='w-[70px] font-medium text-xs'>
                            {truncateString(row.getValue("dorder"), 10)}
                        </div>
                    );
                },
            },
            {
                accessorKey: "dcutoff",
                header: "Cut Off Date",
                enableEditing: false,
                size: 50,
                maxSize: 50,
                Cell: ({ row }) => {
                    return (
                        <div className='w-[70px] font-medium text-xs'>
                            {truncateString(row.getValue("dorder"), 10)}
                        </div>
                    );
                },
            },
            {
                accessorKey: "ccustno",
                header: "Customer #",
                enableEditing: false,
                size: 50,
                maxSize: 50,
            },
            {
                accessorKey: "cscono",
                header: "Site #",
                enableEditing: false,
            },
            {
                accessorKey: "csaddr1",
                header: "Address",
                enableEditing: false,
            },
            {
                accessorKey: "cscity",
                header: "City",
                enableEditing: false,
            },
            {
                accessorKey: "csstate",
                header: "State",
                enableEditing: false,
            },
            {
                accessorKey: "cszip",
                header: "Zip",
                enableEditing: false,
            },
            {
                accessorKey: "cscompany",
                header: "Site Address",
                enableEditing: false,
                size: 400,
                maxSize: 500,
                Cell: ({ row }) => {
                    return (
                        <Box>
                            <Typography>
                                {row.getValue("cscompany")} {row.getValue("csaddr1")}{" "}
                                {row.getValue("cscity")}, {row.getValue("csstate")}{" "}
                                {row.getValue("cszip")}
                            </Typography>
                        </Box>
                    );
                },
            },
            {
                accessorKey: "printbutton",
                header: "",
                enableEditing: false,
                maxSize: 125,
                Cell: ({ row }) => {
                    return (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                variant='outlined'
                                disableElevation
                                fullWidth
                                onClick={() => {
                                    showReport("", row.getValue<string>("csono"));
                                }}
                                sx={{
                                    "color": theme.palette.text.primary,
                                    "fontWeight": 600,
                                    "&:active": {
                                        backgroundColor: theme.palette.primary.main,
                                        color: theme.palette.common.white,
                                    },
                                }}
                            >
                                Print
                            </Button>
                        </Box>
                    );
                },
            },
        ],
        [isSmScreen],
    );

    //optionally, you can manage the row selection state yourself
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

    const table = useMaterialReactTable({
        columns,
        data: filteredData, // Using the state data.
        enableRowSelection: false,
        enableTopToolbar: false,
        enableBottomToolbar: false,
        enableEditing: false,
        editDisplayMode: "cell",
        enableColumnActions: false,
        enableColumnFilters: false,
        initialState: {
            columnVisibility: {
                csaddr1: false,
                cscity: false,
                csstate: false,
                cszip: false,
                cscono: false,
                cpono: !isSmScreen,
                ccustno: !isSmScreen,
                dorder: !isSmScreen,
                dcutoff: !isSmScreen,
                cscompany: !isSmScreen,
            },
        },
        getRowId: (row) => row.csono, //give each row a more useful id
        state: {
            pagination: { pageIndex: page, pageSize: pageSize },
        },
        muiTableBodyCellProps: {
            sx: {
                backgroundColor: theme.palette.common.white,
            },
        },
        muiTableHeadCellProps: {
            sx: {
                backgroundColor: theme.palette.common.white,
            },
        },
    });

    useEffect(() => {
        //SQW_Log(DebugLevel.LOG, "resetting column sizing...  useEffect: ", isSmScreen);
        if (!loading && table) {
            //SQW_Log(DebugLevel.LOG, "resetting column sizing...  small screen=", isSmScreen);
            table.setColumnVisibility({
                cuid: false,
                csaddr1: false,
                cscity: false,
                csstate: false,
                cszip: false,
                cscono: false,
                cpono: !isSmScreen,
                ccustno: !isSmScreen,
                dorder: !isSmScreen,
                dcutoff: !isSmScreen,
                cscompany: !isSmScreen,
            });
        }
    }, [isSmScreen]);

    const startIndex = page * pageSize + 1;
    const endIndex = Math.min(startIndex + pageSize - 1, userTickets.length);
    const totalPages = Math.ceil(userTickets.length / pageSize);

    return (
        <Box style={{ position: "relative" }}>
            <Grid
                container
                spacing={2}
                alignItems='center'
                justifyContent='space-between'
                sx={{ mb: 2 }}
            >
                <Grid
                    item
                    xs={10}
                    sm={8}
                    md={6}
                >
                    <TextField
                        variant='outlined'
                        placeholder='Search Tickets'
                        value={searchTerm}
                        onChange={handleSearchChange}
                        fullWidth
                        sx={{
                            mb: 2,
                            backgroundColor: theme.palette.common.white,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            {loading || isLoadingReport ?
                <LoadingSpinner
                    message={isLoadingReport ? "Loading Report..." : "Loading Tickets..."}
                />
            : isSaving ?
                <LoadingSpinner message='Saving...' />
            :   <MaterialReactTable table={table} />}
            <Typography
                variant='caption'
                sx={{ float: "left", mt: 2 }}
            >
                Showing {startIndex} to {endIndex} of {userTickets.length} entries.
            </Typography>
            <Pagination
                count={totalPages}
                page={page + 1}
                onChange={(event, value) => setPage(value - 1)}
                showFirstButton
                showLastButton
                sx={{
                    "float": "right",
                    "mt": 2,
                    "& .MuiPaginationItem-root": {
                        "borderRadius": "4px",
                        "backgroundColor": theme.palette.common.white,
                        "color": theme.palette.text.primary,
                        "border": `1px solid ${theme.palette.text.primary}`,
                        "&:hover": {
                            color: theme.palette.primary.main,
                            border: `2px solid ${theme.palette.primary.main}`,
                            backgroundColor: theme.palette.common.white,
                            fontWeight: 600,
                        },
                        "&.Mui-selected": {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.common.white,
                        },
                    },
                }}
            />
        </Box>
    );
};

export default PrintTickets;
