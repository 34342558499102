import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, IconButton, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GetUserName } from "services/PersistantObjects";

import { API_Authenticate, API_GetTicketReport, GetTenant } from "../services/GetInvoiceData";
import "../styles.css";
import { WhiteButton } from "./Buttons";
import { CustomDivider } from "./CustomDivider";
import LoadingSpinner from "./LoadingSpinner";

type Props = {
    csono: string;
    deliveryDate: string;
    openDialog: boolean;
};

const OrderComplete: React.FC<Props> = ({ csono, deliveryDate, openDialog }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [width, setWidth] = React.useState<number>(window.innerWidth);
    const [isLoadingReport, setIsLoadingReport] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [tenantInfo, setTenantInfo] = React.useState({} as any);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tenantInfo = await GetTenant();
                setTenantInfo(tenantInfo);
            } catch (error) {
            } finally {
            }
        };

        setIsLoading(true);
        fetchData();
        setDialogOpen(openDialog);
        setIsLoading(false);
    }, [csono, openDialog]);

    useEffect(() => {
        if (!dialogOpen && !isLoading) {
            goBackToStore();
        }
    }, [dialogOpen]);

    const showReport = async (cuid: string, csono: string) => {
        try {
            setIsLoadingReport(true);

            const token = await API_Authenticate();
            //SQW_Log(DebugLevel.LOG, "Show Report for T: ", csono);

            await API_GetTicketReport(token, GetUserName(), csono);
        } catch (error) {
        } finally {
            setIsLoadingReport(false);
        }
    };

    const goBackToStore = () => {
        navigate("/products");
    };

    return (
        <Dialog
            open={dialogOpen}
            onClose={goBackToStore}
            sx={{ width: isMobile ? "375px" : "665px", m: "auto" }}
        >
            {isLoadingReport ?
                <LoadingSpinner message='Retrieving order...' />
            :   <>
                    <div className='float-right'>
                        <IconButton
                            className='float-right'
                            onClick={() => setDialogOpen(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Stack
                        spacing={1}
                        direction='column'
                        sx={{
                            marginLeft: "20px",
                            marginRight: "20px",
                            marginBottom: "20px",
                            marginTop: "1px",
                        }}
                        alignItems='center'
                    >
                        <div>
                            <CheckCircleIcon sx={{ fontSize: 80, color: "#58B55D" }} />
                        </div>
                        <div className='checkout_info_label_bold'>Thank you for your order!</div>
                        <div className='order_complete_order_id'>Order ID #{csono}</div>
                        <div className='short-divider'>
                            <CustomDivider />
                        </div>
                        <div className='order_complete_h1'>Thank you for your business.</div>
                        <div className='order_complete_text'>
                            {deliveryDate === "" ?
                                "Your order has been successfully processed and will be delivered soon."
                            :   "Your estimated delivery date is " + deliveryDate + "."}
                        </div>
                        <div className='order_complete_text'>
                            If you need to change the date of delivery, or need to alert customer
                            service of any other changes, please contact us at{" "}
                            <b>{tenantInfo.phone}</b>.
                        </div>

                        <WhiteButton
                            className='white_button'
                            variant='outlined'
                            onClick={() => showReport("", csono)}
                        >
                            Print Order
                        </WhiteButton>
                    </Stack>
                </>
            }
        </Dialog>
    );
};

export default OrderComplete;
