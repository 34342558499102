import {
    AdminCreateUserCommand,
    AdminSetUserPasswordCommand,
    CognitoIdentityProviderClient,
    ConfirmForgotPasswordCommand,
    ConfirmSignUpCommand,
    ForgotPasswordCommand,
    InitiateAuthCommand,
    InitiateAuthCommandInput,
    ResendConfirmationCodeCommand,
    SignUpCommand,
    GlobalSignOutCommand,
    AuthenticationResultType,
    AdminDisableUserCommand,
    AdminEnableUserCommand,
    AdminGetUserCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { getAWSConfig, getTenantNameFromSubdomain } from "services/GetInvoiceData";
import AWS, { CognitoIdentityServiceProvider } from "aws-sdk";
import { SQW_Log, DebugLevel } from "services/Logging";
import {
    CognitoIdentityClient,
    GetCredentialsForIdentityCommand,
    GetIdCommand,
} from "@aws-sdk/client-cognito-identity";
import { getItemStr, PersistantObjectType } from "services/PersistantObjects";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";

const config = getAWSConfig();
// Update these values with your Cognito details
const REGION = config.aws_cognito_region;

// const userPool = new AmazonCognitoIdentity.CognitoUserPool({
//     UserPoolId: config.aws_user_pools_id,
//     ClientId: config.aws_user_pools_web_client_id,
// });

const cognitoClient = new CognitoIdentityProviderClient({ region: REGION });
const cognitoServiceClient = new CognitoIdentityServiceProvider({ region: REGION });

const tenantName = getTenantNameFromSubdomain();

/*export const refreshCognitoSession = async () => {
    const user = userPool.getCurrentUser();

    if (user) {
        return user.getSession((err: any, session: any) => {
            if (err) {
                SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error refreshing session:", err);
                return;
            }
            const yourIdToken = session.getIdToken().jwtToken;
            const yourAccessToken = session.getAccessToken().jwtToken;
            SQW_Log(DebugLevel.LOG, "Session refreshed:", session);
            return { idToken: yourIdToken, accessToken: yourAccessToken };
        });
    }
    return {};
};*/

export const forgotPassword = async (username: string) => {
    const params = {
        ClientId: config.aws_user_pools_web_client_id,
        //  SecretHash: "STRING_VALUE",
        Username: username,
    };
    try {
        const command = new ForgotPasswordCommand(params);
        const response = await cognitoClient.send(command);
        //SQW_Log(DebugLevel.LOG, "Sent Forgot Password Email:", response);
        return { response: response, error: "" };
    } catch (error) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error sending forgot password email:", error);
        return { response: null, error: error };
    }
};

export const confirmForgottenPassword = async (
    username: string,
    password: string,
    confirmationCode: string,
) => {
    const params = {
        ClientId: config.aws_user_pools_web_client_id,
        //  SecretHash: "STRING_VALUE",
        Username: username,
        ConfirmationCode: confirmationCode,
        Password: password,
    };
    try {
        const command = new ConfirmForgotPasswordCommand(params);
        const response = await cognitoClient.send(command);
        //SQW_Log(DebugLevel.LOG, "Confirmed Forgot Password:", response);
        return { response: response, error: "" };
    } catch (error) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error confirming forgotten password:", error);
        return { response: null, error: error };
    }
};

export const resendConfirmation = async (username: string) => {
    const params = {
        ClientId: config.aws_user_pools_web_client_id,
        //  SecretHash: "STRING_VALUE",
        Username: username,
    };
    try {
        const command = new ResendConfirmationCodeCommand(params);
        const response = await cognitoClient.send(command);
        //SQW_Log(DebugLevel.LOG, "Confirmation Resent:", response);
        return { response: response, error: "" };
    } catch (error) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error resending confirmation:", error);
        return { response: null, error: error };
    }
};

export const confirmSignUp = async (username: string, confirmationCode: string) => {
    const params = {
        ClientId: config.aws_user_pools_web_client_id,
        //  SecretHash: "STRING_VALUE",
        ConfirmationCode: confirmationCode,
        Username: username,
    };
    try {
        const command = new ConfirmSignUpCommand(params);
        const response = await cognitoClient.send(command);
        //SQW_Log(DebugLevel.LOG, "User Confirmed:", response);
        return { response: response, error: "" };
    } catch (error) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error confirming user:", error);
        return { response: null, error: error };
    }
};

export const createNewUser = async (
    firstname: string,
    lastname: string,
    username: string,
    password: string,
    accountnumber: string,
    tenant: string,
) => {
    try {
        const params = {
            ClientId: config.aws_user_pools_web_client_id,
            //  SecretHash: "STRING_VALUE",
            Username: username, // required
            Password: password,
            UserAttributes: [
                {
                    Name: "email",
                    Value: username,
                },
                {
                    Name: "given_name",
                    Value: firstname,
                },
                {
                    Name: "family_name",
                    Value: lastname,
                },
                /*{
                    Name: "custom:account_number",
                    Value: accountnumber,
                },
                {
                    Name: "custom:tenant",
                    Value: tenant,
                },*/
            ],
        };

        //const command = new AdminCreateUserCommand(params);
        const command = new SignUpCommand(params);
        const response = await cognitoClient.send(command);
        //SQW_Log(DebugLevel.LOG, "New user created:", response);
        //const setPermPwd = setPermanentPassword(username, password);
        //SQW_Log(DebugLevel.LOG, "New user password set:", setPermPwd);
        return { response: response, error: "" };
    } catch (error) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error creating new user:", error);
        return { response: null, error: error };
    }
};

// const setPermanentPassword = async (username: string, password: string) => {
//     try {
//         const params = {
//             UserPoolId: "your_user_pool_id", // replace with your actual user pool ID
//             Username: username, // replace with the new user's email or username
//             Password: password, // replace with the new permanent password
//             Permanent: true, // set to true to make the password permanent
//         };

//         const command = new AdminSetUserPasswordCommand(params);
//         const response = await cognitoClient.send(command);
//         //SQW_Log(DebugLevel.LOG, "Password set successfully:", response);
//     } catch (error) {
//         SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error setting password:", error);
//     }
// };

/**
 * Authenticates a user using the InitiateAuth API.
 *
 * @param {string} username - The Cognito username.
 * @param {string} password - The user's password.
 * @returns {Promise<object>} - Resolves to an object containing tokens if authentication succeeds.
 */
export async function authenticateUser(username: string, password: string) {
    // Prepare the parameters for InitiateAuth
    const config = getAWSConfig();

    const params: InitiateAuthCommandInput = {
        AuthFlow: "USER_PASSWORD_AUTH", // Ensure this flow is enabled for your app client
        ClientId: config.aws_user_pools_web_client_id,
        AuthParameters: {
            USERNAME: username,
            PASSWORD: password,
        },
    };

    const command = new InitiateAuthCommand(params);

    try {
        const response = await cognitoClient.send(command);
        const authResult = response.AuthenticationResult;

        if (authResult) {
            // Extract tokens from the AuthenticationResult
            const idToken = authResult.IdToken;
            const accessToken = authResult.AccessToken;
            const refreshToken = authResult.RefreshToken;

            SQW_Log(DebugLevel.LOG, "Authentication successful!");

            return {
                idToken,
                accessToken,
                refreshToken,
            };
        } else {
            SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Authentication result is empty.");
            return null;
        }
    } catch (error) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Authentication failed:", error);
        throw error;
    }
}
export async function signOutUser(accessToken: string) {
    const command = new GlobalSignOutCommand({ AccessToken: accessToken });

    try {
        const response = await cognitoClient.send(command);
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "User signed out globally:", response);
        return true;
    } catch (error) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error signing out user globally:", error);
    }
    return false;
}

/*export async function getUserSession(accessToken: string) {
    const command = new GetUserCommand({ AccessToken: accessToken });
    try {
        const response = await cognitoClient.send(command);
        return response;
    } catch (error) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error getting user session:", error);
        return null;
    }
}*/

export const signUpWithCognito = async (
    clientId: string,
    username: string,
    password: string,
    email: string,
) => {
    try {
        const cognitoUser = await getCognitoUser(username);

        if (cognitoUser) return;
    } catch (err) {
        SQW_Log(DebugLevel.LOG, "Error getting user...", err);
    }

    const credentials = await setCredentials();
    let client = cognitoClient;

    if (credentials) {
        client = new CognitoIdentityProviderClient({
            region: "us-east-1",
            credentials: {
                accessKeyId: credentials?.Credentials?.AccessKeyId ?? "",
                secretAccessKey: credentials?.Credentials?.SecretKey ?? "",
                sessionToken: credentials?.Credentials?.SessionToken,
            },
        });
    }

    if (password == "") {
        const command = new AdminCreateUserCommand({
            UserPoolId: config.aws_user_pools_id,
            Username: username,
            UserAttributes: [{ Name: "email", Value: email }],
        });
        return client.send(command);
    } else {
        const command = new AdminCreateUserCommand({
            UserPoolId: config.aws_user_pools_id,
            Username: username,
            TemporaryPassword: password,
            UserAttributes: [{ Name: "email", Value: email }],
        });

        return client.send(command);
    }
};

export const disableCognitoUser = async (username: string) => {
    const userPoolId = config.aws_user_pools_id;
    const credentials = await setCredentials();

    let client = cognitoClient;

    if (credentials) {
        client = new CognitoIdentityProviderClient({
            region: "us-east-1",
            credentials: {
                accessKeyId: credentials?.Credentials?.AccessKeyId ?? "",
                secretAccessKey: credentials?.Credentials?.SecretKey ?? "",
                sessionToken: credentials?.Credentials?.SessionToken,
            },
        });
    }
    const command = new AdminDisableUserCommand({
        UserPoolId: userPoolId,
        Username: username,
    });

    return client.send(command);
};

interface loginKeys {
    [key: string]: string;
}

// const getCredentials = () => {
//     const config = getAWSConfig();
//     const logins: loginKeys = {};
//     const token = getItemStr(PersistantObjectType.ID_TOKEN);

//     if (!token || token.length < 10) {
//         SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "CognitoAuth - Get Credentials - No token.");
//         return null;
//     }
//     logins[`cognito-idp.${config.aws_cognito_region}.amazonaws.com/${config.aws_user_pools_id}`] =
//         token;

//     const credentials = fromCognitoIdentityPool({
//         clientConfig: { region: config.aws_cognito_region },
//         identityPoolId: config.aws_cognito_identity_pool_id,
//         logins: logins,
//     });

//     AWS.config.credentials = credentials;

//     return credentials;
// };

const getIdentityID = async () => {
    const client = new CognitoIdentityClient({ region: "us-east-1" });
    const logins: loginKeys = {};
    const token = getItemStr(PersistantObjectType.ID_TOKEN);

    if (!token || token.length < 10) {
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "CognitoAuth - Get Credentials - No token.");
        return null;
    }
    logins[`cognito-idp.${config.aws_cognito_region}.amazonaws.com/${config.aws_user_pools_id}`] =
        token;

    const params = {
        IdentityPoolId: config.aws_cognito_identity_pool_id,
        Logins: logins,
    };

    try {
        const command = new GetIdCommand(params);
        const response = await client.send(command);

        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "IdentityId retrieved:", response.IdentityId);
        return response.IdentityId;
    } catch (err) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error retrieving IdentityId:", err);
    }
    return null;
};

const setCredentials = async () => {
    const client = new CognitoIdentityClient({ region: "us-east-1" });
    const logins: loginKeys = {};
    const token = getItemStr(PersistantObjectType.ID_TOKEN);

    if (!token || token.length < 10) {
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "CognitoAuth - Get Credentials - No token.");
        return null;
    }
    logins[`cognito-idp.${config.aws_cognito_region}.amazonaws.com/${config.aws_user_pools_id}`] =
        token;

    const identityId = await getIdentityID();

    if (!identityId) return;

    const cmd = new GetCredentialsForIdentityCommand({
        IdentityId: identityId,
        Logins: logins,
    });
    const credentials = await client.send(cmd);

    if (credentials.Credentials) {
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "Credentials retrieved:", credentials.Credentials);
        AWS.config.credentials = new AWS.Credentials({
            accessKeyId: credentials?.Credentials?.AccessKeyId ?? "",
            secretAccessKey: credentials?.Credentials?.SecretKey ?? "",
            sessionToken: credentials?.Credentials?.SessionToken,
        });
    } else {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error retrieving credentials:", credentials);
    }

    return credentials;
};

export const enableCognitoUser = async (username: string) => {
    const userPoolId = config.aws_user_pools_id;
    const credentials = await setCredentials();

    let client = cognitoClient;

    if (credentials) {
        client = new CognitoIdentityProviderClient({
            region: "us-east-1",
            credentials: {
                accessKeyId: credentials?.Credentials?.AccessKeyId ?? "",
                secretAccessKey: credentials?.Credentials?.SecretKey ?? "",
                sessionToken: credentials?.Credentials?.SessionToken,
            },
        });
    }
    const command = new AdminEnableUserCommand({
        UserPoolId: userPoolId,
        Username: username,
    });

    return client.send(command);
};

export const getCognitoUser = async (username: string) => {
    const userPoolId = config.aws_user_pools_id;
    const credentials = await setCredentials();
    let client = cognitoClient;

    if (credentials) {
        client = new CognitoIdentityProviderClient({
            region: "us-east-1",
            credentials: {
                accessKeyId: credentials?.Credentials?.AccessKeyId ?? "",
                secretAccessKey: credentials?.Credentials?.SecretKey ?? "",
                sessionToken: credentials?.Credentials?.SessionToken,
            },
        });
    }
    const command = new AdminGetUserCommand({
        UserPoolId: userPoolId,
        Username: username,
    });

    return client.send(command);
};

/*export async function refreshUserToken(refreshToken: string) {
    try {
        SQW_Log(DebugLevel.INFO_WITH_INFO_TYPE, "Refreshing user token:", refreshToken);

        const params = {
            AuthFlow: "REFRESH_TOKEN_AUTH",
            ClientId: config.aws_user_pools_web_client_id,
            AuthParameters: {
                REFRESH_TOKEN: refreshToken,
            },
        };
        const newTokens = await new Promise<AuthenticationResultType | null | undefined>(
            (resolve, reject) => {
                cognitoServiceClient.initiateAuth(params, (err, data) => {
                    if (err) {
                        reject(err);
                        return;
                    }
                    resolve(data.AuthenticationResult);
                });
            },
        );
        return newTokens;
    } catch (error) {
        SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Error refreshing user token:", error, refreshToken);
        return null;
    }
}*/
