import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
} from "@mui/material";
import { SQW_Log, DebugLevel } from "services/Logging";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { GetUserName } from "services/PersistantObjects";
import { GreenButton, WhiteButton } from "components/Buttons";
import { NumericFormat } from "react-number-format";
import { SelectField } from "@aws-amplify/ui-react";
import { useIsMobile } from "helpers/CustomHooks";
import InvoicePaymentComplete from "components/InvoicePaymentComplete";
import { Balance } from "@mui/icons-material";

import SuccessDisplay from "./SuccessDisplay";
import ErrorDisplay from "./ErrorDisplay";
import {
    API_Authenticate,
    API_GetCustomerCredits,
    API_GetPaymentMethods,
    API_GetSurcharge,
    API_PayInvoices,
} from "../services/GetInvoiceData";
import { PaymentMethod } from "../interfaces/InvoiceInterfaces";
import ModifyCreditCards from "../components/ModifyCreditCards";
import LoadingSpinner from "../components/LoadingSpinner";
import InvoiceCard from "../components/InvoiceCard";

export default function PayInvoices() {
    const [useCredits, setUseCredits] = useState(false);
    const [authToken, setAuthToken] = useState("");
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [credits, setCredits] = useState(0);
    const [loading, setLoading] = useState(true);
    const [payingInvoice, setPayingInvoice] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
    const [editingCreditCards, setEditingCreditCards] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [reloadCreditCards, setReloadCreditCards] = useState(false);
    const [surcharge, setSurcharge] = useState(0);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const jsonInvoices = JSON.parse(location?.state?.name ? location.state.name : "[]");

    const idlist = jsonInvoices.map((item: any) => item.id);
    const cuidlist = jsonInvoices.map((item: any) => {
        return { cuid: item.original.cuid };
    });
    //SQW_Log(DebugLevel.LOG, JSON.stringify(cuidlist));

    const total = jsonInvoices.reduce((acc: number, item: any) => acc + item.original.nbal, 0);
    const invoiceTotal = jsonInvoices.reduce(
        (acc: number, item: any) => acc + item.original.nsalesamt,
        0,
    );
    const ccustno = jsonInvoices[0]?.original?.ccustno || "";
    const cscono = jsonInvoices[0]?.original?.cscono || "";
    const ccompany = jsonInvoices[0]?.original?.ccompany || "";
    const caddress =
        jsonInvoices[0]?.original?.csaddr1 +
        " " +
        jsonInvoices[0]?.original?.cscity +
        ",  " +
        jsonInvoices[0]?.original?.csstate +
        " " +
        jsonInvoices[0]?.original?.cszip;
    const cpono = jsonInvoices[0]?.original?.cpono || "";
    const csphone = jsonInvoices[0]?.original?.csphone || "";
    const dinvoice = jsonInvoices[0]?.original?.dorder || "";
    const ids = idlist.join(", ");

    //SQW_Log(DebugLevel.LOG, "jsoninvoices: ", jsonInvoices);

    const theme = useTheme();
    // = useMediaQuery(theme.breakpoints.down("sm"));
    const isMobile = useIsMobile();
    const isSmScreen = isMobile;

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const token = await API_Authenticate();
                setAuthToken(token);
                /* const paymentMethodData = await API_GetPaymentMethods(ccustno, token);
        
        if(paymentMethodData){
          for (var i = 0; i < paymentMethodData.PaymentProfiles.length; i++){
            paymentMethodData.PaymentProfiles[i].nprofid = BigInt(paymentMethodData.PaymentProfiles[i].payment_profile_id);
            paymentMethodData.PaymentProfiles[i].ncustprfid = BigInt(paymentMethodData.PaymentProfiles[i].cust_profile_id);
          }
        }
        setPaymentMethods(paymentMethodData?.PaymentProfiles);
        //SQW_Log(DebugLevel.LOG, "paymentmethods: ", paymentMethodData.PaymentProfiles);*/
                setReloadCreditCards(true);
                const customerCredits = await API_GetCustomerCredits(ccustno, cscono, token);
                //SQW_Log(DebugLevel.LOG, "Customer credits: " + JSON.stringify(customerCredits));
                setCredits(customerCredits?.Credits);
            } catch (error) {
                SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Failed to load data: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [ccustno, cscono]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = await API_Authenticate();
                setAuthToken(token);
                const paymentMethodData = await API_GetPaymentMethods(ccustno, token);

                if (paymentMethodData) {
                    for (let i = 0; i < paymentMethodData.PaymentProfiles.length; i++) {
                        paymentMethodData.PaymentProfiles[i].nprofid = BigInt(
                            paymentMethodData.PaymentProfiles[i].payment_profile_id,
                        );
                        paymentMethodData.PaymentProfiles[i].ncustprfid = BigInt(
                            paymentMethodData.PaymentProfiles[i].cust_profile_id,
                        );
                    }
                }
                setPaymentMethods(paymentMethodData?.PaymentProfiles);
                //SQW_Log(DebugLevel.LOG, "paymentmethods: ", paymentMethodData.PaymentProfiles);
            } catch (error) {
                SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Failed to load data: ", error);
            } finally {
                setReloadCreditCards(false);
            }
        };

        if (reloadCreditCards) fetchData();
    }, [reloadCreditCards]);

    useEffect(() => {
        const fetchData = async () => {
            let surchAmt = 0;

            const selPM = paymentMethods.find(
                (pm) => pm.payment_profile_id === selectedPaymentMethod,
            );
            if (!selPM) {
                SQW_Log(
                    DebugLevel.LOG,
                    "Getting Surcharge:  Could not get current selected payment method.",
                );
                return;
            }

            const ncustprfid = selPM.cust_profile_id;

            const invs = jsonInvoices.map((item: any) => ({
                cinvno: item.original.cinvno,
                nbal: item.original.nbal,
            }));

            if (invs) {
                const token = await API_Authenticate();
                //SQW_Log(DebugLevel.LOG, "GetSurcharge Invoices: ", invs);
                invs.forEach(async (inv: any) => {
                    const surch = await API_GetSurcharge(
                        token,
                        ccustno,
                        inv.cinvno,
                        inv.nbal,
                        BigInt(ncustprfid),
                        BigInt(selectedPaymentMethod),
                        GetUserName(),
                    );

                    //console.logSurcharge: ", surch);
                    if (!surch) {
                        SQW_Log(DebugLevel.LOG, "Could not get surcharge.  API returned null.");
                        return;
                    }
                    if (surch.IsSuccessful) {
                        if (surch.Surcharge) {
                            surchAmt = surchAmt + surch.Surcharge.ChargeAmount;
                            /*SQW_Log(DebugLevel.LOG, 
                                "Surcharge for invoice " +
                                    inv.cinvno +
                                    " is " +
                                    surch.Surcharge.ChargeAmount,
                            );*/
                        } else {
                            /*SQW_Log(DebugLevel.LOG, 
                                "Could not get surcharge.  API did not return surcharge.  ",
                                surch,
                            );*/
                        }
                    }
                });
            }
            setSurcharge(surchAmt);
        };
        fetchData();
    }, [selectedPaymentMethod]);

    const makePayment = async () => {
        if (payingInvoice) return;

        setPayingInvoice(true);

        if (!authToken) {
            SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Authentication token is missing.");
            setPayingInvoice(false);
            return false;
        }

        try {
            const customer_profile_id = paymentMethods[0].ncustprfid;
            const customer_payment_profile_id =
                (selectedPaymentMethod && BigInt(selectedPaymentMethod)) ||
                paymentMethods.find((method) => method.ldefault === 1)?.nprofid;

            //SQW_Log(DebugLevel.LOG, "selectedPaymentMethod", selectedPaymentMethod);
            //SQW_Log(DebugLevel.LOG, "customerPaymentProfileID: ", customer_payment_profile_id?.toString());

            if (!customer_payment_profile_id) {
                alert("Please select a payment method.");
                setPayingInvoice(false);
                return false;
            }

            const message = await API_PayInvoices(
                cuidlist,
                total,
                customer_profile_id,
                customer_payment_profile_id,
                useCredits,
                GetUserName(),
                authToken,
            );
            if (!message) {
                setErrorMsg("Error paying invoice.  Service is not responding.");
                setPayingInvoice(false);
                return false;
            } else {
                if (message.IsSuccessful) {
                    setOpenConfirmationDialog(true);
                    //setSuccessMsg("Payment successful!");
                    //alert("Payment successful!");
                    //navigate("/"); // Need to set specific page. Not sure which page we should route to.
                    setPayingInvoice(false);
                    return true;
                } else {
                    SQW_Log(DebugLevel.LOG, "Error making payment: ", message);
                    let anetMsg = "";
                    if (message.AnetResponse) {
                        const anetJSON = JSON.parse(message.AnetResponse);
                        anetMsg = " - " + anetJSON.resptext;
                    }

                    setErrorMsg("Error " + message.ErrorCode + ": " + message.Error + anetMsg);

                    setPayingInvoice(false);
                    return false;
                }
            }
        } catch (error) {
            SQW_Log(DebugLevel.ERROR_WITH_ERROR_TYPE, "Payment Failed: ", error);
            alert("Payment failed. Please try again.");
            setPayingInvoice(false);
            return false;
        } finally {
            setPayingInvoice(false);
        }
    };

    const handleSubmit = () => {
        if (total > credits && !selectedPaymentMethod) {
            alert("Please select a payment method.");
            return;
        }
        makePayment();
    };

    const handleModifyCreditCards = () => {
        setEditingCreditCards(true);
    };

    const handleGoBack = () => {
        setEditingCreditCards(false);
    };

    const closeErrorMessage = () => {
        setErrorMsg("");
    };

    const closeSuccessMessage = () => {
        setSuccessMsg("");
        navigate("/");
    };

    const onCCModifyChange = (nprofid: string) => {
        //SQW_Log(DebugLevel.LOG, "payinv2 - Last Modified CC Prof: ", nprofid);
        if (nprofid !== "0") {
            setReloadCreditCards(true);
            setSelectedPaymentMethod(nprofid);
        }
    };

    return (
        <>
            <InvoicePaymentComplete
                invoicenums={ids}
                openDialog={openConfirmationDialog}
            />
            <Box sx={{ width: "100%" }} />
            {successMsg !== "" ?
                <SuccessDisplay
                    message={successMsg}
                    onGoBack={closeSuccessMessage}
                />
            :   <>
                    {errorMsg !== "" ?
                        <ErrorDisplay
                            message={errorMsg}
                            onGoBack={closeErrorMessage}
                        />
                    :   <div>
                            {editingCreditCards ?
                                <Box sx={{ width: isMobile ? "100vw" : "700px" }}>
                                    <ModifyCreditCards
                                        ccustno={ccustno}
                                        onCCChange={onCCModifyChange}
                                    />
                                </Box>
                            :   <>
                                    <Box sx={{ mt: isMobile ? 5 : 0 }}>
                                        <h1>Pay Invoice(s)</h1>
                                        <Card>
                                            <CardContent>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={5}
                                                    >
                                                        <InvoiceCard
                                                            numofinvoices={idlist.length}
                                                            invoicenums={ids}
                                                            ccustno={ccustno}
                                                            company={ccompany}
                                                            cscono={cscono}
                                                            csphone={csphone}
                                                            caddress={caddress}
                                                            cpono={cpono}
                                                            total={invoiceTotal}
                                                            balance={total}
                                                            credits={credits}
                                                            surcharge={surcharge}
                                                            dinvoice={dinvoice}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={0}
                                                        sm={1}
                                                    >
                                                        <Divider
                                                            variant='middle'
                                                            orientation='vertical'
                                                            sx={{
                                                                width: "3px",
                                                                borderLeftWidth: 1,
                                                                borderRightWidth: 0,
                                                                borderColor: "#1E2832",
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                    >
                                                        {loading ?
                                                            <LoadingSpinner message='Loading Payment Methods...' />
                                                        : payingInvoice ?
                                                            <LoadingSpinner message='Paying Invoice, please wait...' />
                                                        :   <Grid container>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <Grid container>
                                                                        <Grid
                                                                            className='invoice_card_label'
                                                                            item
                                                                            xs={9}
                                                                        >
                                                                            Total Invoice Amount
                                                                        </Grid>
                                                                        <Grid
                                                                            className='invoice_card_label'
                                                                            item
                                                                            xs={3}
                                                                        >
                                                                            Balance
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <Grid container>
                                                                        <Grid
                                                                            className='invoice_card_values_bold'
                                                                            item
                                                                            xs={9}
                                                                        >
                                                                            <NumericFormat
                                                                                value={invoiceTotal}
                                                                                displayType={"text"}
                                                                                thousandSeparator=','
                                                                                decimalScale={2}
                                                                                fixedDecimalScale
                                                                                prefix={"$"}
                                                                                renderText={(
                                                                                    formattedvalue: any,
                                                                                ) => {
                                                                                    return formattedvalue;
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid
                                                                            className='invoice_card_values_bold_red'
                                                                            item
                                                                            xs={3}
                                                                        >
                                                                            <NumericFormat
                                                                                value={total}
                                                                                displayType={"text"}
                                                                                thousandSeparator=','
                                                                                decimalScale={2}
                                                                                fixedDecimalScale
                                                                                prefix={"$"}
                                                                                renderText={(
                                                                                    formattedvalue: any,
                                                                                ) => {
                                                                                    return formattedvalue;
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <Box height={10} />
                                                                </Grid>
                                                                <Grid
                                                                    className='invoice_card_label'
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    Payment Method
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <SelectField
                                                                        id='PaymentMethodsDropdown'
                                                                        onChange={(event) =>
                                                                            setSelectedPaymentMethod(
                                                                                event.target.value,
                                                                            )
                                                                        }
                                                                        label='Payment Method'
                                                                        labelHidden
                                                                        defaultValue={
                                                                            selectedPaymentMethod ||
                                                                            ""
                                                                        }
                                                                        value={
                                                                            selectedPaymentMethod
                                                                        }
                                                                    >
                                                                        <option value=''>
                                                                            Select a payment Method
                                                                        </option>
                                                                        {paymentMethods &&
                                                                            paymentMethods.map(
                                                                                (method) => (
                                                                                    <>
                                                                                        <option
                                                                                            key={method.nprofid.toString()}
                                                                                            value={method.nprofid.toString()}
                                                                                        >
                                                                                            CC:{" "}
                                                                                            {
                                                                                                method.ccardnum
                                                                                            }{" "}
                                                                                            Exp:{" "}
                                                                                            {
                                                                                                method.cexpdate
                                                                                            }{" "}
                                                                                            Name:{" "}
                                                                                            {method.cfirstname +
                                                                                                " " +
                                                                                                method.clastname}{" "}
                                                                                            {(
                                                                                                method.ldefault ===
                                                                                                1
                                                                                            ) ?
                                                                                                "(Default)"
                                                                                            :   ""}
                                                                                        </option>
                                                                                        <br />
                                                                                    </>
                                                                                ),
                                                                            )}
                                                                    </SelectField>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <Box height={10} />
                                                                </Grid>
                                                                {credits < 0 ?
                                                                    <>
                                                                        <Grid
                                                                            className='invoice_card_values'
                                                                            item
                                                                            xs={12}
                                                                        >
                                                                            You have a credit{" "}
                                                                            <span className='invoice_card_values_green'>
                                                                                {"($" +
                                                                                    credits
                                                                                        .toFixed(2)
                                                                                        .toString() +
                                                                                    ")"}
                                                                            </span>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                        >
                                                                            <FormGroup>
                                                                                <FormControlLabel
                                                                                    control={
                                                                                        <Checkbox
                                                                                            onChange={(
                                                                                                e,
                                                                                            ) =>
                                                                                                setUseCredits(
                                                                                                    e
                                                                                                        .target
                                                                                                        .checked,
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    }
                                                                                    label='Use Credits?'
                                                                                />
                                                                            </FormGroup>
                                                                        </Grid>
                                                                    </>
                                                                :   <Grid
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        <Box height={30} />
                                                                    </Grid>
                                                                }
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <Box height={10} />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <GreenButton
                                                                        className='float-left'
                                                                        variant='contained'
                                                                        onClick={handleSubmit}
                                                                    >
                                                                        Pay Invoice(s)
                                                                    </GreenButton>
                                                                    <WhiteButton
                                                                        className='float-right'
                                                                        variant='contained'
                                                                        onClick={
                                                                            handleModifyCreditCards
                                                                        }
                                                                    >
                                                                        Modify Credit Cards
                                                                    </WhiteButton>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </>
                            }
                            <br />
                            <br />
                            <br />
                            {editingCreditCards ?
                                <Button
                                    variant='contained'
                                    onClick={handleGoBack}
                                >
                                    Go Back
                                </Button>
                            :   null}
                        </div>
                    }
                </>
            }
        </>
    );
}
